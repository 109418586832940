import * as React from "react";
import "./styles/OverviewTurboHistoryBox.css";

import { connect } from 'react-redux';
import { PagedItems } from 'src/api/ApiTypes';
import { OrderStatus, TurboOrder } from 'src/api/Entities';
import ContentBoxSplitter from 'src/AtomComponents/ContentBoxSplitter';
import BandwidthHelper from 'src/Contract/helpers/BandwidthHelper';
import { DateHelper } from 'src/helpers/DateHelper';
import StringHelper from 'src/helpers/StringHelper';
import historieSmallIcon from 'src/icons/Historie';
import Badge from 'src/MolekuleComponents/Badge';
import ContentBox from 'src/MolekuleComponents/ContentBox';
import BadgeColor from 'src/MolekuleComponents/interfaces/BadgeColor';
import LinkWithArrow from 'src/MolekuleComponents/LinkWithArrow';
import { LoadingContainer } from 'src/MolekuleComponents/LoadingContainer';
import { IReduxState } from 'src/redux';

// tslint:disable-next-line:no-empty-interface
interface IOwnProps {
    key?: any;
    // no own props yet 
}
interface IStateToProps {
    loadingHistory: boolean;
    history: PagedItems<TurboOrder> | undefined;
}

type IOvervieTurboHistoryBoxProps = IOwnProps & IStateToProps;

const OverviewTurboHistoryBox = (props: IOvervieTurboHistoryBoxProps) => {

    const { loadingHistory, history } = props;

    return (
        <ContentBox
            className="turboHistoryOverviewBox"
            title={"Historie"}
            titleIcon={historieSmallIcon}
            minWidth={272}
        >
            <LoadingContainer hidden={!loadingHistory} />
            {!loadingHistory && history
                ?
                <div className="turboHistoryOverviewBoxContent">
                    <HistoryList oders={history} />
                    <div className="turboHistoryOverviewMoreDetailsContainer">
                        <LinkWithArrow to="/product/turbo/order/history" className="strong blue linkToTurboHistoryDetails">Mehr anzeigen</LinkWithArrow>
                    </div>
                </div> :
                (!loadingHistory ?
                    <div className="turboHistoryOverviewFailedToFetchContainer">
                        "Es wurden keine Turbos vom Server zurückgeliefert."
                    </div> :
                    "")
            }
        </ContentBox>
    );
}

const HistoryList = (props: { oders: PagedItems<TurboOrder> }) => {
    const { oders } = props;
    const items = (oders.items || []).slice(0, 3);
    const entries = items.map((to, i) => <HistoryEntry key={to.id} order={to} hideSplitter={i + 1 >= items.length} />);
    return (
        <div className="turboHistoryOverview turboList">
            {entries.length > 0 ?
                entries :
                <div className="noTurboHistory">
                    Sie haben noch keine Turbos bestellt.
                    {/* &nbsp;
                <LinkWithArrow to="/product/turbo/history">Jetzt bestellen</LinkWithArrow> */}
                </div>}
        </div>);
}

const HistoryEntry = ({ order, hideSplitter }: { order: TurboOrder, hideSplitter?: boolean }) => {
    const dateHelper = new DateHelper();
    const bandwidthHelper = new BandwidthHelper();

    const turbo = order.turbo;
    let deliveryDate;
    if (order.deliveryDate) {
        deliveryDate = dateHelper.toDateStringFromIsoString(order.deliveryDate);
    }

    const bandwidthData = bandwidthHelper.optionFromOder(order);

    const isActive =  order.status === OrderStatus.Active || order.status === OrderStatus.Canceled; // !!order.running; <= if we want only running as active
    const isPending = order.status === OrderStatus.Pending;

    const className = new StringHelper().joinManyClassNames("turboHistoryOverview", "turboEntry");
    const contentClassName = new StringHelper().joinManyClassNames(
        "turboEntryContent",
        isActive && "active",
        isPending && "pending"
    );
    // const isRunningSubscription = order.status === OrderStatus.Active && !!order.subscription;
    const since = order && order.deliveryDate ? dateHelper.toDateTimeStringFromIsoString(order.deliveryDate) + "" : "-"
    const until = order && order.expiresDate ? dateHelper.toDateTimeStringFromIsoString(order.expiresDate) + "" : "-"
    const isSubscription = order.status !== OrderStatus.Canceled && !!order.subscription;

    return (
        <div className={className}>
            <div className={contentClassName}>
                <div className="orderDate">
                    {isActive || isPending ? <Badge badgeColor={isActive ? BadgeColor.ORANGE : BadgeColor.BLUE}>{isActive ? "Aktiv" : "Bestellt"}</Badge> : deliveryDate}

                </div>
                <div className="orderBandwidth">
                    {bandwidthData ?
                        (bandwidthData.download + "/" + bandwidthData.upload + " Mbit/s") :
                        "Unbekannte Bandbreite"}
                </div>
                <div className="orderTurboDuration">
                    {turbo && turbo.duration ? turbo.duration : "Unbekannte Dauer"}
                </div>
            </div>
            <div style={{ marginLeft: "8px" }}>
                <div>{`Laufzeit von ${since} bis ${until}`}</div>
                <div>{isSubscription ? " (verlängert sich automatisch)" : ""}</div>

            </div>
            {hideSplitter ? "" : <ContentBoxSplitter />}
        </div>);
}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { turbo } = state;
    const result: IStateToProps = {
        ...ownProps,
        history: turbo.turboHistory,
        loadingHistory: turbo.loadingTurboHistory,
    }
    return result;
}

export default connect(mapStateToProps)(OverviewTurboHistoryBox);