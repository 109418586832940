import * as React from "react";
import './styles/UserInfo.css';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import StringHelper from 'src/helpers/StringHelper';
import logoutIcon from 'src/icons/Logout';
import userIcon from 'src/icons/User';
import { IReduxState } from 'src/redux';
import IUser from './interfaces/IUser';
import AuhtenticationActionCreators from './redux/ActionCreators';
import { withRouter, RouteComponentProps } from "react-router-dom";


// tslint:disable-next-line:no-empty-interface
interface IOffersOwnProps extends RouteComponentProps<any> {
    className?: string;
}

interface IDispatchToProps {
    logout: () => Promise<any>;
}

interface IStateToProps {
    user: IUser | undefined;
}

type IUserInfoProps = IOffersOwnProps & IDispatchToProps & IStateToProps;

class UserInfo extends React.Component<IUserInfoProps> {

    private mounted?: boolean;

    constructor(props: IUserInfoProps) {
        super(props);
        this.performLogout = this.performLogout.bind(this);
    }

    public componentWillUnmount() {
        this.mounted = false;
    }

    public componentDidMount() {
        this.mounted = true;
    }

    public render() {
        const { className: cn, user } = this.props;
        const className = new StringHelper().joinClassNames("userInfoContainerWrapper", cn);

        return (
            <div className={className}>
                {user ?
                    <div className="userInfoContainer">
                        <div className="userIconContainer">
                            <img src={userIcon} alt="user" />
                        </div>
                        <div className="fullName">{user.fullName}</div>
                        <div className="customerNumber">Knd-Nr. {user.customerNumber}</div>
                        <div className="logoutLink ttLink blue" onClick={this.performLogout}>
                            <img src={logoutIcon} width="16" className="logoutIcon" alt="logout" />
                            <div>Logout</div>
                        </div>
                    </div> :
                    ""}
            </div>
        );
    }


    private async performLogout(e?: React.MouseEvent<HTMLElement>) {
        if (e) { e.preventDefault(); }
        const { logout } = this.props;
        try {
            await logout();
            this.props.history.push("/")
        } finally {
            if (this.mounted) {
                // tslint:disable-next-line:no-console
                console.log("still mounted");
            }
        }
    }
}

const mapStateToProps = (state: IReduxState, ownProps: IOffersOwnProps): IStateToProps => {
    const { authentication } = state;
    const result: IStateToProps = {
        ...ownProps,
        user: authentication.customer,
    }
    return result;
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOffersOwnProps): IDispatchToProps => {
    const actionCreators = new AuhtenticationActionCreators();
    const result: IDispatchToProps = {
        ...ownProps,
        logout: () => (dispatch as ReduxThunkDispatch<IReduxState>)(actionCreators.logout()),
    }
    return result;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserInfo));

