// tslint:disable
import { ApiNavigationLink, ApiBase } from './ApiTypes'


/**
 * This type is used as a basis for the different variants of this domain type. It can be created in the frontend
 * (in order to store it to the backend, for example) as it does neither have mandatory `_links` nor `id`.
 */
export interface BandwidthOptionBase extends ApiBase {
    id: number
    download: number
    upload: number
    
    _links?: {
        self: ApiNavigationLink
        
    }
}

/**
 * This type is used for data transfer. Each time we read an object of this domain type from a rest service,
 * this type will be returned.
 */
export interface BandwidthOptionDto extends BandwidthOptionBase {
    _links: {
        self: ApiNavigationLink
        
    }
}

/**
 * This type is the default type of choice in the frontend as it has an id (which can be added to the `BandwidthOptionDto`
 * via `apiHelper#getObjectId`). Consequently, this type is used for fields that reference this type.
 */
export interface BandwidthOption extends BandwidthOptionDto {
    id: number
}

/**
 * This type can be used, if the fields that reference other types have been eagerly loaded.
 */
export interface BandwidthOptionEAGER extends BandwidthOption {
    
}

/**
 * This type is used as a basis for the different variants of this domain type. It can be created in the frontend
 * (in order to store it to the backend, for example) as it does neither have mandatory `_links` nor `id`.
 */
export interface ContractBase extends ApiBase {
    id: number
    expires: string
    customer?: Customer
    product?: Product
    turboOrders?: TurboOrder[]
    _links?: {
        self: ApiNavigationLink
        customer: ApiNavigationLink
        product: ApiNavigationLink
        turboOrders: ApiNavigationLink
    }
}

/**
 * This type is used for data transfer. Each time we read an object of this domain type from a rest service,
 * this type will be returned.
 */
export interface ContractDto extends ContractBase {
    _links: {
        self: ApiNavigationLink
        customer: ApiNavigationLink
        product: ApiNavigationLink
        turboOrders: ApiNavigationLink
    }
}

/**
 * This type is the default type of choice in the frontend as it has an id (which can be added to the `ContractDto`
 * via `apiHelper#getObjectId`). Consequently, this type is used for fields that reference this type.
 */
export interface Contract extends ContractDto {
    id: number
}

/**
 * This type can be used, if the fields that reference other types have been eagerly loaded.
 */
export interface ContractEAGER extends Contract {
    customer: Customer
    product?: Product
    turboOrders: TurboOrder[]
}

/**
 * This type is used as a basis for the different variants of this domain type. It can be created in the frontend
 * (in order to store it to the backend, for example) as it does neither have mandatory `_links` nor `id`.
 */
export interface CustomerBase extends ApiBase {
    id: number
    city: string
    customerNumber: string
    email: string
    fullName: string
    street: string
    userName: string
    contract?: Contract
    _links?: {
        self: ApiNavigationLink
        contract: ApiNavigationLink
    }
}

/**
 * This type is used for data transfer. Each time we read an object of this domain type from a rest service,
 * this type will be returned.
 */
export interface CustomerDto extends CustomerBase {
    _links: {
        self: ApiNavigationLink
        contract: ApiNavigationLink
    }
}

/**
 * This type is the default type of choice in the frontend as it has an id (which can be added to the `CustomerDto`
 * via `apiHelper#getObjectId`). Consequently, this type is used for fields that reference this type.
 */
export interface Customer extends CustomerDto {
    id: number
}

/**
 * This type can be used, if the fields that reference other types have been eagerly loaded.
 */
export interface CustomerEAGER extends Customer {
    contract?: Contract
}

/**
 * This type is used as a basis for the different variants of this domain type. It can be created in the frontend
 * (in order to store it to the backend, for example) as it does neither have mandatory `_links` nor `id`.
 */
export interface NotificationsSettingsBase extends ApiBase {
    id: number
    deviceToken?: string
    sendMail: boolean
    notificationLevel?: NotificationLevel
    
    _links?: {
        self: ApiNavigationLink
        
    }
}

/**
 * This type is used for data transfer. Each time we read an object of this domain type from a rest service,
 * this type will be returned.
 */
export interface NotificationsSettingsDto extends NotificationsSettingsBase {
    _links: {
        self: ApiNavigationLink
        
    }
}

/**
 * This type is the default type of choice in the frontend as it has an id (which can be added to the `NotificationsSettingsDto`
 * via `apiHelper#getObjectId`). Consequently, this type is used for fields that reference this type.
 */
export interface NotificationsSettings extends NotificationsSettingsDto {
    id: number
}

/**
 * This type can be used, if the fields that reference other types have been eagerly loaded.
 */
export interface NotificationsSettingsEAGER extends NotificationsSettings {
    
}

/**
 * This type is used as a basis for the different variants of this domain type. It can be created in the frontend
 * (in order to store it to the backend, for example) as it does neither have mandatory `_links` nor `id`.
 */
export interface ProductBase extends ApiBase {
    id: number
    activated: boolean
    name: string
    productNumber: string
    bandwidth?: BandwidthOption
    contract?: Contract
    possibleTurbos?: Turbo[]
    _links?: {
        self: ApiNavigationLink
        bandwidth: ApiNavigationLink
        contract: ApiNavigationLink
        possibleTurbos: ApiNavigationLink
    }
}

/**
 * This type is used for data transfer. Each time we read an object of this domain type from a rest service,
 * this type will be returned.
 */
export interface ProductDto extends ProductBase {
    _links: {
        self: ApiNavigationLink
        bandwidth: ApiNavigationLink
        contract: ApiNavigationLink
        possibleTurbos: ApiNavigationLink
    }
}

/**
 * This type is the default type of choice in the frontend as it has an id (which can be added to the `ProductDto`
 * via `apiHelper#getObjectId`). Consequently, this type is used for fields that reference this type.
 */
export interface Product extends ProductDto {
    id: number
}

/**
 * This type can be used, if the fields that reference other types have been eagerly loaded.
 */
export interface ProductEAGER extends Product {
    bandwidth?: BandwidthOption
    contract?: Contract
    possibleTurbos: Turbo[]
}

/**
 * This type is used as a basis for the different variants of this domain type. It can be created in the frontend
 * (in order to store it to the backend, for example) as it does neither have mandatory `_links` nor `id`.
 */
export interface TurboBase extends ApiBase {
    id: number
    duration: string
    enabled: boolean
    hours?: number
    price: string
    pricelabel?: string
    sorting?: number
    turboNumber: string
    bandwidth?: BandwidthOption
    _links?: {
        self: ApiNavigationLink
        bandwidth: ApiNavigationLink
    }
}

/**
 * This type is used for data transfer. Each time we read an object of this domain type from a rest service,
 * this type will be returned.
 */
export interface TurboDto extends TurboBase {
    _links: {
        self: ApiNavigationLink
        bandwidth: ApiNavigationLink
    }
}

/**
 * This type is the default type of choice in the frontend as it has an id (which can be added to the `TurboDto`
 * via `apiHelper#getObjectId`). Consequently, this type is used for fields that reference this type.
 */
export interface Turbo extends TurboDto {
    id: number
}

/**
 * This type can be used, if the fields that reference other types have been eagerly loaded.
 */
export interface TurboEAGER extends Turbo {
    bandwidth?: BandwidthOption
}

/**
 * This type is used as a basis for the different variants of this domain type. It can be created in the frontend
 * (in order to store it to the backend, for example) as it does neither have mandatory `_links` nor `id`.
 */
export interface TurboOrderBase extends ApiBase {
    id: number
    deliveryDate?: string
    expiresDate?: string
    orderDate: string
    orderNumber: string
    running: boolean
    subscription?: boolean
    status: OrderStatus
    contract?: Contract
    turbo?: Turbo
    _links?: {
        self: ApiNavigationLink
        contract: ApiNavigationLink
        turbo: ApiNavigationLink
    }
}

/**
 * This type is used for data transfer. Each time we read an object of this domain type from a rest service,
 * this type will be returned.
 */
export interface TurboOrderDto extends TurboOrderBase {
    _links: {
        self: ApiNavigationLink
        contract: ApiNavigationLink
        turbo: ApiNavigationLink
    }
}

/**
 * This type is the default type of choice in the frontend as it has an id (which can be added to the `TurboOrderDto`
 * via `apiHelper#getObjectId`). Consequently, this type is used for fields that reference this type.
 */
export interface TurboOrder extends TurboOrderDto {
    id: number
}

/**
 * This type can be used, if the fields that reference other types have been eagerly loaded.
 */
export interface TurboOrderEAGER extends TurboOrder {
    contract?: Contract
    turbo?: Turbo
}

export interface BandwidthOptionEAGERBaseProjection extends BandwidthOptionEAGER {
    
}

export interface ContractBaseProjection extends Contract {
    
}

export interface ContractForPresentation extends Contract {
    product: ProductForPresentation
}

export interface CustomerBaseProjection extends Customer {
    
}

export interface NotificationsSettingsEAGERBaseProjection extends NotificationsSettingsEAGER {
    
}

export interface ProductBaseProjection extends Product {
    
}

export interface ProductForPresentation extends Product {
    bandwidth: BandwidthOptionEAGERBaseProjection
    possibleTurbos: TurboForPresentation[]
}

export interface TurboBaseProjection extends Turbo {
    
}

export interface TurboForPresentation extends Turbo {
    bandwidth: BandwidthOptionEAGERBaseProjection
}

export interface TurboOrderBaseProjection extends TurboOrder {
    
}

export interface TurboOrderForPresentation extends TurboOrder {
    turbo: TurboForPresentation
}

export enum NotificationLevel {
    ContractOnly = "ContractOnly",
    Full = "Full"
}

export enum OrderStatus {
    Pending = "Pending",
    Active = "Active",
    Expired = "Expired",
    Canceled = "Canceled"
}