import * as React from "react";
import "./styles/AppAlertContainer.css";

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import delay from 'src/helpers/delay';
import AlertBar from 'src/MolekuleComponents/AlertBar';
import { IReduxState } from 'src/redux';
// import AlertTypes from '../interfaces/AlertTypes';
import IAppAlertData from '../interfaces/AppAlertData';
import AppActionCreators from '../redux/ActionCreators';

interface IOffersOwnProps extends RouteComponentProps<any> { }

interface IDispatchToProps {
    addAppAlert: (alertData: IAppAlertData) => any;
    discardAppAlert: (alertData: IAppAlertData) => any;
}

interface IStateToProps {
    alerts: IAppAlertData[];
}
type IAppAlertContainerProps = IOffersOwnProps & IDispatchToProps & IStateToProps;

interface IAppAlertContainerState {
    visibleAlert?: IAppAlertData;
    expanded: boolean;
}

class AppAlertContainer extends React.Component<IAppAlertContainerProps, IAppAlertContainerState>{
    public state: IAppAlertContainerState = {
        visibleAlert: undefined,
        expanded: false,
    };

    constructor(props: IAppAlertContainerProps) {
        super(props);
        this.discardAppAlert = this.discardAppAlert.bind(this);
        this.setVisibleAlert = this.setVisibleAlert.bind(this);
        this.updateVisibleAlert = this.updateVisibleAlert.bind(this);
    }

    public componentWillMount() {
        this.updateVisibleAlert(this.props);
        // setTimeout(() => {
        //     const { addAppAlert } = this.props;
        //     addAppAlert({
        //         title: "Ups!",
        //         message: "Bad news",
        //         type: AlertTypes.ERROR,
        //     });
        //     addAppAlert({
        //         title: "Ups!",
        //         message: "Bad news2",
        //         type: AlertTypes.ERROR,
        //     });
        // }, 1000);
    }

    public componentWillReceiveProps(next: IAppAlertContainerProps) {
        this.updateVisibleAlert(next);
    }

    public render() {
        const { visibleAlert, expanded } = this.state;
        return (
            <div className="appAlertContainer">
                {/* tslint:disable */}
                <AlertBar
                    alertData={visibleAlert}
                    expanded={expanded}
                    onClick={this.discardAppAlert}
                />
            </div>);
    }

    private discardAppAlert() {
        const { visibleAlert, expanded } = this.state;
        const { discardAppAlert } = this.props;
        if (visibleAlert && expanded) {
            discardAppAlert(visibleAlert);
        }
    }

    private async updateVisibleAlert(props: IAppAlertContainerProps) {
        const { visibleAlert } = this.state;
        const { alerts } = props;
        if (
            (!!visibleAlert && alerts.length === 0) ||
            (alerts.length > 0 && alerts[0] !== visibleAlert)
        ) {
            const nextAlert = alerts.length > 0 ? alerts[0] : undefined;

            if (visibleAlert) {
                await this.setVisibleAlert(undefined);
            }

            if (!!nextAlert && !this.state.visibleAlert) {
                await this.setVisibleAlert(nextAlert);
            }
        }

    }

    private async setVisibleAlert(alertData: IAppAlertData | undefined) {
        const { visibleAlert } = this.state;
        if (!alertData) {
            this.setState({ expanded: false });
            await delay(300);
            const { visibleAlert: vc } = this.state;
            if (visibleAlert === vc) {
                this.setState({ visibleAlert: undefined });
            }
        } else {
            this.setState({ visibleAlert: alertData });
            await delay(10);
            const { visibleAlert: vc } = this.state;
            if (alertData === vc) {
                this.setState({ expanded: true });
            }
            await delay(5000);
            const { visibleAlert: vcStill, expanded } = this.state;
            if (alertData === vcStill && expanded) {
                this.discardAppAlert();
            }
        }
    }

}

const mapStateToProps = (state: IReduxState, ownProps: IOffersOwnProps): IStateToProps => {
    const { app } = state;
    const result: IStateToProps = {
        ...ownProps,
        alerts: app.alerts,
    }
    return result;
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOffersOwnProps): IDispatchToProps => {
    const appActionCreators = new AppActionCreators();
    const result: IDispatchToProps = {
        ...ownProps,
        addAppAlert: (alertData: IAppAlertData) => (dispatch as ReduxThunkDispatch<IReduxState>)(appActionCreators.addAppAlert(alertData)),
        discardAppAlert: (alertData: IAppAlertData) => (dispatch as ReduxThunkDispatch<IReduxState>)(appActionCreators.discardAppAlert(alertData)),
    }
    return result;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppAlertContainer));