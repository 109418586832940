import { ContractForPresentation } from 'src/api/Entities';

export interface IContractState {
    contract: ContractForPresentation | undefined;
    loadingContract?: boolean;
}

export const defaultState: IContractState = {
    contract: undefined,
    loadingContract: undefined,

}

export default IContractState;