import { TurboForPresentation } from 'src/api/Entities';
import { ArrayHelper } from 'src/helpers/ArrayHelper';
import IDurationViewModel from '../interfaces/IDurationViewModel';

export class DurationHelper {

    constructor() {
        this.getDistinctDurations = this.getDistinctDurations.bind(this);
        this.getDistinctDurationViewModels = this.getDistinctDurationViewModels.bind(this);
        this.createDurationViewModelFromTurbo = this.createDurationViewModelFromTurbo.bind(this);
        this.getSortedDurationsByHours = this.getSortedDurationsByHours.bind(this);
        this.getSortedDurationsBySorting = this.getSortedDurationsBySorting.bind(this);
    }

    // public getDistinctDurations(possibleTurbos: string[]): string[] {
    //     const result =
    //         new ArrayHelper().getDistinctByExpression(durations || [], this.isEqualDuration);

    //     return result;
    // }
    public getDistinctDurations(durations: string[]): string[] {
        const result =
            new ArrayHelper().getDistinctByExpression(durations || [], this.isEqualDuration);

        return result;
    }

    public isEqualDuration(d1: string, d2: string) {
        return d1 === d2;
    }

    public getDistinctDurationViewModels(durations: IDurationViewModel[]): IDurationViewModel[] {
        const result =
            new ArrayHelper().getDistinctByExpression(durations || [], this.isEqualDurationModel)

        const sorted = this.getSortedDurationsBySorting(result);
        return sorted;
    }

    public createDurationViewModelFromTurbo(turbo: TurboForPresentation): IDurationViewModel {

        return ({
            name: turbo.duration,
            hours: turbo.hours ? turbo.hours : -1,
            sorting: turbo.sorting || -1,
        });
    }

    public isEqualDurationModel(d1: IDurationViewModel, d2: IDurationViewModel) {
        return d1.hours === d2.hours;
    }
    public getSortedDurationsByHours(durations: IDurationViewModel[], desc: boolean = false): IDurationViewModel[] {
        const result = [...durations || []]
            .sort(
                (d1, d2) =>
                    // indef hours (at least one)
                    d1.hours <= 0 || d2.hours <= 0 ?
                        (
                            d1.hours <= 0 && d2.hours > 0 ?
                                1 :
                                (d1.hours > 0 && d2.hours <= 0 ?
                                    -1 : 0)
                        ) :
                        // defined hours
                        (d1.hours > d2.hours ? 1 :
                            (d1.hours < d2.hours) ? -1 :
                                0)
            );

        return desc ? result.reverse() : result;
    }
    public getSortedDurationsBySorting(durations: IDurationViewModel[], desc: boolean = false): IDurationViewModel[] {
        const result = [...durations || []]
            .sort(
                (d1, d2) =>
                    // defined hours
                    (d1.sorting > d2.sorting ? 1 :
                        (d1.sorting < d2.sorting) ? -1 :
                            0)
            );

        return desc ? result.reverse() : result;
    }
}

export default DurationHelper;