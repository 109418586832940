import * as React from "react";
import store from 'src/redux';
import AppActionCreators from '../redux/ActionCreators';
import IDialogBoxMessage from 'src/DialogBox/interfaces/IDialogBoxMessage';
import { TurboOrder } from 'src/api/Entities';
import TurboForPresentation from "src/Turbo/interfaces/TurboForPresentation";


export default class DialogBoxHelper {

    public getTurboOrderDialogText(order: TurboOrder) {

        const { turbo, subscription } = order;
        const bandwidth = turbo?.bandwidth;

        return <ul>
            <li>Geschwindigkeit: {bandwidth?.download || "0"} / {bandwidth?.upload || "0"}</li>
            <li>Dauer: {turbo?.duration || ""}</li>
            <li>Fortlaufend: {subscription ? "Ja" : "Nein"}</li>
            <li>{turbo?.pricelabel ? turbo?.pricelabel : `Preis: ${(turbo?.price || "")}`}</li>
        </ul>
    }

    public getTurboOfferDialogText(offer: TurboForPresentation | undefined, asSubscription: boolean) {

        const bandwidth = offer?.bandwidth;

        return <ul>
            <li>Geschwindigkeit: {bandwidth?.download || "0"} / {bandwidth?.upload || "0"}</li>
            <li>Dauer: {offer?.duration || ""}</li>
            <li>Fortlaufend: {asSubscription ? "Ja" : "Nein"}</li>
            <li>{offer?.pricelabel ? offer?.pricelabel : `Preis: ${(offer?.price || "")}`}</li>
        </ul>
    }


    public addAppMessage(message: IDialogBoxMessage) {
        if (store) {
            try {
                store.dispatch(new AppActionCreators().addAppMessage(message))
            } catch (err) {
                // tslint:disable-next-line:no-console
                console.error("Failed to add dialog box.", message)
            }
        }
    }
    public discardAppMessage(message: IDialogBoxMessage) {
        if (store) {
            try {
                store.dispatch(new AppActionCreators().discardAppMessage(message))
            } catch (err) {
                // tslint:disable-next-line:no-console
                console.error("Failed to discard dialog box.", message)
            }
        }
    }
    public clearAppMessages() {
        if (store) {
            try {
                store.dispatch(new AppActionCreators().clearAppMessages())
            } catch (err) {
                // tslint:disable-next-line:no-console
                console.error("Failed to remove dialog boxes.")
            }
        }
    }

}