import * as React from "react";
import "./styles/OverviewTurboHistoryBox.css";

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { PagedItems } from 'src/api/ApiTypes';
import { OrderStatus, TurboOrder } from 'src/api/Entities';
import ContentBoxSplitter from 'src/AtomComponents/ContentBoxSplitter';
import BandwidthHelper from 'src/Contract/helpers/BandwidthHelper';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import { DateHelper } from 'src/helpers/DateHelper';
import StringHelper from 'src/helpers/StringHelper';
import historieSmallIcon from 'src/icons/Historie';
import Badge from 'src/MolekuleComponents/Badge';
import ContentBox from 'src/MolekuleComponents/ContentBox';
import DivButtonWithArrow from 'src/MolekuleComponents/DivButtonWithArrow';
import BadgeColor from 'src/MolekuleComponents/interfaces/BadgeColor';
import LinkWithArrow from 'src/MolekuleComponents/LinkWithArrow';
import { LoadingContainer } from 'src/MolekuleComponents/LoadingContainer';
import { IReduxState } from 'src/redux';
import TurboActionCreators from './redux/ActionCreators';

// tslint:disable-next-line:no-empty-interface
interface IOwnProps {
    key?: any;
    // no own props yet 
}
interface IStateToProps {
    loadingHistory: boolean;
    history: PagedItems<TurboOrder> | undefined;
}


interface IDispatchToProps {
    retrieveMoreOrders: () => Promise<any>;
}

type IOvervieTurboHistoryBoxProps = IOwnProps & IStateToProps & IDispatchToProps;

const OverviewTurboHistoryBox = (props: IOvervieTurboHistoryBoxProps) => {

    const { loadingHistory, history, retrieveMoreOrders } = props;
    const hasMore = history && history.page.number + 1 < history.page.totalPages;

    return (
        <ContentBox
            className="turboHistoryOverviewBox"
            title={"Historie"}
            isSingleCollumnBox={true}
            titleIcon={historieSmallIcon}
            minWidth={272}
        >
            <LoadingContainer hidden={!loadingHistory || !!history} />
            {history
                ?
                <div className="turboHistoryOverviewBoxContent">
                    <HistoryList oders={history} />
                    <div className="turboHistoryOverviewMoreDetailsContainer">
                        {hasMore ?
                            <DivButtonWithArrow disabled={loadingHistory} onClick={retrieveMoreOrders} className="strong blue linkToTurboHistoryDetails">Lade weitere Turbos</DivButtonWithArrow> :
                            ""
                        }
                        {!hasMore ?
                            <div>
                                <LinkWithArrow to="/product/turbo" className="linkToTurboHistoryDetails">Zurück zum Dashboard</LinkWithArrow>
                            </div> :
                            ""}
                    </div>
                </div> :
                (!loadingHistory ?
                    <div className="turboHistoryOverviewFailedToFetchContainer">
                        "Es wurden keine Turbos vom Server zurückgeliefert."
                    </div> :
                    "")
            }
        </ContentBox>
    );
}

const HistoryList = (props: { oders: PagedItems<TurboOrder> }) => {
    const { oders } = props;
    const items = (oders.items || []);
    const entries = items.map((to, i) => <HistoryEntry key={to.id} order={to} hideSplitter={i + 1 >= items.length} />);
    return (
        <div className="turboHistoryOverview turboList">
            {entries.length > 0 ?
                entries :
                <div className="noTurboHistory">
                    Sie haben noch keine Turbos bestellt?&nbsp;
                <LinkWithArrow to="/product/turbo/history">Jetzt bestellen</LinkWithArrow>
                </div>}
        </div>);
}

const HistoryEntry = ({ order, hideSplitter }: { order: TurboOrder, hideSplitter?: boolean }) => {
    const dateHelper = new DateHelper();
    const bandwidthHelper = new BandwidthHelper();

    const turbo = order.turbo;
    let readableOrderDate
    if (order.deliveryDate) {
        readableOrderDate = <div>
            <div>Am {dateHelper.toDateStringFromIsoString(order.deliveryDate)}</div>
            <div>({dateHelper.toTimeStringFromIsoString(order.deliveryDate)} Uhr)</div>
        </div>;
    }

    let expiresDate;
    if (order.expiresDate) {
        expiresDate = dateHelper.fromIsoDateTimeString(order.expiresDate);
    }
    const expiresNumber = expiresDate
        ? expiresDate.valueOf() :
        0;
    const readableExpiresDate = order.expiresDate ?
        (expiresNumber > Date.now() ? "Läuft" : "Lief") + " ab am " + dateHelper.toDateStringFromIsoString(order.expiresDate) + " um " + dateHelper.toTimeStringFromIsoString(order.expiresDate) :
        "";

    const bandwidthData = bandwidthHelper.optionFromOder(order);

    const isActive = order.status === OrderStatus.Active || order.status === OrderStatus.Canceled;
    const isPending = order.status === OrderStatus.Pending;

    const className = new StringHelper().joinManyClassNames("turboHistoryOverview", "turboEntry");
    const contentClassName = new StringHelper().joinManyClassNames(
        "turboEntryContent",
        isActive && "active",
        isPending && "pending"
    );
    const since = order && order.deliveryDate ? dateHelper.toDateTimeStringFromIsoString(order.deliveryDate) + "" : "-"
    const until = order && order.expiresDate ? dateHelper.toDateTimeStringFromIsoString(order.expiresDate) + "" : "-"
    const isSubscription = order.status !== OrderStatus.Canceled && !!order.subscription;

    return (
        <div className={className}>
            <div className={contentClassName}>
                <div className="orderDate">
                    {isActive || isPending ? <Badge badgeColor={isActive ? BadgeColor.ORANGE : BadgeColor.BLUE}>{isActive ? "Aktiv" : "Bestellt"}</Badge> : readableOrderDate}
                </div>
                <div className="orderBandwidth">
                    {bandwidthData ?
                        ((bandwidthData.download) + "/" + (bandwidthData.upload) + " Mbit/s") :
                        "Unbekannte Bandbreite"}
                </div>
                <div className="orderTurboDuration">
                    {(turbo && turbo.duration) || "Unbekannte Dauer"}
                </div>
                <div className="separator" />
                <div className="orderExpires">
                    {readableExpiresDate}
                </div>
            </div>
            <div style={{ marginLeft: "8px" }}>
                <div>{`Laufzeit von ${since} bis ${until}`}</div>
                <div>{isSubscription ? " (verlängert sich automatisch)" : ""}</div>

            </div>
            {hideSplitter ? "" : <ContentBoxSplitter />}
        </div>);
}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { turbo } = state;
    const result: IStateToProps = {
        ...ownProps,
        history: turbo.turboHistory,
        loadingHistory: turbo.loadingTurboHistory,
    }
    return result;
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
    const turboActionCreators = new TurboActionCreators();
    const result: IDispatchToProps = {
        ...ownProps,
        retrieveMoreOrders: () => (dispatch as ReduxThunkDispatch<IReduxState>)(turboActionCreators.retrieveNextTurboHistoryPage()),
    }
    return result;
}
export default connect(mapStateToProps, mapDispatchToProps)(OverviewTurboHistoryBox);