import * as React from "react";
import StringHelper from "src/helpers/StringHelper";
import "./styles/Header1.css"


interface IHeader1Props extends React.HTMLAttributes<HTMLDivElement> {
    blue?: boolean;
}
const Header1 = (props: IHeader1Props) => {
    const { blue, className: cn, ...rest } = props;
    const helper = new StringHelper();
    const className = helper.joinManyClassNames("header1", blue ? "blue" : undefined, cn);

    return (<div {...rest} className={className} />);
}

export default Header1;