import * as React from "react";
import "./styles/LinkWithArrow.css";

import { Link, LinkProps } from 'react-router-dom';
import StringHelper from 'src/helpers/StringHelper';
import linkBlueIcon from 'src/icons/LinkBlue';
import linkWhiteIcon from 'src/icons/LinkWhite';


interface ILinkWithArrowProps extends LinkProps {
    white?: boolean;
}

const LinkWithArrow = (props: ILinkWithArrowProps) => {
    const { className: cn, children, white, ...rest } = props;

    const stringHelper = new StringHelper();
    const className = stringHelper.joinManyClassNames("linkWithArrow", white ? "white" : undefined, cn);
    const backgroundImage = stringHelper.createCssUrlString(white ? linkWhiteIcon : linkBlueIcon);

    return (
        <Link {...{ ...rest, className }} >
            <div className="linkWithArrowChildren" >{children}</div>
            <div className="linkWithArrowIcon"
                style={{ backgroundImage }}
            />
        </Link>
    )
}

export default LinkWithArrow;