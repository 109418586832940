// const AlertTypes = Object.freeze({
//     NONE: "NONE",
//     INFO: "INFO",
//     WARNING: "WARNING",
//     ERROR: "ERROR",
// })

enum AlertTypes {
    NONE,
    INFO,
    WARNING,
    ERROR,
}

export default AlertTypes;