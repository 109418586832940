import * as React from "react";
import "./styles/NotificationSettingsBox.css";

import settingsIcon from 'src/icons/Settings';
import ContentBox from 'src/MolekuleComponents/ContentBox';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import Button from 'src/AtomComponents/Button';
import Header1 from 'src/AtomComponents/Header1';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import { IReduxState } from 'src/redux';
import SettingsActionCreators from './redux/ActionCreators';

interface IOwnProps extends RouteComponentProps<any> {
}


interface IStateToProps {
    savingSettings: boolean;
    loadingSettings: boolean;
    noNotificationSettingsFlag: boolean;
}

interface IDispatchToProps {
    fetchNotificationSettings: () => Promise<any>
}

type INoNotificationSettingsBoxProps = IOwnProps & IStateToProps & IDispatchToProps;

class NoNotificationSettingsBox extends React.Component<INoNotificationSettingsBoxProps> {

    public render() {
        const { savingSettings, loadingSettings, noNotificationSettingsFlag, fetchNotificationSettings } = this.props;

        if (savingSettings || loadingSettings || !noNotificationSettingsFlag) { return <div />; }
        return (
            <ContentBox
                isSingleCollumnBox={true}
                title={"Keine Benachrichtigungen"}
                titleIcon={settingsIcon}
            >
                <div className="settingsBoxContentContainer">
                    <div className="settingsProductUpdateAndNotificationsContainer">
                        <Header1>Produktinformationen und Benachrichtigungen</Header1>
                        <div className="notifyByMailContainer">
                            Ihnen stehen keine Einstellungen für Benachrichtigungen zur Verfügung. Einstellungen zu Banachrichtigungen sind nur für Nutzer verfügbar, die ein iOS- oder Android-Smartphone registriert haben.
                        </div>
                        <div style={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
                            <Button
                                orange={true}
                                onClick={fetchNotificationSettings}
                                style={{ width: "320px" }}
                            >
                                Neu laden
                            </Button>
                        </div>
                    </div>
                </div>
            </ContentBox >
        );
    }
}


const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { settings } = state;
    const { loadingSettings, savingSettings, noNotificationSettingsFlag } = settings;
    const result: IStateToProps = {
        ...ownProps,
        savingSettings: savingSettings || false,
        loadingSettings: loadingSettings || false,
        noNotificationSettingsFlag: noNotificationSettingsFlag || false,
    }
    return result;
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
    const actionCreators = new SettingsActionCreators();
    const result: IDispatchToProps = {
        ...ownProps,
        fetchNotificationSettings: () => (dispatch as ReduxThunkDispatch<IReduxState>)(actionCreators.fetchNotificationSettings()),
    }
    return result;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NoNotificationSettingsBox));