import { AnyAction, Dispatch } from "redux";

import { getApiClient } from 'src/api';

import { NotificationsSettings } from 'src/api/Entities';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import { IReduxState } from 'src/redux';
import types from "./ActionTypes";

class SettingsActionCreators {

    // constructor() {
    //     this.retrieveCurrentUserNotificationSettings = this.retrieveCurrentUserNotificationSettings.bind(this);
    // }

    public setNotificationSettings(notificationSettings: NotificationsSettings | undefined): AnyAction {
        const result: AnyAction = { type: types.SETTINGS_SET_NOTIFICATION_SETTINGS, notificationSettings: notificationSettings || undefined }
        return result;
    }

    public setNotificationSettingsFlag(noNotificationSettingsFlag: boolean): AnyAction {
        const result: AnyAction = { type: types.SETTINGS_SET_NO_NOTIFICATION_SETTINGS_FLAG, flag: noNotificationSettingsFlag || false }
        return result;
    }

    public setLoadingNotificationSettings(loading: boolean): AnyAction {
        return { type: types.SETTINGS_SET_LOADING_NOTIFICATION_SETTINGS, loading: loading || false };
    }

    public setSavingNotificationSettings(saving: boolean): AnyAction {
        const result: AnyAction = { type: types.SETTINGS_SET_SAVING_NOTIFICATION_SETTINGS, saving: saving || false }
        return result;
    }

    public fetchNotificationSettings(): (dispatch: Dispatch, getState: () => IReduxState) => Promise<any> {
        return async (dispatch, getState) => {
            try {
                dispatch(this.setLoadingNotificationSettings(true));
                const client = getApiClient();
                const { authentication } = getState();
                const { customer } = authentication;
                const notificationSettings = customer ? await client.notificationsSettingsClient.getCurrent() : undefined;
                dispatch(this.setNotificationSettings(notificationSettings));
            }
            catch (err) {
                const response = err as Response;
                if (response.status === 404) {
                    dispatch(this.setNotificationSettingsFlag(true));
                }
            }
            finally {
                dispatch(this.setLoadingNotificationSettings(false));
            }

        }
    }

    public saveNotificationSettings(settings: NotificationsSettings): (dispatch: Dispatch, getState: () => IReduxState) => Promise<any> {
        return async (dispatch, getState) => {
            try {
                dispatch(this.setLoadingNotificationSettings(true));
                const client = getApiClient();
                dispatch(this.setSavingNotificationSettings(true));
                await client.notificationsSettingsClient.putCurrent(settings);
                await (dispatch as ReduxThunkDispatch<IReduxState>)(this.fetchNotificationSettings());
            } finally {
                dispatch(this.setSavingNotificationSettings(false));
            }

        }
    }

}

export default SettingsActionCreators;