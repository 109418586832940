import * as React from "react";

import StringHelper from 'src/helpers/StringHelper';
import "./styles/CheckboxInput.css";

import checkboxIcon from 'src/icons/Checkbox';
import checkboxEmptyIcon from 'src/icons/CheckboxEmpty';

export interface ICheckboxInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    inlineWithNormalText?: boolean;
}

export const CheckboxInput = (props: ICheckboxInputProps) => {
    const { inlineWithNormalText, type, checked, ...inputAttributes } = props;
    const { className, disabled } = inputAttributes;

    const stringHelper = new StringHelper();
    const chkBoxClassName = stringHelper.joinManyClassNames(
        "ttCheckboxInput",
        checked ? "checked" : undefined,
        className ? (` ${className}`) : undefined,
        disabled ? " disabled" : undefined);

    return (
        inlineWithNormalText ?
            <span className={stringHelper.joinManyClassNames(inlineWithNormalText ? "inlineCheckboxContainer" : undefined)}>
                <input
                    {...inputAttributes}
                    checked={checked}
                    className={chkBoxClassName}
                    style={{ backgroundImage: `url(${checked ? checkboxIcon : checkboxEmptyIcon})` }}
                    type="checkbox" />
            </span> :
            <input
                {...inputAttributes}
                checked={checked}
                className={chkBoxClassName}
                style={{ backgroundImage: `url(${checked ? checkboxIcon : checkboxEmptyIcon})` }}
                type="checkbox" />
    );
}

export default CheckboxInput;