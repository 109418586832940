import * as React from "react";
import "./styles/Badge.css";

import StringHelper from 'src/helpers/StringHelper';
import BadgeColor from './interfaces/BadgeColor';

interface IBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
    badgeColor?: BadgeColor;
    onClickListener?: () => void;
}

const Badge = (props: IBadgeProps) => {
    const { badgeColor, onClickListener, className: cn, ...rest } = props;
    const stringHelper = new StringHelper();

    const isBadgeColor = (expectedColor: BadgeColor) => {
        return (expectedColor === BadgeColor.ORANGE && !badgeColor) || badgeColor === expectedColor;
    }

    const className = stringHelper.joinManyClassNames(
        "badgeContainer",
        isBadgeColor(BadgeColor.ORANGE) ? "orange" : "",
        isBadgeColor(BadgeColor.BLUE) ? "blue" : "",
        onClickListener && "clickable",
        cn
    );

    const contentClassName = stringHelper.joinManyClassNames(
        "badgeContainerContent"
    );

    const onClick = () => onClickListener && onClickListener()

    return (
        <div className={className} onClick={onClick}>
            <div className={contentClassName} {...rest} />
        </div>
    );
}

export default Badge;