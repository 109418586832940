import * as React from "react";
import './styles/OrderPageContent.css';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { getApiClient } from 'src/api';
import { ContractForPresentation, Customer } from 'src/api/Entities';
import AlertTypes from 'src/App/interfaces/AlertTypes';
import IAppAlertData from 'src/App/interfaces/AppAlertData';
import AppActionCreators from 'src/App/redux/ActionCreators';
import ContractActionCreators from 'src/Contract/redux/ActionCreators';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import { LoadingContainer } from 'src/MolekuleComponents/LoadingContainer';
import { IReduxState } from 'src/redux';
import TurboForPresentation from 'src/Turbo/interfaces/TurboForPresentation';
import OderBox from './OderBox';
import OrderActionCreators from './redux/ActionCreators';

interface IOrderPageContentParams {
    turboId: string
}

interface IOwnProps extends RouteComponentProps<IOrderPageContentParams> {

}

interface IDispatchToProps {
    retrieveContract: () => Promise<any>;
    retrieveTurbo: (turboId: number | undefined) => Promise<any>;
    addAlert: (data: IAppAlertData) => any;
    setPlacingOrder: (placing: boolean) => any;
}

interface IStateToProps {

    loadingTurbo: boolean;
    placingOrder: boolean;

    turbo: TurboForPresentation | undefined;

    loadingContract: boolean;
    contract: ContractForPresentation | undefined;

    customer: Customer | undefined;
}

type IOrderPageContentProperties = IStateToProps & IDispatchToProps & IOwnProps;

class OrderPageContent extends React.Component<IOrderPageContentProperties, { termsRead: boolean; privacyAccepted: boolean }> {
    public state = { termsRead: false, privacyAccepted: false };

    constructor(props: IOrderPageContentProperties) {
        super(props);
        this.onReadTerms = this.onReadTerms.bind(this);
        this.onAcceptPrivacy = this.onAcceptPrivacy.bind(this);
        this.placeOrder = this.placeOrder.bind(this);
    }

    public componentWillMount() {
        // TODO: fetch orders, user etc. to view selected order!
        const { match, contract, retrieveContract, retrieveTurbo } = this.props;
        if (!contract) { retrieveContract(); }
        const turboIdParam = match.params.turboId;
        if (turboIdParam) {
            retrieveTurbo(+turboIdParam)
        }
    }

    public render() {
        const { customer, contract, loadingContract, placingOrder, loadingTurbo, turbo } = this.props;
        return (
            <div className={"orderPage"} >
                {loadingTurbo || loadingContract || placingOrder ?
                    <LoadingContainer
                        addWhiteBackgroundColor={true}
                        className={"globalLoadingIndicator"} /> :
                    turbo && customer && contract ?
                        <div className="defaultPageContent orderPageContent">
                            <OderBox
                                customer={customer}
                                contract={contract}
                                turbo={turbo}
                                termsRead={this.state.termsRead}
                                onReadTerms={this.onReadTerms}
                                privacyAccepted={this.state.privacyAccepted}
                                onAcceptPrivacy={this.onAcceptPrivacy}
                                placeOrder={this.placeOrder}
                            />
                        </div> :
                        "No turbo selected?"}
            </div>
        );
    }

    private onReadTerms(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ termsRead: e.target.checked });
    }

    private onAcceptPrivacy(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ privacyAccepted: e.target.checked });
    }

    private async placeOrder(): Promise<void> {
        // todo: placeOrder from props

        if (this.props.turbo) {

            try {
                const { setPlacingOrder } = this.props;
                setPlacingOrder(true);
                await getApiClient().turboOrderClient.postPlaceByTurboIdByAsSubscription(this.props.turbo.id, false);
                const { history, match } = this.props;
                history.push(`${match.url}/confirmed`)
            } catch (err) {
                const { addAlert } = this.props;
                if (err.message && err.title) {
                    addAlert({
                        type: AlertTypes.ERROR,
                        title: err.title,
                        message: err.message + (err.code && ` (${err.code})`),
                    })
                }
                else {
                    addAlert({ message: "Fehler beim Absenden des Auftrags", type: AlertTypes.ERROR });
                }
            } finally {
                const { setPlacingOrder } = this.props;
                setPlacingOrder(false);
            }
        }

    }

}


const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { order, contract, authentication } = state;

    const result: IStateToProps = {
        ...ownProps,
        loadingTurbo: order.loadingTurbo,
        placingOrder: order.placingOrder,
        turbo: order.turbo,
        customer: authentication.customer,

        loadingContract: contract.loadingContract || false,
        contract: contract.contract,
    }
    return result;
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
    const contractActionCreators = new ContractActionCreators();
    const appActionCreators = new AppActionCreators();
    const actionCreators = new OrderActionCreators();
    const result: IDispatchToProps = {
        ...ownProps,
        retrieveContract: () => (dispatch as ReduxThunkDispatch<IReduxState>)(contractActionCreators.retrieveContract()),
        retrieveTurbo: (turboId: number | undefined) => (dispatch as ReduxThunkDispatch<IReduxState>)(actionCreators.retrieveTurbo(turboId)),
        setPlacingOrder: placing => dispatch(actionCreators.setPlacingOrder(placing)),
        addAlert: (data: IAppAlertData) => (dispatch as ReduxThunkDispatch<IReduxState>)(appActionCreators.addAppAlert(data)),
    }
    return result;
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderPageContent));