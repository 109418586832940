import { AnyAction, Dispatch } from "redux";
import AuhtenticationActionCreators from 'src/Authentication/redux/ActionCreators';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import { IReduxState } from 'src/redux';
import IAppAlertData from '../interfaces/AppAlertData';
import types from "./ActionTypes";
import IDialogBoxMessage from 'src/DialogBox/interfaces/IDialogBoxMessage';

class AppActionCreators {
    constructor() {
        this.initializeApplication = this.initializeApplication.bind(this);
    }
    public setAppInitialized(initialized: boolean): AnyAction {
        const result: AnyAction = { type: types.SET_APP_INITIALIZED, initialized: initialized || undefined }
        return result;
    }

    public addAppAlert(alertData: IAppAlertData): AnyAction {
        const result: AnyAction = { type: types.ADD_APP_ALERT, data: alertData || undefined }
        return result;
    }

    public discardAppAlert(alertData: IAppAlertData): AnyAction {
        const result: AnyAction = { type: types.DISCARD_APP_ALERT, data: alertData || undefined }
        return result;
    }

    public clearAppAlerts(alertData: IAppAlertData): AnyAction {
        const result: AnyAction = { type: types.CLEAR_APP_ALERTS }
        return result;
    }

    public addAppMessage(message: IDialogBoxMessage): AnyAction {
        const result: AnyAction = { type: types.ADD_APP_MESSAGE, data: message }
        return result;
    }

    public discardAppMessage(message: IDialogBoxMessage): AnyAction {
        const result: AnyAction = { type: types.DISCARD_APP_MESSAGE, data: message }
        return result;
    }

    public clearAppMessages(): AnyAction {
        const result: AnyAction = { type: types.CLEAR_APP_MESSAGES }
        return result;
    }

    public initializeApplication(): (dispatch: Dispatch) => Promise<any> {
        return async (dispatch: Dispatch) => {

            const authActionCreator = new AuhtenticationActionCreators();
            await (dispatch as ReduxThunkDispatch<IReduxState>)(authActionCreator.initializeCurrentUser());
            dispatch(this.setAppInitialized(true));

        }
    }
}

export default AppActionCreators;