import * as React from "react";

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { TurboOrderForPresentation, OrderStatus, } from 'src/api/Entities';
import { IReduxState } from 'src/redux';
import Header1 from 'src/AtomComponents/Header1';
import { TurboNameContainer } from './TurboNameContainer';
import { Dispatch } from 'redux';
import { DateHelper } from 'src/helpers/DateHelper';

interface IOwnProps extends RouteComponentProps<any> {

}

// tslint:disable-next-line:no-empty-interface
interface IDispatchToProps {
}

interface IStateToProps {
    activeNonRunningTurbos: TurboOrderForPresentation[];
}

type DetailsActiveNotRunningTurbosInfoBoxProps = IStateToProps & IOwnProps;

const DetailsActiveNotRunningTurbosInfoBox = (props: DetailsActiveNotRunningTurbosInfoBoxProps) => {
    const { activeNonRunningTurbos } = props;

    return (
        <React.Fragment>
            {(activeNonRunningTurbos || []).map((t, i) => <DetailsActiveNotRunningTurboInfoBox key={i} pendingTurbo={t} />)}
        </React.Fragment>)

}
const DetailsActiveNotRunningTurboInfoBox = (props: { pendingTurbo: TurboOrderForPresentation }) => {
    const { pendingTurbo } = props;
    const dateHelper = new DateHelper();

    return (
        <React.Fragment>
            <Header1>
                <span className={"blue"}>Geschwindigkeit im Anschluss</span>&nbsp;
                </Header1>
            <div style={{ width: "100%" }}>
                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", marginTop: "4px" }}>
                    <div className="detailsInfoCell">
                        Geschwindigkeit demnächst
                                </div>
                    <div className="detailsInfoCell value">
                        <TurboNameContainer turbo={pendingTurbo!.turbo!} isActive={false} />
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", marginTop: "4px" }}>
                    <div className="detailsInfoCell">gebuchte Laufzeit:</div>
                    <div className="detailsInfoCell value" style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            {pendingTurbo!.turbo!.duration}
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", marginTop: "4px" }}>
                    <div className="detailsInfoCell">Bestellt am:</div>
                    <div className="detailsInfoCell value" style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            {pendingTurbo!.orderDate ? (dateHelper.toDateTimeStringFromIsoString(pendingTurbo!.orderDate) + "") : "-"}
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", marginTop: "4px" }}>
                    <div className="detailsInfoCell">Endet am:</div>
                    <div className="detailsInfoCell value" style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            {pendingTurbo!.expiresDate ? (dateHelper.toDateTimeStringFromIsoString(pendingTurbo!.expiresDate!) + "") : "-"}
                        </div>
                    </div>
                </div>
                {pendingTurbo!.subscription ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "4px" }}>
                        <div className="detailsInfoCell" />
                        <div className="detailsInfoCell value">
                            {pendingTurbo!.status !== OrderStatus.Canceled ?
                                "Wird anschließend automatisch verlängert" :
                                "Die automatische Verlängerung wurde beendet."
                            }</div>
                    </div>
                    : <React.Fragment />}
            </div>
        </React.Fragment>);

}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { turbo } = state;

    const result: IStateToProps = {
        ...ownProps,
        activeNonRunningTurbos: turbo.activeNonRunningTurbos,
    }
    return result;
}
const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
    const result: IDispatchToProps = {
        ...ownProps,

    }
    return result;
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsActiveNotRunningTurbosInfoBox));