import * as React from "react";

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ContractForPresentation, TurboOrder } from 'src/api/Entities';
import { IReduxState } from 'src/redux';
import ProductHelper from '../helpers/ProductHelper';
import BandwithSpeedInfoCurve from 'src/MolekuleComponents/BandwithSpeedInfoCurve';
import downloadIcon from 'src/icons/Download';
import { getApiClient } from 'src/api';
import uploadIcon from 'src/icons/Upload';
import BandwithSpeedInfoLine from 'src/MolekuleComponents/BandwithSpeedInfoLine';


interface IOwnProps extends RouteComponentProps<any> {

}


interface IStateToProps {
    activeTurbo: TurboOrder | undefined;
    contract: ContractForPresentation | undefined;
}

type DetailsCurrentBandwidthBoxProps = IStateToProps & IOwnProps;

const DetailsCurrentBandwidthBox = (props: DetailsCurrentBandwidthBoxProps) => {
    const { contract, activeTurbo } = props;
    const productHelper = new ProductHelper();

    const hasActiveTurbo = !!activeTurbo;
    const visibleTurbo = activeTurbo && activeTurbo.turbo;

    const turboBandwidthOption = (visibleTurbo && visibleTurbo.bandwidth) || getApiClient().bandwidthOptionClient.build();
    const maxBandwidthOption = contract && productHelper.getMaxBandwidth(contract.product)
    const productBandwithOption = contract && contract.product.bandwidth;

    return (productBandwithOption && maxBandwidthOption ?
        <div className="turboOrderSpeedInfoContainer">
            <div className="downlaodInfo">
                <span className="hiddenSmartphone">
                    <BandwithSpeedInfoCurve
                        bandwithTypeLabel={"maximale Download-Geschwindigkeit"}
                        currentBandwith={productBandwithOption.download}
                        maxBandwith={maxBandwidthOption.download}
                        turboBandwith={turboBandwidthOption.download}
                        showTurboBandwithInLabel={hasActiveTurbo}
                        icon={downloadIcon}
                        showSpeed={true}
                    />
                </span>
                <span className="hiddenNonSmartphone">
                    <BandwithSpeedInfoLine
                        bandwithTypeLabel="Download:"
                        currentBandwith={productBandwithOption.download}
                        maxBandwith={maxBandwidthOption.download}
                        appliedTurboBandwith={turboBandwidthOption.download}
                        showTurboBandwithInLabel={!!turboBandwidthOption.download}
                        bandwidthClass={hasActiveTurbo ? "orange strong" : undefined}
                        additionalLabel={
                            !!turboBandwidthOption.download ?
                                <React.Fragment>
                                    <span>&nbsp;(Standard:</span>
                                    <span className="blue strong">&nbsp;{productBandwithOption.download} Mbit/s</span>
                                    <span>)</span>
                                </React.Fragment>
                                :
                                ""
                        }
                    />
                </span>
            </div>
            <div className="uploadInfo">
                <span className="hiddenSmartphone">
                    <BandwithSpeedInfoCurve
                        bandwithTypeLabel={"maximale Upload-Geschwindigkeit"}
                        currentBandwith={productBandwithOption.upload}
                        maxBandwith={maxBandwidthOption.upload}
                        turboBandwith={turboBandwidthOption.upload}
                        showTurboBandwithInLabel={hasActiveTurbo}
                        icon={uploadIcon}
                        showSpeed={true}
                    />
                </span>
                <span className="hiddenNonSmartphone">
                    <BandwithSpeedInfoLine
                        bandwithTypeLabel="Upload:"
                        currentBandwith={productBandwithOption.upload}
                        maxBandwith={maxBandwidthOption.upload}
                        appliedTurboBandwith={turboBandwidthOption.upload}
                        showTurboBandwithInLabel={!!turboBandwidthOption.upload}
                        bandwidthClass={hasActiveTurbo ? "orange strong" : undefined}
                        additionalLabel={
                            !!turboBandwidthOption.upload ?
                                <React.Fragment>
                                    <span>&nbsp;(Standard:</span>
                                    <span className="blue strong">&nbsp;{productBandwithOption.upload} Mbit/s</span>
                                    <span>)</span>
                                </React.Fragment>
                                :
                                ""
                        }
                    />
                </span>
            </div>
        </div>
        : <React.Fragment />
    );
}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { contract, turbo } = state;

    const result: IStateToProps = {
        ...ownProps,
        activeTurbo: turbo.activeTurbo,
        contract: contract.contract,
    }
    return result;
}

export default withRouter(connect(mapStateToProps)(DetailsCurrentBandwidthBox));