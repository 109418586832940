import * as React from "react";
import './styles/TurboOrderHistoryPageContent.css';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import ContractActionCreators from 'src/Contract/redux/ActionCreators';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import { IReduxState } from 'src/redux';
import TurboActionCreators from 'src/Turbo/redux/ActionCreators';
import TurboHistoryBox from './TurboHistoryBox';

// tslint:disable-next-line:no-empty-interface
interface IOwnProps extends RouteComponentProps<any> {
    // no own props yet 
}

interface IDispatchToProps {
    loadContract: () => Promise<any>;
    retrieveTurboHistory: (size: number | undefined) => Promise<any>;
}

interface IStateToProps {
    loadingHistory: boolean;
}

type ITurboHistoryPageContentProps = IOwnProps & IDispatchToProps & IStateToProps;

class TurboHistoryPageContent extends React.Component<ITurboHistoryPageContentProps>{

    public componentWillMount() {
        const { loadContract, retrieveTurboHistory } = this.props;
        loadContract();
        retrieveTurboHistory(1);
    }

    public render() {
        return (
            <div
                className={"turboOrderHistoryPageContent"}
            >
                <TurboHistoryBox />
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { turbo } = state;
    const result: IStateToProps = {
        ...ownProps,
        loadingHistory: turbo.loadingTurboHistory,
    }
    return result;
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
    const actionCreators = new ContractActionCreators();
    const turboActionCreators = new TurboActionCreators();
    const result: IDispatchToProps = {
        ...ownProps,
        loadContract: () => (dispatch as ReduxThunkDispatch<IReduxState>)(actionCreators.retrieveContract()),
        retrieveTurboHistory: (size: number | undefined) => (dispatch as ReduxThunkDispatch<IReduxState>)(turboActionCreators.retrieveTurboHistory(size)),
    }
    return result;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TurboHistoryPageContent));