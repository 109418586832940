import * as React from "react";
import "./styles/CheckboxWithLabel.css";

import CheckboxInput, { ICheckboxInputProps } from 'src/AtomComponents/CheckboxInput';
import StringHelper from 'src/helpers/StringHelper';

interface ICheckboxWithLabelProps extends ICheckboxInputProps {
    children?: React.ReactNode;
    className?: string;
}

const CheckboxWithLabel = (props: ICheckboxWithLabelProps) => {
    const { children, className: cn, ...rest } = props;
    const stringHelper = new StringHelper();
    const checkboxId = "checkbox_" + stringHelper.generateGuid();

    const className = stringHelper.joinManyClassNames("checkboxWithLabelContainer", cn);

    return (
        <div className={className}>
            <CheckboxInput
                {...rest}
                className="checkboxWithLabelCheckbox"
                id={checkboxId}
            />
            <label
                className="checkboxWithLabelLabel"
                htmlFor={checkboxId}>
                {children}
            </label>
        </div>);
}

export default CheckboxWithLabel;