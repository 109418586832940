import  { RequestAdapter } from "./RequestAdapter"

export { RequestAdapter } from "./RequestAdapter"

export class BaseClient<T> {
    // tslint:disable-next-line:variable-name
    protected _requestAdapter: RequestAdapter = new RequestAdapter();
    // tslint:disable-next-line:variable-name
    protected _apiClient: T;
    constructor(apiClient: T, requestAdapter?: RequestAdapter) {
        this._requestAdapter = requestAdapter || this._requestAdapter;
        this._apiClient = apiClient;
    }
}