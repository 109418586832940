
import { PagedItems } from 'src/api/ApiTypes';
import { TurboOrderForPresentation } from 'src/api/Entities';

export interface ITurboState {

    pendingTurbos: PagedItems<TurboOrderForPresentation> | undefined;
    // activeTurbos: PagedItems<TurboOrderForPresentation> | undefined;
    activeTurbo: TurboOrderForPresentation | undefined;
    activeNonRunningTurbos: TurboOrderForPresentation[],

    loadingTurboHistory: boolean;
    turboHistory: PagedItems<TurboOrderForPresentation> | undefined;
}

export const defaultState: ITurboState = {
    pendingTurbos: undefined,
    activeTurbo: undefined,
    activeNonRunningTurbos: [],

    loadingTurboHistory: false,
    turboHistory: undefined,
}

export default ITurboState;