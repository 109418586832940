import * as React from "react"
import "./styles/BurgerMenueLinks.css";

import contactIcon from 'src/icons/Contact';
import dashboardIcon from 'src/icons/Dashboard';
import helpIcon from 'src/icons/Help';
import impressumIcon from 'src/icons/Impressum';
import privacyIcon from 'src/icons/Privacy';
import settingsIcon from 'src/icons/Settings';
// import turboTasteIcon from 'src/icons/TurboTasteSmall';
import BurgerMenueLink from './BurgerMenueLink';
import tarifDetailsIcon from 'src/icons/TarifDetails';
import historyIcon from 'src/icons/Historie';



interface IBurgerMenueLinksProps { onLinkClicked?: () => any }

const BurgerMenueLinks = (props: IBurgerMenueLinksProps) => {
    const { onLinkClicked } = props;
    return (
        <div className="burgerMenueLinks">
            <BurgerMenueLink
                to="/"
                exact={true}
                icon={dashboardIcon}
                onLinkClicked={onLinkClicked}
            >
                Dashboard
            </BurgerMenueLink>
            {/* <div className="burgerMenueLinksHeader">
                Produkte
            </div>
            <BurgerMenueLink
                to="/product/turbo"
                icon={turboTasteIcon}
                onLinkClicked={onLinkClicked}
            >
                Turbotaste
            </BurgerMenueLink> */}
            <div className="menueSplitter" />
            <div className="burgerMenueLinksHeader">
                Service
            </div>
            <BurgerMenueLink
                to="/product/currentBandwith"
                icon={tarifDetailsIcon}
                onLinkClicked={onLinkClicked}
            >
                Ihre Details
            </BurgerMenueLink>
            
            <BurgerMenueLink
                to="/settings"
                icon={settingsIcon}
                onLinkClicked={onLinkClicked}
            >
                Einstellungen
            </BurgerMenueLink>
            <BurgerMenueLink
                to="/product/turbo/order/history"
                icon={historyIcon}
                onLinkClicked={onLinkClicked}
            >
                Historie
            </BurgerMenueLink>
            {/*to="/help" */}
            <BurgerMenueLink
                isHrefAnchor={true}
                openAsBlank={true}
                to="https://faq.dokom.net/"
                icon={helpIcon}
                onLinkClicked={onLinkClicked}
            >
                Hilfe
            </BurgerMenueLink>
            {/* to="/contact" */}
            <BurgerMenueLink
                isHrefAnchor={true}
                openAsBlank={true}
                to="https://www.dokom21.de/Kontakt"
                icon={contactIcon}
                onLinkClicked={onLinkClicked}
            >
                Kontakt
            </BurgerMenueLink>
            {/*to="/privacy" */}
            <BurgerMenueLink
                isHrefAnchor={true}
                openAsBlank={true}
                to="https://www.dokom21.de/de/Unternehmen/Datenschutz.htm"
                icon={privacyIcon}
                onLinkClicked={onLinkClicked}
            >
                Datenschutz
            </BurgerMenueLink>
            {/*to="/impressum" */}
            <BurgerMenueLink
                isHrefAnchor={true}
                openAsBlank={true}
                to="https://www.dokom21.de/de/Unternehmen/Impressum.htm"
                icon={impressumIcon}
                onLinkClicked={onLinkClicked}
            >
                Impressum
            </BurgerMenueLink>
        </div>
    );
}

export default BurgerMenueLinks;