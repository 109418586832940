import { combineReducers } from 'redux';

import app from "../App/redux";
import authentication from "../Authentication/redux";
import contract from "../Contract/redux";
import order from "../Order/redux";
import settings from "../Settings/redux";
import turbo from "../Turbo/redux";

const reducer = combineReducers({
    app,
    authentication,
    contract,
    order,
    settings,
    turbo
});

export default reducer;