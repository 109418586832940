const TurboActionTypes = Object.freeze({



    SET_ACTIVE_TURBO: "SET_ACTIVE_TURBO",
    SET_ACTIVE_NOT_RUNNING_TURBOS: "SET_ACTIVE_NOT_RUNNING_TURBOS",
    SET_PENDING_TURBOS: "SET_PENDING_TURBOS",
    SET_TURBO_HISTORY: "SET_TURBO_HISTORY",

    SET_LOADING_TURBO_HISTORY: "SET_LOADING_TURBO_HISTORY",
})

export default TurboActionTypes;