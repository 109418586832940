import * as React from "react";

import { Link, LinkProps } from 'react-router-dom';
import "./styles/Button.css";

interface ILinkButtonProps extends LinkProps {

    disabled?: boolean;
    orange?: boolean;
}

export const LinkButton = (props: ILinkButtonProps) => {
    const { orange, disabled, className, to, ...linkProps } = props;

    const buttonClassName = "ttButton" + (orange ? " orange" : "") + (className ? (` ${className}`) : "") + (disabled ? " disabled" : "");

    return (
        <Link {...linkProps} to={disabled ? "" : to} className={buttonClassName} />
    );
}

export default LinkButton;