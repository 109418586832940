import store from 'src/redux';
import IAppAlertData from '../interfaces/AppAlertData';
import AppActionCreators from '../redux/ActionCreators';
import AlertTypes from '../interfaces/AlertTypes';


export default class AppAlertHelper {
    public addAppAlert(data: IAppAlertData) {
        if (store) {
            try {
                store.dispatch(new AppActionCreators().addAppAlert(data))
            } catch (err) {
                // tslint:disable-next-line:no-console
                console.error("Failed to add alert.", data)
            }
        }
    }
    public addError(message: string, title?: string, icon?: ImageModules) {
        this.add(AlertTypes.ERROR, message, title, icon);
    }
    public addInfo(message: string, title?: string, icon?: ImageModules) {
        this.add(AlertTypes.INFO, message, title, icon);
    }
    public add(type: AlertTypes, message: string, title?: string, icon?: ImageModules) {
        this.addAppAlert({
            type: type || AlertTypes.NONE,
            message: message || "",
            title: title || "",
            icon: icon || undefined,
            noIcon: icon === undefined,
        });
    }
}