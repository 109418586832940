import { AnyAction, Dispatch } from "redux";
import { getApiClient } from 'src/api';
import {  ContractForPresentation } from 'src/api/Entities';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import delay from 'src/helpers/delay';
import OrderActionCreators from 'src/Order/redux/ActionCreators';
import { IReduxState } from 'src/redux';
import TurboActionCreators from 'src/Turbo/redux/ActionCreators';
import types from "./ActionTypes";
import AppAlertHelper from '../../App/helper/AppAlertHelper';
import DialogBoxHelper from '../../App/helper/DialogBoxHelper';

class ContractActionCreators {

    constructor() {
        this.retrieveContract = this.retrieveContract.bind(this);
    }

    public setLoadingContract(loading: boolean): AnyAction {
        const result: AnyAction = { type: types.SET_LOADING_CONTRACT, loading: loading || false }
        return result;
    }
    public setContract(contract: ContractForPresentation | undefined): AnyAction {
        const result: AnyAction = { type: types.SET_CONTRACT, contract: contract || undefined }
        return result;
    }

    // Dispatch action for thunk middleware
    public retrieveContract(): (dispatch: Dispatch, getState: () => IReduxState) => Promise<any> {
        return async (dispatch, getState) => {

            try {
                const client = getApiClient();
                // are we logged in?
                dispatch(this.setLoadingContract(true));
                dispatch(this.setContract(undefined));

                await delay(1000);

                const { authentication } = getState();
                const customer = authentication.customer;
                const contract = customer ?
                    await client.customerClient.readContractProjection<ContractForPresentation>(customer, "contractForPresentation") :
                    undefined;

                const turboActionCreator = new TurboActionCreators();
                await (dispatch as ReduxThunkDispatch<IReduxState>)(turboActionCreator.retrievePendingeTurbos());
                await (dispatch as ReduxThunkDispatch<IReduxState>)(turboActionCreator.retrieveActiveTurbos());
                await (dispatch as ReduxThunkDispatch<IReduxState>)(turboActionCreator.retrieveActiveNotRunningTurbos());

                dispatch(this.setContract(contract));
                
                await (dispatch as ReduxThunkDispatch<IReduxState>)(new OrderActionCreators().calculateSelectableOfferOptions());

            } finally {
                dispatch(this.setLoadingContract(false))
            }
        }
    }

    public activateProduct(): (dispatch: Dispatch, getState: () => IReduxState) => Promise<any> {
        return async (dispatch, getState) => {

            try {
                const client = getApiClient();
                const { contract } = getState();

                if (contract.contract) {
                    await client.productClient.putByProductIdActivate(contract.contract.product.id)
                }


                const activationSuccessMessage = {
                    title: "Produkt aktiviert",
                    text: `Ihr Produkt wird in Kürze aktiviert. Sie werden per E-Mail benachrichtigt, sobald Ihr Router die Konfiguration übernommen hat. Dies kann einige Minuten in Anspruch nehmen.`, // "Ihr Produkt wurde erfolgreich aktiviert. Sie werden benachrichtigt, sobald Ihr Router die Konfiguration übernommen hat. Das kann einige Minuten in anspruch nehmen.",
                    okButtonText: "Ok",
                    onOkButtonClick: async () => {
                        dispatch(this.setLoadingContract(false));
                        new DialogBoxHelper().discardAppMessage(activationSuccessMessage);
                    },
                    cancelButtonText: undefined,
                    onCancelButtonClick: undefined
                }
                new DialogBoxHelper().addAppMessage(activationSuccessMessage);
            }
            catch(err) {
                new AppAlertHelper().addError("Das Aktivieren ist fehlgeschlagen.\r\nBitte versuchen Sie es später erneut oder wenden Sie sich an unseren Kundendienst.","Aktivierung fehlgeschlagen");
            } finally {
                dispatch(this.setLoadingContract(false))
            }
        }
    }


}

export default ContractActionCreators;