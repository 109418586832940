import { AnyAction } from 'redux';

import types from "./ActionTypes";
import IReduxState, { defaultState } from "./State";

export const TurboReducer = (state: IReduxState = defaultState, action: AnyAction): IReduxState => {
    switch (action.type) {
        case types.SET_ACTIVE_TURBO:
            return { ...state, activeTurbo: (action as any).turbo || undefined };
            case types.SET_ACTIVE_NOT_RUNNING_TURBOS:
                return { ...state, activeNonRunningTurbos: (action as any).turbos || undefined };
        case types.SET_PENDING_TURBOS:
            return { ...state, pendingTurbos: (action as any).turbos || undefined };

        case types.SET_LOADING_TURBO_HISTORY:
            return { ...state, loadingTurboHistory: (action as any).loading || false };
        case types.SET_TURBO_HISTORY:
            return { ...state, turboHistory: (action as any).turbos || undefined };

        default:
            return state;
    }
}

export default TurboReducer;