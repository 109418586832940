import * as React from "react";
import './styles/DialogBox.css';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import turboTasteIcon from 'src/icons/TurboTasteSmall';
import ContentBox from 'src/MolekuleComponents/ContentBox';
import Button from "src/AtomComponents/Button";
import IDialogBoxMessage from './interfaces/IDialogBoxMessage';
import { LoadingContainer } from 'src/MolekuleComponents/LoadingContainer';

interface IOwnProps extends RouteComponentProps<any> {
    message: IDialogBoxMessage | undefined;
}

// tslint:disable-next-line:no-empty-interface
interface IDispatchToProps {
    // no dispatch to props yet 
}

// tslint:disable-next-line:no-empty-interface
interface IStateToProps {
    // no state props yet 
}

type IDialogBoxProps = IOwnProps & IDispatchToProps & IStateToProps;

interface IDialogBoxState {
    waiting: boolean
}

class DialogBox extends React.Component<IDialogBoxProps, IDialogBoxState>{
    constructor(props: IDialogBoxProps) {
        super(props)

        this.state = {
            waiting: false
        }
    }

    public componentWillReceiveProps(nextProps: IDialogBoxProps) {
        if (this.props !== nextProps) {
            this.setState({ waiting: false })
        }
    }

    public render() {
        const { message } = this.props;

        const okButton =
            <Button
                key="okButton"
                className={"saveButton"}
                orange={true}
                onClick={this.onOkButtonClick}
                style={{
                    width: "320px",
                    maxWidth: "320px",
                    marginRight:
                        message && message.onCancelButtonClick ?
                            "8px" :
                            undefined,
                    marginLeft:
                        message && message.onCancelButtonClick ?
                            "8px" :
                            undefined,

                }}
            >
                {message && message.okButtonText}
            </Button>

        const cancelButton = message && message.onCancelButtonClick &&
            <Button
                key="cancelButton"
                className={"cancelButton"}
                orange={false}
                onClick={this.onCancelButtonClick}
                style={{
                    width: "320px",
                    maxWidth: "320px",
                    marginRight: "8px",
                    marginLeft: "8px"
                }}
            >
                {message.cancelButtonText}
            </Button>

        const loadingSpinner = this.state.waiting && <LoadingContainer />

        return (
            message &&
            <div className="dialogOverlay"  >
                <ContentBox
                    className={`${message.containerClass ? message.containerClass : ""} dialogBox`}
                    isSingleCollumnBox={true}
                    title={message.title}
                    titleIcon={turboTasteIcon}
                    style={{
                        minWidth: message.minWidth ? message.minWidth : undefined,
                        width: message.width ? message.width : undefined,
                        ...(message.additionalStyles ? message.additionalStyles : {})
                    }}>
                    <p className="contextBoxText">
                        <pre>{message.text}</pre>
                    </p>
                    {this.state.waiting ? (loadingSpinner) : (
                        <div className="dialogBoxButtons">
                            {cancelButton}
                            {okButton}
                        </div>)}
                </ContentBox>
            </div>
        );
    }

    private onOkButtonClick = () => {
        const { message } = this.props;
        this.setState({ waiting: true });

        if (message) {
            message.onOkButtonClick();
        }
    }

    private onCancelButtonClick = () => {
        const { message } = this.props;
        this.setState({ waiting: true });

        if (message && message.onCancelButtonClick) {
            message.onCancelButtonClick();
        }
    }
}

export default withRouter(DialogBox);