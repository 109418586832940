import * as React from "react";
import "./styles/ContentBoxSaveButtons.css"

import Button from "src/AtomComponents/Button";
import StringHelper from 'src/helpers/StringHelper';

interface IContentBoxSaveButtonsProps {
    className?: string;

    saveButtonDisabled?: boolean;
    cancelButtonDisabled?: boolean;

    customSaveButtonLabel?: React.ReactNode;
    customCancelButtonLabel?: React.ReactNode;

    onSaveClick?: (e: React.MouseEvent<HTMLElement>) => any;
    onCancelClick?: (e: React.MouseEvent<HTMLElement>) => any;
}

const ContentBoxSaveButtons = (props: IContentBoxSaveButtonsProps) => {
    const {
        className: cn,
        onSaveClick,
        onCancelClick,
        saveButtonDisabled,
        cancelButtonDisabled,
        customSaveButtonLabel,
        customCancelButtonLabel,
    } = props;

    const stringHelper = new StringHelper();

    const className = stringHelper.joinManyClassNames("contentBoxSaveButtonsContainer", cn)

    return (
        <div className={className}>
            <div className="cancelButtonContainer">
                <Button
                    disabled={cancelButtonDisabled}
                    className={"cancelButton"}
                    orange={false}
                    whiteBorder={true}
                    onClick={onCancelClick}
                >
                    {customCancelButtonLabel ? customCancelButtonLabel : "Abbrechen"}
                </Button>
            </div>
            <div className="saveButtonContainer">
                <Button
                    disabled={saveButtonDisabled}
                    className={"saveButton"}
                    orange={true}
                    onClick={onSaveClick}
                >
                    {customSaveButtonLabel ? customSaveButtonLabel : "Speichern"}
                </Button>
            </div>
        </div>);
}

export default ContentBoxSaveButtons;
