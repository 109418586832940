import * as React from "react";

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { TurboOrder, } from 'src/api/Entities';
import { IReduxState } from 'src/redux';
import { PagedItems } from 'src/api/ApiTypes';
import Header1 from 'src/AtomComponents/Header1';
import { TurboNameContainer } from './TurboNameContainer';
import { Dispatch } from 'redux';
import { DateHelper } from 'src/helpers/DateHelper';

interface IOwnProps extends RouteComponentProps<any> {

}

// tslint:disable-next-line:no-empty-interface
interface IDispatchToProps {
}

interface IStateToProps {
    activeTurbo: TurboOrder | undefined;
    pendingTurbos: PagedItems<TurboOrder> | undefined;
}

type DetailsPendingTurboInfoBoxProps = IStateToProps & IOwnProps;

const DetailsPendingTurboInfoBox = (props: DetailsPendingTurboInfoBoxProps) => {
    const { pendingTurbos } = props;
    const dateHelper = new DateHelper();
    const pendingTurbo = (pendingTurbos && pendingTurbos.items.length > 0) ? pendingTurbos.items[0] : undefined;
    const hasPendingTurbo = !!pendingTurbo;

    return (hasPendingTurbo ?
        <React.Fragment>
            <Header1>
                <span className={"blue"}>Turbo bestellt</span>&nbsp;
                </Header1>
            <div style={{ width: "100%" }}>
                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", marginTop: "4px" }}>
                    <div className="detailsInfoCell">
                        Geschwindigkeit demnächst
                                </div>
                    <div className="detailsInfoCell value">
                        <TurboNameContainer turbo={pendingTurbo!.turbo!} isActive={false} />
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", marginTop: "4px" }}>
                    <div className="detailsInfoCell">gebuchte Laufzeit:</div>
                    <div className="detailsInfoCell value" style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            {pendingTurbo!.turbo!.duration}
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", marginTop: "4px" }}>
                    <div className="detailsInfoCell">Bestellt am:</div>
                    <div className="detailsInfoCell value" style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            {pendingTurbo!.orderDate ? (dateHelper.toDateTimeStringFromIsoString(pendingTurbo!.orderDate) + "") : "-"}
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", marginTop: "4px" }}>
                    <div className="detailsInfoCell">Endet am:</div>
                    <div className="detailsInfoCell value" style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            {pendingTurbo!.expiresDate ? (dateHelper.toDateTimeStringFromIsoString(pendingTurbo!.expiresDate!) + "") : "-"}
                        </div>
                    </div>
                </div>
                {pendingTurbo!.subscription ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "4px" }}>
                        <div className="detailsInfoCell" />
                        <div className="detailsInfoCell value">Wird anschließend automatisch verlängert</div>
                    </div>
                    : <React.Fragment />}
            </div>
        </React.Fragment> :
        <React.Fragment />)

}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { turbo } = state;

    const result: IStateToProps = {
        ...ownProps,
        activeTurbo: turbo.activeTurbo,
        pendingTurbos: turbo.pendingTurbos,
    }
    return result;
}
const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
    const result: IDispatchToProps = {
        ...ownProps,

    }
    return result;
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsPendingTurboInfoBox));