import { Store } from "redux";
import { IReduxState } from 'src/redux';

import ITokenAuthenticationEventsHelper from 'src/api/ITokenAuthenticationEventsHelper';
import ITokenAuthenticationHelper from 'src/api/ITokenAuthenticationHelper';
import IAuthApiClient from 'src/api/IUserApiClient';
import defaultStore from "../../redux/index";
import AuhtenticationActionCreators from '../redux/ActionCreators';

export class TokenAuthenticationHelper implements ITokenAuthenticationHelper, ITokenAuthenticationEventsHelper {


    private readonly store: Store<IReduxState>;
    private readonly apiClient: IAuthApiClient;

    constructor(apiClient: IAuthApiClient, store?: Store<IReduxState>) {
        this.store = store || defaultStore;
        this.apiClient = apiClient;
    }

    public getAccessToken(): string | undefined {
        const { authentication } = this.store.getState();
        return authentication.authData ? authentication.authData.access_token : undefined;
    }

    public async refreshAccessToken(): Promise<string | undefined> {
        const { authentication } = this.store.getState();
        if (authentication.authData) {
            try {
                const nextData = await this.apiClient.auth.refreshToken(authentication.authData);
                this.store.dispatch(new AuhtenticationActionCreators().setAuthData(nextData));
                return nextData ? nextData.access_token : undefined;
            } catch (err) {
                // tslint:disable-next-line:no-console
                console.error(err);
                throw err;
            }
        }

        return undefined;
    }

    public async authenticationFailed(): Promise<any> {
        this.store.dispatch(new AuhtenticationActionCreators().setAuthData(undefined));
    }
}

export default TokenAuthenticationHelper;