import * as React from 'react';
import "./styles/Navigation.css";

import UserInfo from 'src/Authentication/UserInfo';
import delay from 'src/helpers/delay';
import StringHelper from 'src/helpers/StringHelper';
import burgerIcon from 'src/icons/Burger';
import BurgerMenueLinks from './BurgerMenueLinks';

// tslint:disable-next-line:no-empty-interface
export interface INavigationProperties {
    visible: boolean;
}

// tslint:disable-next-line:no-empty-interface
export interface INavigationState {
    expanding?: boolean;
    collapsing?: boolean;
    expanded: boolean;
}

class Navigation extends React.Component<INavigationProperties, INavigationState>{
    public state: INavigationState;
    private container: HTMLDivElement | undefined | null;
    constructor(props: INavigationProperties) {
        super(props)

        this.expandMenue = this.expandMenue.bind(this);
        this.onDocumentClick = this.onDocumentClick.bind(this);

        this.state = { expanded: false };
    }

    public render() {
        const { visible } = this.props;
        const { expanded, collapsing, expanding } = this.state;

        const stringHelper = new StringHelper();
        const className = stringHelper.joinManyClassNames(
            "appNavigationContainer",
            visible ? "visible" : "hidden");

        const menueContentClassName =
            "burgerMenueMenueContentWrapper" +
            (expanded ? " expanded" : "") +
            (!expanded && !collapsing && !expanding ?
                " collapsed" :
                (collapsing || expanding) ? " expanding" : "");

        const mobileMenueContentClassName =
            "burgerMenueMenueMobileContentWrapper" +
            (expanded ? " expanded" : "") +
            (!expanded && !collapsing && !expanding ?
                " collapsed" :
                (collapsing || expanding) ? " expanding" : "");


        const expandClick = () => this.expandMenue(true);
        const collapseClick = () => this.expandMenue(false);
        const linkClicked = () => this.expandMenue(false);
        return (
            <div className={className} ref={obj => this.container = obj}>
                <div className="burgerMenuIconContainerWrapper">
                    <div
                        className="burgerMenuIconContainer"
                        onClick={expanded ? collapseClick : expandClick}
                    >
                        <img src={burgerIcon} width="24" alt="menu" />
                    </div>
                </div>
                <div className={menueContentClassName}>
                    <div className="burgerMenueContentHeader">
                        <div
                            className="burgerMenuIconContainer"
                            onClick={collapseClick}
                        >
                            <img src={burgerIcon} width="24" alt="menu"/>
                        </div>
                    </div>
                    <UserInfo />
                    <BurgerMenueLinks onLinkClicked={linkClicked} />
                </div>
                <div className={mobileMenueContentClassName}>
                    <UserInfo />
                    <BurgerMenueLinks onLinkClicked={linkClicked} />
                </div>
            </div>
        )
    }

    private async expandMenue(expand: boolean) {

        if (expand) {
            document.addEventListener("click", this.onDocumentClick)
        } else {
            document.removeEventListener("click", this.onDocumentClick)
        }
        this.setState({
            collapsing: !expand,
            expanding: expand,
        });
        await delay(25);
        this.setState({
            expanded: expand,
        });
        await delay(200);

        const { expanded } = this.state;
        if (expanded === expand) {
            this.setState({
                collapsing: false,
                expanding: false,
            });
        }
    }

    private onDocumentClick(e: MouseEvent) {
        if (this.state.expanded &&
            this.container &&
            e &&
            e.target &&
            !this.container.contains(e.target as Node)) {
            this.expandMenue(false);
        }
    }
}


export default Navigation