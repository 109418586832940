import { AnyAction, Dispatch } from "redux";

import { getApiClient, getAuthApiClient } from 'src/api';
import AlertTypes from 'src/App/interfaces/AlertTypes';
import AppActionCreators from 'src/App/redux/ActionCreators';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import delay from 'src/helpers/delay';
import userIcon from 'src/icons/User';
import { IReduxState } from 'src/redux';
import { AuthRequestErrorHelper } from '../api/AuthRequestError';
import IAuthData from '../interfaces/IAuthData';
import IUser from '../interfaces/IUser';
import types from "./ActionTypes";

class AuhtenticationActionCreators {

    constructor() {
        this.login = this.login.bind(this);
    }

    public setAuthData(authData: IAuthData | undefined): AnyAction {
        if (authData) {
            localStorage.setItem("auth", JSON.stringify(authData));
        }
        else {
            localStorage.removeItem("auth");
        }

        const result: AnyAction = { type: types.SET_AUTH_DATA, data: authData || undefined }
        return result;
    }
    public setLoggedInUser(user: IUser | undefined): AnyAction {
        const result: AnyAction = { type: types.SET_LOGGED_IN_USER, user: user || undefined }
        return result;
    }
    public initializeCurrentUser(): (dispatch: Dispatch) => Promise<any> {
        return async (dispatch: Dispatch) => {
            await delay(100);
            const serializedAuthData = localStorage.getItem("auth");
            if (serializedAuthData !== null) {
                const authData = JSON.parse(serializedAuthData) as IAuthData;
                dispatch(this.setAuthData(authData));

                await (dispatch as ReduxThunkDispatch<IReduxState>)(this.retrieveCurrentUser());

            } else {
                dispatch(this.setAuthData(undefined))
                dispatch(this.setLoggedInUser(undefined))
            }

        }
    }

    public login(email: string, password: string): (dispatch: Dispatch) => Promise<any> {
        return async (dispatch: Dispatch) => {
            await delay(100);
            const userClient = getAuthApiClient();
            try {
                const authData = await userClient.auth.login(email, password);
                dispatch(this.setAuthData(authData));

                await (dispatch as ReduxThunkDispatch<IReduxState>)(this.retrieveCurrentUser());

            } catch (err) {
                const hlpr = new AuthRequestErrorHelper();
                if (hlpr.isIAuthRequestError(err)) {
                    const aerr = hlpr.toAuthRequestError(err);
                    if (hlpr.isBadCredentialsError(aerr)) {
                        dispatch(new AppActionCreators().addAppAlert({
                            icon: userIcon,
                            type: AlertTypes.ERROR,
                            title: "Login fehlgeschlagen",
                            message: "Bitte prüfen Sie Ihren Benutzernamen und Ihr Passwort.",

                        }));
                        // alert("Bitte prüfen Sie Ihren Benutzernamen und Ihr Passwort.")
                    } else {
                        dispatch(new AppActionCreators().addAppAlert({
                            icon: userIcon,
                            type: AlertTypes.ERROR,
                            title: "Login fehlgeschlagen",
                            message: "Ein Fehler ist beim Anmelden aufgetreten: " + aerr.message + "(" + aerr.body.error + ")",

                        }));
                    }
                } else {
                    dispatch(new AppActionCreators().addAppAlert({
                        icon: userIcon,
                        type: AlertTypes.ERROR,
                        title: "Login fehlgeschlagen",
                        message: "Ein Fehler ist beim Anmelden aufgetreten. Bitte versuchen Sie es später erneut.",

                    }));
                }

                // tslint:disable-next-line:no-console
                console.error(err);
            }
        }
    }

    public retrieveCurrentUser(): (dispatch: Dispatch, getState: () => IReduxState) => Promise<any> {
        return async (dispatch, getState) => {
            const { authentication } = getState();
            const { authData } = authentication;
            const client = getApiClient();
            try {
                const customer = authData ? await client.customerClient.getCurrent() : undefined;
                dispatch(this.setLoggedInUser(customer))
            } catch (err) {

                if (err && err.status === 401) {
                    await (dispatch as ReduxThunkDispatch<IReduxState>)(this.logout());
                    dispatch(new AppActionCreators().addAppAlert({
                        icon: userIcon,
                        type: AlertTypes.ERROR,
                        title: "Abrufen der Benutzerdaten fehlgeschlagen",
                        message: "Beim Abrufen Ihrer Benutzerdaten ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
                    }));
                } else {
                    dispatch(new AppActionCreators().addAppAlert({
                        icon: userIcon,
                        type: AlertTypes.ERROR,
                        title: "Abrufen der Benutzerdaten fehlgeschlagen",
                        message: "Beim Abrufen Ihrer Benutzerdaten ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
                    }));
                    await (dispatch as ReduxThunkDispatch<IReduxState>)(this.logout());
                }

            }
        }
    }

    public logout(): (dispatch: Dispatch) => Promise<any> {
        return async (dispatch: Dispatch) => {
            await delay(100);
            dispatch(this.setAuthData(undefined));
            dispatch(this.setLoggedInUser(undefined));
        }
    }

}


export default AuhtenticationActionCreators;