import * as React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { getAuthApiClient } from 'src/api';
import Button from 'src/AtomComponents/Button';
import Header1 from 'src/AtomComponents/Header1';
import IAuthData from 'src/Authentication/interfaces/IAuthData';
import AuhtenticationActionCreators from 'src/Authentication/redux/ActionCreators';
import { environment, webAppVersion, webAppVersionDate } from 'src/generatedConfig';
import ContentBox from 'src/MolekuleComponents/ContentBox';
import { IReduxState } from 'src/redux';
import "./styles/VersionPage.css"

// tslint:disable-next-line:no-empty-interface
interface IOwnProps { }

interface IDispatchToProps {
    setAuthData: (data: IAuthData | undefined) => any;
}

interface IStateToProps {
    authData: IAuthData | undefined;
}

type VersionPageProps = IDispatchToProps & IStateToProps;

interface IVersionPageState {
    lastAuthData: IAuthData | undefined;
}


class VersionPage extends React.Component<VersionPageProps, IVersionPageState>{
    private componentIsMounted: boolean = false;

    constructor(props: VersionPageProps) {
        super(props);
        this.state = ({ lastAuthData: props.authData });
    }

    public componentDidMount() {
        this.componentIsMounted = true;
        this.updateTime();
    }

    public componentWillUnmount() {
        this.componentIsMounted = false;
    }

    public updateTime() {
        if (this.componentIsMounted) {
            this.setState({ lastAuthData: (this.props.authData && { ...this.props.authData }) || undefined });
            setTimeout(() => this.updateTime(), 1000);
        }
    }


    public render() {
        const { lastAuthData: authData } = this.state;

        const onRefreshTokenClick = async () => {

            const { authData: data, setAuthData } = this.props;
            if (data) {
                try {
                    setAuthData(await getAuthApiClient().auth.refreshToken(data));
                } catch (err) {
                    // tslint:disable-next-line:no-console
                    console.error("Failed to refresh token");
                }
            } else {
                // tslint:disable-next-line:no-console
                console.error("Failed to refresh token (no auth data)");
            }
        };

        return (
            <ContentBox
                isSingleCollumnBox={true}
                title={"Versionsinformationen"}
            >
                <div>
                    <div className="versionInfoContainerContent">
                        <Header1>Informationen zu dieser Webseite</Header1>
                        <div className="confirmedOrderContainerText">
                            <div>
                                <p>
                                    Version: {webAppVersion}
                                </p>
                            </div>
                            <div>
                                <p>
                                    Erstellt: {webAppVersionDate.toLocaleDateString()},  {webAppVersionDate.toLocaleTimeString()}
                                </p>
                            </div>
                            {
                                environment === "development" ?
                                    <div>
                                        <TokenInfo encodedToken={authData && authData.access_token} isRefreshToken={false} />
                                        <TokenInfo encodedToken={authData && authData.refresh_token} isRefreshToken={true} />
                                        <div> <Button
                                            orange={true}
                                            onClick={onRefreshTokenClick}
                                            style={{ width: "320px" }}
                                        >
                                            Neu laden
                                        </Button>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <p><Link to="/" >Zurück zum Dashboard</Link></p>
                        </div>
                    </div>
                </div>
            </ContentBox>
        );
    }

}

const TokenInfo = (props: { encodedToken: string | undefined, isRefreshToken: boolean }) => {

    const getParsedToken = (encodedToken: string | undefined): { exp: number } | undefined => {
        const tokenParts = (encodedToken && encodedToken.split(".")) || [];
        const tokenString = (tokenParts.length > 1 && atob(tokenParts[1])) || undefined;
        const token = (tokenString && JSON.parse(tokenString)) || undefined;
        return token;

    }

    const parsedToken = getParsedToken(props.encodedToken);
    const expDateString = (parsedToken && parsedToken.exp && new Date(parsedToken.exp * 1000).toString()) || "unbekannt";

    const tokenExpInSeconds = !parsedToken ? undefined : Math.floor(parsedToken.exp - Date.now() / 1000);
    return (
        <div>
            <p>
                Ablauf des {props.isRefreshToken ? "Refresh" : "Access"} Tokens: {expDateString}
            </p>
            {tokenExpInSeconds !== undefined ?
                <p>
                    {tokenExpInSeconds > 0 ? "In" : "Seit"}: {Math.abs(tokenExpInSeconds) + ""} Sekunden
                </p> :
                ""
            }
        </div >
    );
}


const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { authentication } = state;
    const result: IStateToProps = {
        ...ownProps,
        authData: authentication.authData,

    }
    return result;
}



const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
    const actionCreator = new AuhtenticationActionCreators();
    const result: IDispatchToProps = {
        ...ownProps,
        setAuthData: (data: IAuthData | undefined) => dispatch(actionCreator.setAuthData(data)),
    }
    return result;
}


// this.store.dispatch(new AuhtenticationActionCreators().setAuthData(nextData));
export default connect(mapStateToProps, mapDispatchToProps)(VersionPage);