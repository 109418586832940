import { AnyAction } from 'redux';

import types from "./ActionTypes";
import IOrderState, { defaultState } from "./State";

export const OrderReducer = (state: IOrderState = defaultState, action: AnyAction): IOrderState => {
    switch (action.type) {
        case types.ORDER_SET_LOADING_TURBO:
            return { ...state, loadingTurbo: (action as any).loading || false };
        case types.SET_PLACING_ORDER:
            return { ...state, placingOrder: (action as any).placing || false };

        case types.ORDER_SET_TURBO:
            return { ...state, turbo: (action as any).turbo || undefined };

        // case types.SET_OFFERS_BANDWITH_OPTIONS:
        //     return { ...state, offersBandwidthOptions: (action as any).options || [] };
        // case types.SET_OFFERS_DURATIONS:
        //     return { ...state, offersDurations: (action as any).options || [] };

        case types.SET_SELECTABLE_OFFERS_BANDWITH_OPTIONS:
            return { ...state, selectableOffersBandwidthOptions: (action as any).options || [] };
        case types.SET_SELECTABLE_OFFERS_DURATIONS:
            return { ...state, selectableOffersDurations: (action as any).options || [] };

        case types.SET_OFFERS_LOADING_TURBO_OPTIONS:
            return { ...state, loadingOffersTurboOptions: (action as any).loading };
        case types.SET_OFFERS_SELECTED_BANDWITH_OPTION_ID:
            return { ...state, selectedBandwidthOption: (action as any).id || undefined };
        case types.SET_OFFERS_SELECTED_DURATION_ID:
            return { ...state, selectedDuration: (action as any).id || undefined };

        default:
            return state;
    }
}

export default OrderReducer;