import { BandwidthOption } from '../../api/Entities';
import IDurationViewModel from '../../Contract/interfaces/IDurationViewModel';
import TurboForPresentation from '../../Turbo/interfaces/TurboForPresentation';

export interface IOrderState {
    selectedBandwidthOption: BandwidthOption | undefined;
    selectedDuration: IDurationViewModel | undefined;

    loadingOffersTurboOptions: boolean;
    // offersBandwidthOptions: BandwidthOption[];
    // offersDurations: IDurationViewModel[];
    selectableOffersBandwidthOptions: BandwidthOption[];
    selectableOffersDurations: IDurationViewModel[];

    loadingTurbo: boolean;
    turbo: TurboForPresentation | undefined,

    placingOrder: boolean;
}

export const defaultState: IOrderState = {
    loadingOffersTurboOptions: false,
    loadingTurbo: false,
    turbo: undefined,
    selectedBandwidthOption: undefined,
    selectedDuration: undefined,
    // offersBandwidthOptions: [],
    // offersDurations: [],
    selectableOffersBandwidthOptions: [],
    selectableOffersDurations: [],

    placingOrder: false,
}

export default IOrderState;