import * as React from "react";
import './styles/CurrentBandwithPage.css';

import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ContractForPresentation, Customer, TurboOrder } from 'src/api/Entities';
import { IReduxState } from 'src/redux';
import { PagedItems } from 'src/api/ApiTypes';
import ContentBox from 'src/MolekuleComponents/ContentBox';
import Header1 from 'src/AtomComponents/Header1';
import ContentBoxSplitter from 'src/AtomComponents/ContentBoxSplitter';
import ContractActionCreators from './redux/ActionCreators';
import { Dispatch } from 'redux';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import userIcon from 'src/icons/User';
import tarifDetailsIcon from 'src/icons/TarifDetails';
import LinkButton from 'src/AtomComponents/LinkButton';
import DetailsPendingTurboInfoBox from './components/DetailsPendingTurboInfoBox';
import DetailsActiveTurboInfoBox from './components/DetailsActiveTurboInfoBox';
import DetailsActiveNotRunningTurbosInfoBox from './components/DetailsActiveNotRunningTurbosInfoBox';
import DetailsCurrentBandwidthBox from './components/DetailsCurrentBandwidthBox';

import "./styles/DetailsPage.css"

interface IOwnProps extends RouteComponentProps<any> {

}


interface IDispatchToProps {
    loadContract: () => Promise<any>;
}

interface IStateToProps {

    loadingContract: boolean;

    activeTurbo: TurboOrder | undefined;
    pendingTurbos: PagedItems<TurboOrder> | undefined;
    contract: ContractForPresentation | undefined;

    customer: Customer | undefined;
}

type IOrderPageContentProperties = IStateToProps & IDispatchToProps & IOwnProps;

class CurrentBandwithPage extends React.Component<IOrderPageContentProperties, { termsRead: boolean; privacyAccepted: boolean }> {
    public state = { termsRead: false, privacyAccepted: false };

    // constructor(props: IOrderPageContentProperties) {
    //     super(props);
    // }

    public componentWillMount() {
        const { loadContract } = this.props;
        loadContract();
    }

    public render() {
        const { customer, contract, activeTurbo, pendingTurbos } = this.props;
        return (
            <div className={"orderPage"} >
                <div className="defaultPageContent orderPageContent">
                    <DetailsBox
                        customer={customer}
                        contract={contract}
                        activeTurbo={activeTurbo}
                        pendingTurbos={pendingTurbos}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { contract, authentication, turbo } = state;

    const result: IStateToProps = {
        ...ownProps,
        activeTurbo: turbo.activeTurbo,
        pendingTurbos: turbo.pendingTurbos,

        customer: authentication.customer,

        loadingContract: contract.loadingContract || false,
        contract: contract.contract,
    }
    return result;
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
    const actionCreators = new ContractActionCreators();
    const result: IDispatchToProps = {
        ...ownProps,
        loadContract: () => (dispatch as ReduxThunkDispatch<IReduxState>)(actionCreators.retrieveContract()),
    }
    return result;
}

const DetailsBox = (props: {
    contract: ContractForPresentation | undefined;
    customer: Customer | undefined;
    activeTurbo: TurboOrder | undefined;
    pendingTurbos: PagedItems<TurboOrder> | undefined;
}) => {
    const { customer, contract, pendingTurbos, activeTurbo } = props;

    const pendingTurbo = (pendingTurbos && pendingTurbos.items.length > 0) ? pendingTurbos.items[0] : undefined;

    const hasActiveTurbo = !!activeTurbo;
    const hasPendingTurbo = !!pendingTurbo;

    const hasAvailableOptions = contract && contract.product && contract.product.possibleTurbos.filter(pt => pt.enabled).length > 0;

    return (
        <ContentBox
            isSingleCollumnBox={true}
            title={"Ihre Details"}
            titleIcon={tarifDetailsIcon}
        >
            <div className="selectedOrderContainer contractDetailsContentBox">
                <Header1>
                    Ihre Daten
                </Header1>
                <div className="turboDetailsCustomerInfoContainer">
                    {customer ?
                        <div className="contractData">
                            <div className="innerData">
                                <div className="customerInfo detailsInfoCell">
                                    <div className="customerIcon"
                                        style={{ backgroundImage: `url(${userIcon})` }}
                                    />
                                    <div className="customerData">
                                        <div className="customerName">{customer.fullName}</div>
                                        <div className="customerNumber">Knd-Nr: {customer.customerNumber}</div>
                                    </div>
                                </div>
                                <div className="customerAdress detailsInfoCell value">
                                    <div>
                                        {customer.street}
                                    </div>
                                    <div>
                                        {customer.city}
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ""
                    }
                </div>
                <ContentBoxSplitter />
                <Header1>
                    <span className={hasActiveTurbo ? "orange" : hasPendingTurbo ? "blue" : ""}>
                        {
                            hasActiveTurbo ? "Turbo aktiv" : "Aktuelle Geschwindigkeit"
                        }
                    </span>
                </Header1>
                <DetailsCurrentBandwidthBox />
                <DetailsActiveTurboInfoBox />
                <DetailsActiveNotRunningTurbosInfoBox />
                {/* <ContentBoxSplitter /> */}
                <DetailsPendingTurboInfoBox />
                {!hasActiveTurbo && hasAvailableOptions ?
                    <div className="detailsPageButtonContainer">
                        <LinkButton
                            to="/product/turbo"
                            orange={false}
                            className="detailsButton"
                        >
                            Turbo bestellen
                        </LinkButton>
                    </div> :
                    ""}{
                    !hasAvailableOptions && hasActiveTurbo ?
                        <div className="detailsPageButtonContainer">
                            <LinkButton className={"detailsButton"} to="/" >Zum Dashboard</LinkButton>
                        </div>
                        // <LinkWithArrow to="/product/currentBandwith" className="strong blue">Mehr Details und Optionen</LinkWithArrow>
                        :
                        ""
                }
            </div>
        </ContentBox >
    );
}



export default connect(mapStateToProps, mapDispatchToProps)(CurrentBandwithPage);
