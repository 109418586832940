/* eslint-disable eqeqeq */
/* eslint-disable no-throw-literal */
/* eslint-disable no-mixed-operators */
// tslint:disable
import { BaseClient, RequestAdapter } from './BaseClient';
import { ApiHateoasObjectBase, ApiHateoasObjectReadMultiple, PagedItems, ApiNavigationLinks, ApiBase } from './ApiTypes';
import apiHelper from './ApiHelper';
import StringHelper from '../helpers/StringHelper';
import { BandwidthOptionBase, BandwidthOptionDto, BandwidthOption, BandwidthOptionEAGER, ContractBase, ContractDto, Contract, ContractEAGER, CustomerBase, CustomerDto, Customer, CustomerEAGER, NotificationsSettingsBase, NotificationsSettingsDto, NotificationsSettings, NotificationsSettingsEAGER, ProductBase, ProductDto, Product, ProductEAGER, TurboBase, TurboDto, Turbo, TurboEAGER, TurboOrderBase, TurboOrderDto, TurboOrder, TurboOrderEAGER } from './Entities'
import { NotificationLevel, OrderStatus } from './Entities'
import { NotificationsSettingsEAGERBaseProjection, BandwidthOptionEAGERBaseProjection, ProductBaseProjection, TurboBaseProjection, TurboOrderBaseProjection, CustomerBaseProjection, ContractBaseProjection, ContractForPresentation, ProductForPresentation, TurboForPresentation, TurboOrderForPresentation } from './Entities'

const stringHelper = new StringHelper();

export class ApiClient {
    public readonly bandwidthOptionClient: BandwidthOptionClient
    public readonly contractClient: ContractClient
    public readonly customerClient: CustomerClient
    public readonly notificationsSettingsClient: NotificationsSettingsClient
    public readonly productClient: ProductClient
    public readonly turboClient: TurboClient
    public readonly turboOrderClient: TurboOrderClient

    private baseUrl = "";

    constructor(requestAdapter?: RequestAdapter, baseUrl?: string) {
        this.baseUrl = baseUrl || this.baseUrl
        const adapter = requestAdapter || new RequestAdapter(this.baseUrl)
        this.bandwidthOptionClient = new BandwidthOptionClient(this, adapter)
        this.contractClient = new ContractClient(this, adapter)
        this.customerClient = new CustomerClient(this, adapter)
        this.notificationsSettingsClient = new NotificationsSettingsClient(this, adapter)
        this.productClient = new ProductClient(this, adapter)
        this.turboClient = new TurboClient(this, adapter)
        this.turboOrderClient = new TurboOrderClient(this, adapter)
    }
}

export class BandwidthOptionClient extends BaseClient<ApiClient> {

    constructor(apiClient: ApiClient, requestAdapter?: RequestAdapter){
        super(apiClient, requestAdapter);
        this.readOne = this.readOne.bind(this);
        this.readProjection = this.readProjection.bind(this);
        
    }

    public async create(obj: BandwidthOptionBase): Promise<BandwidthOption> {
        return await this._requestAdapter.createObject(obj, "/rest/bandwidthOption") as BandwidthOption
    }

    public build(): BandwidthOptionBase {
        return {
            id: 0,
            download: 0,
            upload: 0
        }
    }

    public async readAll(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "download,ASC" | "download,DESC" | "upload,ASC" | "upload,DESC") : Promise<PagedItems<BandwidthOption>> {
        return await this.readProjections<BandwidthOption>(undefined, page, size, sort);
    }

    public async readProjectionsBandwidthOptionEAGERBaseProjection(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "download,ASC" | "download,DESC" | "upload,ASC" | "upload,DESC") : Promise<PagedItems<BandwidthOptionEAGERBaseProjection>> {
        return this.readProjections<BandwidthOptionEAGERBaseProjection>("baseProjection", page, size, sort);
    }

    public async readProjections<T extends BandwidthOptionBase & { _links: ApiNavigationLinks }>(projectionName?: string, page?: number, size?: number, sort?: string) : Promise<PagedItems<T>> {
        return await this._requestAdapter.getPage<T>("/rest/bandwidthOption", "bandwidthOption", projectionName, page, size, sort);
    }

    public async readOne(id: number): Promise<BandwidthOption | undefined> {
        return this.readProjection<BandwidthOption>(id);
    }

    public async readProjectionBandwidthOptionEAGERBaseProjection(id: number): Promise<BandwidthOptionEAGERBaseProjection | undefined> {
        return this.readProjection<BandwidthOptionEAGERBaseProjection>(id, "baseProjection")
    }

    public async readProjection<T extends BandwidthOption>(id: number, projection?: string): Promise<T | undefined> {
        const hasProjection = (!!projection);
        let fullUrl = `/rest/bandwidthOption/${id}`;
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;

        const response =  await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) return undefined
        if(!response.ok){ throw response; }

        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }

    public async readOneEAGER(id: number): Promise<BandwidthOptionEAGER | undefined> {
        const obj = await this.readOne(id)
        if(obj == undefined) return undefined

        // TODO use Promise#all
        

        return {
            
            ...obj,
            id
        }
    }

    public async update<T extends BandwidthOption>(obj: T): Promise<BandwidthOption> {
        const bandwidthOption: BandwidthOption = this.toBandwidthOption(obj);
        return await this._requestAdapter.updateObject(bandwidthOption)
    }

    public async delete(obj: BandwidthOptionDto) {
        return await this._requestAdapter.deleteObject(obj)
    }

    public toBandwidthOption<T extends BandwidthOption>(obj: T): BandwidthOption {
        const result: BandwidthOption =  {
            ...this.toBandwidthOptionBase(obj),
            id: obj.id,
            _links: obj._links,
        }
        return result;
    }

    public toBandwidthOptionDto(obj: BandwidthOption): BandwidthOptionDto {
        return obj
    }

    public toBandwidthOptionBase<T extends BandwidthOptionBase>(obj: T): BandwidthOptionBase {
        const result: BandwidthOptionBase =  {
            id: obj.id,
            download: obj.download,
            upload: obj.upload,
        };
        return result;
    }

    

    

    
}

export class ContractClient extends BaseClient<ApiClient> {

    constructor(apiClient: ApiClient, requestAdapter?: RequestAdapter){
        super(apiClient, requestAdapter);
        this.readOne = this.readOne.bind(this);
        this.readProjection = this.readProjection.bind(this);
        this.readCustomerProjection = this.readCustomerProjection.bind(this);
        this.readProductProjection = this.readProductProjection.bind(this);
        this.readTurboOrdersProjection = this.readTurboOrdersProjection.bind(this);
    }

    public async create(obj: ContractBase): Promise<Contract> {
        return await this._requestAdapter.createObject(obj, "/rest/contract") as Contract
    }

    public build(): ContractBase {
        return {
            id: 0,
            expires: "1970-01-01T00:00:00"
        }
    }

    public async readAll(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "expires,ASC" | "expires,DESC") : Promise<PagedItems<Contract>> {
        return await this.readProjections<Contract>(undefined, page, size, sort);
    }

    public async readProjectionsContractBaseProjection(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "expires,ASC" | "expires,DESC") : Promise<PagedItems<ContractBaseProjection>> {
        return this.readProjections<ContractBaseProjection>("baseProjection", page, size, sort);
    }
    
    public async readProjectionsContractForPresentation(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "expires,ASC" | "expires,DESC") : Promise<PagedItems<ContractForPresentation>> {
        return this.readProjections<ContractForPresentation>("contractForPresentation", page, size, sort);
    }

    public async readProjections<T extends ContractBase & { _links: ApiNavigationLinks }>(projectionName?: string, page?: number, size?: number, sort?: string) : Promise<PagedItems<T>> {
        return await this._requestAdapter.getPage<T>("/rest/contract", "contract", projectionName, page, size, sort);
    }

    public async readOne(id: number): Promise<Contract | undefined> {
        return this.readProjection<Contract>(id);
    }

    public async readProjectionContractBaseProjection(id: number): Promise<ContractBaseProjection | undefined> {
        return this.readProjection<ContractBaseProjection>(id, "baseProjection")
    }
    
    public async readProjectionContractForPresentation(id: number): Promise<ContractForPresentation | undefined> {
        return this.readProjection<ContractForPresentation>(id, "contractForPresentation")
    }

    public async readProjection<T extends Contract>(id: number, projection?: string): Promise<T | undefined> {
        const hasProjection = (!!projection);
        let fullUrl = `/rest/contract/${id}`;
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;

        const response =  await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) return undefined
        if(!response.ok){ throw response; }

        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }

    public async readOneEAGER(id: number): Promise<ContractEAGER | undefined> {
        const obj = await this.readOne(id)
        if(obj == undefined) return undefined

        // TODO use Promise#all
        const customer = await this.readCustomer(obj)
        if(!customer) return undefined
        const product = await this.readProduct(obj)
        
        const turboOrders = await this.readTurboOrders(obj)

        return {
            customer,
            product,
            turboOrders,
            ...obj,
            id
        }
    }

    public async update<T extends Contract>(obj: T): Promise<Contract> {
        const contract: Contract = this.toContract(obj);
        return await this._requestAdapter.updateObject(contract)
    }

    public async delete(obj: ContractDto) {
        return await this._requestAdapter.deleteObject(obj)
    }

    public toContract<T extends Contract>(obj: T): Contract {
        const result: Contract =  {
            ...this.toContractBase(obj),
            id: obj.id,
            _links: obj._links,
        }
        return result;
    }

    public toContractDto(obj: Contract): ContractDto {
        return obj
    }

    public toContractBase<T extends ContractBase>(obj: T): ContractBase {
        const result: ContractBase =  {
            id: obj.id,
            expires: obj.expires,
        };
        return result;
    }

    public async readCustomer(obj: ContractDto): Promise<Customer | undefined> {
        return this.readCustomerProjection<Customer>(obj);
    }
    
    public async readCustomerProjectionCustomerBaseProjection(obj: ContractDto): Promise<CustomerBaseProjection | undefined> {
        return this.readCustomerProjection<CustomerBaseProjection>(obj, "baseProjection")
    }
    
    public async readCustomerProjection<T extends ApiBase & { _links: ApiNavigationLinks }>(obj: ContractDto, projection?: string): Promise<T | undefined> {
        const hasProjection = (!!projection);
        let fullUrl = apiHelper.removeParamsFromNavigationHref(obj._links.customer);
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;
    
        const response = await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) { return undefined; }
        if(!response.ok){ throw response; }
        const result = (await response.json()) as T
        return apiHelper.injectIds(result);
    }
    
    public async setCustomer(returnType: Contract, child: Customer) {
        if(!returnType._links) throw `Parent has no _links: ${returnType.id}`
        if(!child._links) throw `Child has no _links: ${child.id}`
        await this._requestAdapter.adaptAnyToOne(
            apiHelper.removeParamsFromNavigationHref(returnType._links.customer),
            child._links.self.href
        )
    }
    
    public async deleteFromCustomer(returnType: Contract, childToDelete: Customer) {
        await this._requestAdapter.getRequest().delete(`/rest/contract/${returnType.id}/customer/${childToDelete.id}`)
    }
    
    public async readProduct(obj: ContractDto): Promise<Product | undefined> {
        return this.readProductProjection<Product>(obj);
    }
    
    public async readProductProjectionProductBaseProjection(obj: ContractDto): Promise<ProductBaseProjection | undefined> {
        return this.readProductProjection<ProductBaseProjection>(obj, "baseProjection")
    }
    
    public async readProductProjectionProductForPresentation(obj: ContractDto): Promise<ProductForPresentation | undefined> {
        return this.readProductProjection<ProductForPresentation>(obj, "productForPresentation")
    }
    
    public async readProductProjection<T extends ApiBase & { _links: ApiNavigationLinks }>(obj: ContractDto, projection?: string): Promise<T | undefined> {
        const hasProjection = (!!projection);
        let fullUrl = apiHelper.removeParamsFromNavigationHref(obj._links.product);
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;
    
        const response = await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) { return undefined; }
        if(!response.ok){ throw response; }
        const result = (await response.json()) as T
        return apiHelper.injectIds(result);
    }
    
    public async setProduct(returnType: Contract, child: Product) {
        if(!returnType._links) throw `Parent has no _links: ${returnType.id}`
        if(!child._links) throw `Child has no _links: ${child.id}`
        await this._requestAdapter.adaptAnyToOne(
            apiHelper.removeParamsFromNavigationHref(returnType._links.product),
            child._links.self.href
        )
    }
    
    public async deleteFromProduct(returnType: Contract, childToDelete: Product) {
        await this._requestAdapter.getRequest().delete(`/rest/contract/${returnType.id}/product/${childToDelete.id}`)
    }
    
    public async readTurboOrders(obj: ContractDto): Promise<TurboOrder[]> {
        return this.readTurboOrdersProjection<TurboOrder>(obj);
    }
    
    public async readTurboOrdersProjectionTurboOrderBaseProjection(obj: ContractDto): Promise<TurboOrderBaseProjection[]> {
        return this.readTurboOrdersProjection<TurboOrderBaseProjection>(obj, "baseProjection")
    }
    
    public async readTurboOrdersProjectionTurboOrderForPresentation(obj: ContractDto): Promise<TurboOrderForPresentation[]> {
        return this.readTurboOrdersProjection<TurboOrderForPresentation>(obj, "turboOrderForPresentation")
    }
    
    public async readTurboOrdersProjection<T extends ApiBase & { _links: ApiNavigationLinks }>(obj: ContractDto, projection?: string): Promise<T[]> {
        const hasProjection = (!!projection);
        let fullUrl = apiHelper.removeParamsFromNavigationHref(obj._links.turboOrders);
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;
    
        const response = await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) { return []; }
        if(!response.ok){ throw response; }
        return (((await response.json()) as ApiHateoasObjectBase<T[]>)._embedded.turboOrder).map(item => (apiHelper.injectIds(item)));
    }
    
    public async setTurboOrders(returnType: Contract, children: TurboOrder[]) {
        if(!returnType._links) throw `Parent has no _links: ${returnType.id}`
        await this._requestAdapter.adaptAnyToMany(
            apiHelper.removeParamsFromNavigationHref(returnType._links.turboOrders),
            children.map(c => {
                if(!c._links) throw `Child has no _links: ${c.id}`
                return c._links.self.href
            })
        )
    }
    
    public async addToTurboOrders(returnType: Contract, childToAdd: TurboOrder) {
        await this._requestAdapter.addToObj(childToAdd, returnType, "turboOrders")
    }
    
    public async deleteFromTurboOrders(returnType: Contract, childToDelete: TurboOrder) {
        await this._requestAdapter.getRequest().delete(`/rest/contract/${returnType.id}/turboOrders/${childToDelete.id}`)
    }

    

    
}

export class CustomerClient extends BaseClient<ApiClient> {

    constructor(apiClient: ApiClient, requestAdapter?: RequestAdapter){
        super(apiClient, requestAdapter);
        this.readOne = this.readOne.bind(this);
        this.readProjection = this.readProjection.bind(this);
        this.readContractProjection = this.readContractProjection.bind(this);
    }

    public async create(obj: CustomerBase): Promise<Customer> {
        return await this._requestAdapter.createObject(obj, "/rest/customer") as Customer
    }

    public build(): CustomerBase {
        return {
            id: 0,
            city: "",
            customerNumber: "",
            email: "",
            fullName: "",
            street: "",
            userName: ""
        }
    }

    public async readAll(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "city,ASC" | "city,DESC" | "customerNumber,ASC" | "customerNumber,DESC" | "email,ASC" | "email,DESC" | "fullName,ASC" | "fullName,DESC" | "street,ASC" | "street,DESC" | "userName,ASC" | "userName,DESC") : Promise<PagedItems<Customer>> {
        return await this.readProjections<Customer>(undefined, page, size, sort);
    }

    public async readProjectionsCustomerBaseProjection(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "city,ASC" | "city,DESC" | "customerNumber,ASC" | "customerNumber,DESC" | "email,ASC" | "email,DESC" | "fullName,ASC" | "fullName,DESC" | "street,ASC" | "street,DESC" | "userName,ASC" | "userName,DESC") : Promise<PagedItems<CustomerBaseProjection>> {
        return this.readProjections<CustomerBaseProjection>("baseProjection", page, size, sort);
    }

    public async readProjections<T extends CustomerBase & { _links: ApiNavigationLinks }>(projectionName?: string, page?: number, size?: number, sort?: string) : Promise<PagedItems<T>> {
        return await this._requestAdapter.getPage<T>("/rest/customer", "customer", projectionName, page, size, sort);
    }

    public async readOne(id: number): Promise<Customer | undefined> {
        return this.readProjection<Customer>(id);
    }

    public async readProjectionCustomerBaseProjection(id: number): Promise<CustomerBaseProjection | undefined> {
        return this.readProjection<CustomerBaseProjection>(id, "baseProjection")
    }

    public async readProjection<T extends Customer>(id: number, projection?: string): Promise<T | undefined> {
        const hasProjection = (!!projection);
        let fullUrl = `/rest/customer/${id}`;
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;

        const response =  await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) return undefined
        if(!response.ok){ throw response; }

        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }

    public async readOneEAGER(id: number): Promise<CustomerEAGER | undefined> {
        const obj = await this.readOne(id)
        if(obj == undefined) return undefined

        // TODO use Promise#all
        const contract = await this.readContract(obj)

        return {
            contract,
            ...obj,
            id
        }
    }

    public async update<T extends Customer>(obj: T): Promise<Customer> {
        const customer: Customer = this.toCustomer(obj);
        return await this._requestAdapter.updateObject(customer)
    }

    public async delete(obj: CustomerDto) {
        return await this._requestAdapter.deleteObject(obj)
    }

    public toCustomer<T extends Customer>(obj: T): Customer {
        const result: Customer =  {
            ...this.toCustomerBase(obj),
            id: obj.id,
            _links: obj._links,
        }
        return result;
    }

    public toCustomerDto(obj: Customer): CustomerDto {
        return obj
    }

    public toCustomerBase<T extends CustomerBase>(obj: T): CustomerBase {
        const result: CustomerBase =  {
            id: obj.id,
            city: obj.city,
            customerNumber: obj.customerNumber,
            email: obj.email,
            fullName: obj.fullName,
            street: obj.street,
            userName: obj.userName,
        };
        return result;
    }

    public async readContract(obj: CustomerDto): Promise<Contract | undefined> {
        return this.readContractProjection<Contract>(obj);
    }
    
    public async readContractProjectionContractBaseProjection(obj: CustomerDto): Promise<ContractBaseProjection | undefined> {
        return this.readContractProjection<ContractBaseProjection>(obj, "baseProjection")
    }
    
    public async readContractProjectionContractForPresentation(obj: CustomerDto): Promise<ContractForPresentation | undefined> {
        return this.readContractProjection<ContractForPresentation>(obj, "contractForPresentation")
    }
    
    public async readContractProjection<T extends ApiBase & { _links: ApiNavigationLinks }>(obj: CustomerDto, projection?: string): Promise<T | undefined> {
        const hasProjection = (!!projection);
        let fullUrl = apiHelper.removeParamsFromNavigationHref(obj._links.contract);
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;
    
        const response = await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) { return undefined; }
        if(!response.ok){ throw response; }
        const result = (await response.json()) as T
        return apiHelper.injectIds(result);
    }
    
    public async setContract(returnType: Customer, child: Contract) {
        if(!returnType._links) throw `Parent has no _links: ${returnType.id}`
        if(!child._links) throw `Child has no _links: ${child.id}`
        await this._requestAdapter.adaptAnyToOne(
            apiHelper.removeParamsFromNavigationHref(returnType._links.contract),
            child._links.self.href
        )
    }
    
    public async deleteFromContract(returnType: Customer, childToDelete: Contract) {
        await this._requestAdapter.getRequest().delete(`/rest/customer/${returnType.id}/contract/${childToDelete.id}`)
    }

    

    public async getCurrent<T extends Customer>(projection?: string): Promise<T>  {
        const request = this._requestAdapter.getRequest()
    
        const url = stringHelper.appendParams(
            `/rest/customer/current`,
            projection && `projection=${projection}`
        )
    
        
        const response = await request.fetch(
            url,
            {
                method: "GET"
            })
    
        if(!response.ok) {
            throw response;
        }
        
        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }
    
    
    
    public async getUpdateByCustomerNumber(customerNumber: string): Promise<void>  {
        const request = this._requestAdapter.getRequest()
    
        const url = stringHelper.appendParams(
            `/rest/customer/update/${customerNumber}`
        )
    
        
        const response = await request.fetch(
            url,
            {
                method: "GET"
            })
    
        if(!response.ok) {
            throw response;
        }
        
    }
}

export class NotificationsSettingsClient extends BaseClient<ApiClient> {

    constructor(apiClient: ApiClient, requestAdapter?: RequestAdapter){
        super(apiClient, requestAdapter);
        this.readOne = this.readOne.bind(this);
        this.readProjection = this.readProjection.bind(this);
        
    }

    public async create(obj: NotificationsSettingsBase): Promise<NotificationsSettings> {
        return await this._requestAdapter.createObject(obj, "/rest/notificationsSettings") as NotificationsSettings
    }

    public build(): NotificationsSettingsBase {
        return {
            id: 0,
            deviceToken: "",
            sendMail: false,
            notificationLevel: NotificationLevel.ContractOnly
        }
    }

    public async readAll(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "sendMail,ASC" | "sendMail,DESC") : Promise<PagedItems<NotificationsSettings>> {
        return await this.readProjections<NotificationsSettings>(undefined, page, size, sort);
    }

    public async readProjectionsNotificationsSettingsEAGERBaseProjection(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "sendMail,ASC" | "sendMail,DESC") : Promise<PagedItems<NotificationsSettingsEAGERBaseProjection>> {
        return this.readProjections<NotificationsSettingsEAGERBaseProjection>("baseProjection", page, size, sort);
    }

    public async readProjections<T extends NotificationsSettingsBase & { _links: ApiNavigationLinks }>(projectionName?: string, page?: number, size?: number, sort?: string) : Promise<PagedItems<T>> {
        return await this._requestAdapter.getPage<T>("/rest/notificationsSettings", "notificationsSettings", projectionName, page, size, sort);
    }

    public async readOne(id: number): Promise<NotificationsSettings | undefined> {
        return this.readProjection<NotificationsSettings>(id);
    }

    public async readProjectionNotificationsSettingsEAGERBaseProjection(id: number): Promise<NotificationsSettingsEAGERBaseProjection | undefined> {
        return this.readProjection<NotificationsSettingsEAGERBaseProjection>(id, "baseProjection")
    }

    public async readProjection<T extends NotificationsSettings>(id: number, projection?: string): Promise<T | undefined> {
        const hasProjection = (!!projection);
        let fullUrl = `/rest/notificationsSettings/${id}`;
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;

        const response =  await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) return undefined
        if(!response.ok){ throw response; }

        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }

    public async readOneEAGER(id: number): Promise<NotificationsSettingsEAGER | undefined> {
        const obj = await this.readOne(id)
        if(obj == undefined) return undefined

        // TODO use Promise#all
        

        return {
            
            ...obj,
            id
        }
    }

    public async update<T extends NotificationsSettings>(obj: T): Promise<NotificationsSettings> {
        const notificationsSettings: NotificationsSettings = this.toNotificationsSettings(obj);
        return await this._requestAdapter.updateObject(notificationsSettings)
    }

    public async delete(obj: NotificationsSettingsDto) {
        return await this._requestAdapter.deleteObject(obj)
    }

    public toNotificationsSettings<T extends NotificationsSettings>(obj: T): NotificationsSettings {
        const result: NotificationsSettings =  {
            ...this.toNotificationsSettingsBase(obj),
            id: obj.id,
            _links: obj._links,
        }
        return result;
    }

    public toNotificationsSettingsDto(obj: NotificationsSettings): NotificationsSettingsDto {
        return obj
    }

    public toNotificationsSettingsBase<T extends NotificationsSettingsBase>(obj: T): NotificationsSettingsBase {
        const result: NotificationsSettingsBase =  {
            id: obj.id,
            deviceToken: obj.deviceToken,
            sendMail: obj.sendMail,
            notificationLevel: obj.notificationLevel,
        };
        return result;
    }

    

    

    public async postCurrentByPlatformDeviceByDeviceToken(platform: string, deviceToken: string, projection?: string): Promise<void>  {
        const request = this._requestAdapter.getRequest()
    
        const url = stringHelper.appendParams(
            `/rest/notificationsSettings/current/${platform}/device/${deviceToken}`,
            projection && `projection=${projection}`
        )
    
        
        const response = await request.fetch(
            url,
            {
                method: "POST"
            })
    
        if(!response.ok) {
            throw response;
        }
        
    }
    
    
    
    public async deleteCurrentByPlatformDeviceByDeviceToken(platform: string, deviceToken: string, projection?: string): Promise<void>  {
        const request = this._requestAdapter.getRequest()
    
        const url = stringHelper.appendParams(
            `/rest/notificationsSettings/current/${platform}/device/${deviceToken}`,
            projection && `projection=${projection}`
        )
    
        
        const response = await request.fetch(
            url,
            {
                method: "DELETE"
            })
    
        if(!response.ok) {
            throw response;
        }
        
    }
    
    
    
    public async getCurrent<T extends NotificationsSettings>(deviceToken?: string, projection?: string): Promise<T>  {
        const request = this._requestAdapter.getRequest()
    
        const url = stringHelper.appendParams(
            `/rest/notificationsSettings/current`,
            projection && `projection=${projection}`
        )
    
        
        const response = await request.fetch(
            url,
            {
                method: "GET"
            })
    
        if(!response.ok) {
            throw response;
        }
        
        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }
    
    
    
    public async putCurrent<T extends NotificationsSettings>(data: NotificationsSettings, projection?: string): Promise<T>  {
        const request = this._requestAdapter.getRequest()
    
        const url = stringHelper.appendParams(
            `/rest/notificationsSettings/current`,
            projection && `projection=${projection}`
        )
    
        
        const response = await request.fetch(
            url,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json"
                },
                body:JSON.stringify(data),
            })
    
        if(!response.ok) {
            throw response;
        }
        
        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }
    
    
    
    public async putCurrentByPlatformDeviceByNextTokenByOldToken(platform: string, nextToken: string, oldToken: string, projection?: string): Promise<void>  {
        const request = this._requestAdapter.getRequest()
    
        const url = stringHelper.appendParams(
            `/rest/notificationsSettings/current/${platform}/device/${nextToken}/${oldToken}`,
            projection && `projection=${projection}`
        )
    
        
        const response = await request.fetch(
            url,
            {
                method: "PUT"
            })
    
        if(!response.ok) {
            throw response;
        }
        
    }
}

export class ProductClient extends BaseClient<ApiClient> {

    constructor(apiClient: ApiClient, requestAdapter?: RequestAdapter){
        super(apiClient, requestAdapter);
        this.readOne = this.readOne.bind(this);
        this.readProjection = this.readProjection.bind(this);
        this.readBandwidthProjection = this.readBandwidthProjection.bind(this);
        this.readContractProjection = this.readContractProjection.bind(this);
        this.readPossibleTurbosProjection = this.readPossibleTurbosProjection.bind(this);
    }

    public async create(obj: ProductBase): Promise<Product> {
        return await this._requestAdapter.createObject(obj, "/rest/product") as Product
    }

    public build(): ProductBase {
        return {
            id: 0,
            activated: false,
            name: "",
            productNumber: ""
        }
    }

    public async readAll(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "activated,ASC" | "activated,DESC" | "name,ASC" | "name,DESC" | "productNumber,ASC" | "productNumber,DESC") : Promise<PagedItems<Product>> {
        return await this.readProjections<Product>(undefined, page, size, sort);
    }

    public async readProjectionsProductBaseProjection(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "activated,ASC" | "activated,DESC" | "name,ASC" | "name,DESC" | "productNumber,ASC" | "productNumber,DESC") : Promise<PagedItems<ProductBaseProjection>> {
        return this.readProjections<ProductBaseProjection>("baseProjection", page, size, sort);
    }
    
    public async readProjectionsProductForPresentation(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "activated,ASC" | "activated,DESC" | "name,ASC" | "name,DESC" | "productNumber,ASC" | "productNumber,DESC") : Promise<PagedItems<ProductForPresentation>> {
        return this.readProjections<ProductForPresentation>("productForPresentation", page, size, sort);
    }

    public async readProjections<T extends ProductBase & { _links: ApiNavigationLinks }>(projectionName?: string, page?: number, size?: number, sort?: string) : Promise<PagedItems<T>> {
        return await this._requestAdapter.getPage<T>("/rest/product", "product", projectionName, page, size, sort);
    }

    public async readOne(id: number): Promise<Product | undefined> {
        return this.readProjection<Product>(id);
    }

    public async readProjectionProductBaseProjection(id: number): Promise<ProductBaseProjection | undefined> {
        return this.readProjection<ProductBaseProjection>(id, "baseProjection")
    }
    
    public async readProjectionProductForPresentation(id: number): Promise<ProductForPresentation | undefined> {
        return this.readProjection<ProductForPresentation>(id, "productForPresentation")
    }

    public async readProjection<T extends Product>(id: number, projection?: string): Promise<T | undefined> {
        const hasProjection = (!!projection);
        let fullUrl = `/rest/product/${id}`;
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;

        const response =  await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) return undefined
        if(!response.ok){ throw response; }

        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }

    public async readOneEAGER(id: number): Promise<ProductEAGER | undefined> {
        const obj = await this.readOne(id)
        if(obj == undefined) return undefined

        // TODO use Promise#all
        const bandwidth = await this.readBandwidth(obj)
        
        const contract = await this.readContract(obj)
        
        const possibleTurbos = await this.readPossibleTurbos(obj)

        return {
            bandwidth,
            contract,
            possibleTurbos,
            ...obj,
            id
        }
    }

    public async update<T extends Product>(obj: T): Promise<Product> {
        const product: Product = this.toProduct(obj);
        return await this._requestAdapter.updateObject(product)
    }

    public async delete(obj: ProductDto) {
        return await this._requestAdapter.deleteObject(obj)
    }

    public toProduct<T extends Product>(obj: T): Product {
        const result: Product =  {
            ...this.toProductBase(obj),
            id: obj.id,
            _links: obj._links,
        }
        return result;
    }

    public toProductDto(obj: Product): ProductDto {
        return obj
    }

    public toProductBase<T extends ProductBase>(obj: T): ProductBase {
        const result: ProductBase =  {
            id: obj.id,
            activated: obj.activated,
            name: obj.name,
            productNumber: obj.productNumber,
        };
        return result;
    }

    public async readBandwidth(obj: ProductDto): Promise<BandwidthOption | undefined> {
        return this.readBandwidthProjection<BandwidthOption>(obj);
    }
    
    public async readBandwidthProjectionBandwidthOptionEAGERBaseProjection(obj: ProductDto): Promise<BandwidthOptionEAGERBaseProjection | undefined> {
        return this.readBandwidthProjection<BandwidthOptionEAGERBaseProjection>(obj, "baseProjection")
    }
    
    public async readBandwidthProjection<T extends ApiBase & { _links: ApiNavigationLinks }>(obj: ProductDto, projection?: string): Promise<T | undefined> {
        const hasProjection = (!!projection);
        let fullUrl = apiHelper.removeParamsFromNavigationHref(obj._links.bandwidth);
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;
    
        const response = await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) { return undefined; }
        if(!response.ok){ throw response; }
        const result = (await response.json()) as T
        return apiHelper.injectIds(result);
    }
    
    public async setBandwidth(returnType: Product, child: BandwidthOption) {
        if(!returnType._links) throw `Parent has no _links: ${returnType.id}`
        if(!child._links) throw `Child has no _links: ${child.id}`
        await this._requestAdapter.adaptAnyToOne(
            apiHelper.removeParamsFromNavigationHref(returnType._links.bandwidth),
            child._links.self.href
        )
    }
    
    public async deleteFromBandwidth(returnType: Product, childToDelete: BandwidthOption) {
        await this._requestAdapter.getRequest().delete(`/rest/product/${returnType.id}/bandwidth/${childToDelete.id}`)
    }
    
    public async readContract(obj: ProductDto): Promise<Contract | undefined> {
        return this.readContractProjection<Contract>(obj);
    }
    
    public async readContractProjectionContractBaseProjection(obj: ProductDto): Promise<ContractBaseProjection | undefined> {
        return this.readContractProjection<ContractBaseProjection>(obj, "baseProjection")
    }
    
    public async readContractProjectionContractForPresentation(obj: ProductDto): Promise<ContractForPresentation | undefined> {
        return this.readContractProjection<ContractForPresentation>(obj, "contractForPresentation")
    }
    
    public async readContractProjection<T extends ApiBase & { _links: ApiNavigationLinks }>(obj: ProductDto, projection?: string): Promise<T | undefined> {
        const hasProjection = (!!projection);
        let fullUrl = apiHelper.removeParamsFromNavigationHref(obj._links.contract);
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;
    
        const response = await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) { return undefined; }
        if(!response.ok){ throw response; }
        const result = (await response.json()) as T
        return apiHelper.injectIds(result);
    }
    
    public async setContract(returnType: Product, child: Contract) {
        if(!returnType._links) throw `Parent has no _links: ${returnType.id}`
        if(!child._links) throw `Child has no _links: ${child.id}`
        await this._requestAdapter.adaptAnyToOne(
            apiHelper.removeParamsFromNavigationHref(returnType._links.contract),
            child._links.self.href
        )
    }
    
    public async deleteFromContract(returnType: Product, childToDelete: Contract) {
        await this._requestAdapter.getRequest().delete(`/rest/product/${returnType.id}/contract/${childToDelete.id}`)
    }
    
    public async readPossibleTurbos(obj: ProductDto): Promise<Turbo[]> {
        return this.readPossibleTurbosProjection<Turbo>(obj);
    }
    
    public async readPossibleTurbosProjectionTurboBaseProjection(obj: ProductDto): Promise<TurboBaseProjection[]> {
        return this.readPossibleTurbosProjection<TurboBaseProjection>(obj, "baseProjection")
    }
    
    public async readPossibleTurbosProjectionTurboForPresentation(obj: ProductDto): Promise<TurboForPresentation[]> {
        return this.readPossibleTurbosProjection<TurboForPresentation>(obj, "turboForPresentation")
    }
    
    public async readPossibleTurbosProjection<T extends ApiBase & { _links: ApiNavigationLinks }>(obj: ProductDto, projection?: string): Promise<T[]> {
        const hasProjection = (!!projection);
        let fullUrl = apiHelper.removeParamsFromNavigationHref(obj._links.possibleTurbos);
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;
    
        const response = await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) { return []; }
        if(!response.ok){ throw response; }
        return (((await response.json()) as ApiHateoasObjectBase<T[]>)._embedded.turbo).map(item => (apiHelper.injectIds(item)));
    }
    
    public async setPossibleTurbos(returnType: Product, children: Turbo[]) {
        if(!returnType._links) throw `Parent has no _links: ${returnType.id}`
        await this._requestAdapter.adaptAnyToMany(
            apiHelper.removeParamsFromNavigationHref(returnType._links.possibleTurbos),
            children.map(c => {
                if(!c._links) throw `Child has no _links: ${c.id}`
                return c._links.self.href
            })
        )
    }
    
    public async addToPossibleTurbos(returnType: Product, childToAdd: Turbo) {
        await this._requestAdapter.addToObj(childToAdd, returnType, "possibleTurbos")
    }
    
    public async deleteFromPossibleTurbos(returnType: Product, childToDelete: Turbo) {
        await this._requestAdapter.getRequest().delete(`/rest/product/${returnType.id}/possibleTurbos/${childToDelete.id}`)
    }

    

    public async putByProductIdActivate<T extends Product>(productId: number, projection?: string): Promise<T>  {
        const request = this._requestAdapter.getRequest()
    
        const url = stringHelper.appendParams(
            `/rest/product/${productId}/activate`,
            projection && `projection=${projection}`
        )
    
        
        const response = await request.fetch(
            url,
            {
                method: "PUT"
            })
    
        if(!response.ok) {
            throw response;
        }
        
        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }
    
    
    
    public async putByProductIdDeactivate<T extends Product>(productId: number, projection?: string): Promise<T>  {
        const request = this._requestAdapter.getRequest()
    
        const url = stringHelper.appendParams(
            `/rest/product/${productId}/deactivate`,
            projection && `projection=${projection}`
        )
    
        
        const response = await request.fetch(
            url,
            {
                method: "PUT"
            })
    
        if(!response.ok) {
            throw response;
        }
        
        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }
}

export class TurboClient extends BaseClient<ApiClient> {

    constructor(apiClient: ApiClient, requestAdapter?: RequestAdapter){
        super(apiClient, requestAdapter);
        this.readOne = this.readOne.bind(this);
        this.readProjection = this.readProjection.bind(this);
        this.readBandwidthProjection = this.readBandwidthProjection.bind(this);
    }

    public async create(obj: TurboBase): Promise<Turbo> {
        return await this._requestAdapter.createObject(obj, "/rest/turbo") as Turbo
    }

    public build(): TurboBase {
        return {
            id: 0,
            duration: "",
            enabled: false,
            hours: 0,
            price: "",
            pricelabel: "",
            sorting: 0,
            turboNumber: ""
        }
    }

    public async readAll(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "duration,ASC" | "duration,DESC" | "enabled,ASC" | "enabled,DESC" | "price,ASC" | "price,DESC" | "turboNumber,ASC" | "turboNumber,DESC") : Promise<PagedItems<Turbo>> {
        return await this.readProjections<Turbo>(undefined, page, size, sort);
    }

    public async readProjectionsTurboBaseProjection(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "duration,ASC" | "duration,DESC" | "enabled,ASC" | "enabled,DESC" | "price,ASC" | "price,DESC" | "turboNumber,ASC" | "turboNumber,DESC") : Promise<PagedItems<TurboBaseProjection>> {
        return this.readProjections<TurboBaseProjection>("baseProjection", page, size, sort);
    }
    
    public async readProjectionsTurboForPresentation(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "duration,ASC" | "duration,DESC" | "enabled,ASC" | "enabled,DESC" | "price,ASC" | "price,DESC" | "turboNumber,ASC" | "turboNumber,DESC") : Promise<PagedItems<TurboForPresentation>> {
        return this.readProjections<TurboForPresentation>("turboForPresentation", page, size, sort);
    }

    public async readProjections<T extends TurboBase & { _links: ApiNavigationLinks }>(projectionName?: string, page?: number, size?: number, sort?: string) : Promise<PagedItems<T>> {
        return await this._requestAdapter.getPage<T>("/rest/turbo", "turbo", projectionName, page, size, sort);
    }

    public async readOne(id: number): Promise<Turbo | undefined> {
        return this.readProjection<Turbo>(id);
    }

    public async readProjectionTurboBaseProjection(id: number): Promise<TurboBaseProjection | undefined> {
        return this.readProjection<TurboBaseProjection>(id, "baseProjection")
    }
    
    public async readProjectionTurboForPresentation(id: number): Promise<TurboForPresentation | undefined> {
        return this.readProjection<TurboForPresentation>(id, "turboForPresentation")
    }

    public async readProjection<T extends Turbo>(id: number, projection?: string): Promise<T | undefined> {
        const hasProjection = (!!projection);
        let fullUrl = `/rest/turbo/${id}`;
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;

        const response =  await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) return undefined
        if(!response.ok){ throw response; }

        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }

    public async readOneEAGER(id: number): Promise<TurboEAGER | undefined> {
        const obj = await this.readOne(id)
        if(obj == undefined) return undefined

        // TODO use Promise#all
        const bandwidth = await this.readBandwidth(obj)

        return {
            bandwidth,
            ...obj,
            id
        }
    }

    public async update<T extends Turbo>(obj: T): Promise<Turbo> {
        const turbo: Turbo = this.toTurbo(obj);
        return await this._requestAdapter.updateObject(turbo)
    }

    public async delete(obj: TurboDto) {
        return await this._requestAdapter.deleteObject(obj)
    }

    public toTurbo<T extends Turbo>(obj: T): Turbo {
        const result: Turbo =  {
            ...this.toTurboBase(obj),
            id: obj.id,
            _links: obj._links,
        }
        return result;
    }

    public toTurboDto(obj: Turbo): TurboDto {
        return obj
    }

    public toTurboBase<T extends TurboBase>(obj: T): TurboBase {
        const result: TurboBase =  {
            id: obj.id,
            duration: obj.duration,
            enabled: obj.enabled,
            hours: obj.hours,
            price: obj.price,
            pricelabel: obj.pricelabel,
            sorting: obj.sorting,
            turboNumber: obj.turboNumber,
        };
        return result;
    }

    public async readBandwidth(obj: TurboDto): Promise<BandwidthOption | undefined> {
        return this.readBandwidthProjection<BandwidthOption>(obj);
    }
    
    public async readBandwidthProjectionBandwidthOptionEAGERBaseProjection(obj: TurboDto): Promise<BandwidthOptionEAGERBaseProjection | undefined> {
        return this.readBandwidthProjection<BandwidthOptionEAGERBaseProjection>(obj, "baseProjection")
    }
    
    public async readBandwidthProjection<T extends ApiBase & { _links: ApiNavigationLinks }>(obj: TurboDto, projection?: string): Promise<T | undefined> {
        const hasProjection = (!!projection);
        let fullUrl = apiHelper.removeParamsFromNavigationHref(obj._links.bandwidth);
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;
    
        const response = await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) { return undefined; }
        if(!response.ok){ throw response; }
        const result = (await response.json()) as T
        return apiHelper.injectIds(result);
    }
    
    public async setBandwidth(returnType: Turbo, child: BandwidthOption) {
        if(!returnType._links) throw `Parent has no _links: ${returnType.id}`
        if(!child._links) throw `Child has no _links: ${child.id}`
        await this._requestAdapter.adaptAnyToOne(
            apiHelper.removeParamsFromNavigationHref(returnType._links.bandwidth),
            child._links.self.href
        )
    }
    
    public async deleteFromBandwidth(returnType: Turbo, childToDelete: BandwidthOption) {
        await this._requestAdapter.getRequest().delete(`/rest/turbo/${returnType.id}/bandwidth/${childToDelete.id}`)
    }

    

    
}

export class TurboOrderClient extends BaseClient<ApiClient> {

    constructor(apiClient: ApiClient, requestAdapter?: RequestAdapter){
        super(apiClient, requestAdapter);
        this.readOne = this.readOne.bind(this);
        this.readProjection = this.readProjection.bind(this);
        this.readContractProjection = this.readContractProjection.bind(this);
        this.readTurboProjection = this.readTurboProjection.bind(this);
    }

    public async create(obj: TurboOrderBase): Promise<TurboOrder> {
        return await this._requestAdapter.createObject(obj, "/rest/turboOrder") as TurboOrder
    }

    public build(): TurboOrderBase {
        return {
            id: 0,
            deliveryDate: "1970-01-01T00:00:00",
            expiresDate: "1970-01-01T00:00:00",
            orderDate: "1970-01-01T00:00:00",
            orderNumber: "",
            running: false,
            subscription: false,
            status: OrderStatus.Pending
        }
    }

    public async readAll(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "orderDate,ASC" | "orderDate,DESC" | "orderNumber,ASC" | "orderNumber,DESC" | "running,ASC" | "running,DESC") : Promise<PagedItems<TurboOrder>> {
        return await this.readProjections<TurboOrder>(undefined, page, size, sort);
    }

    public async readProjectionsTurboOrderBaseProjection(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "orderDate,ASC" | "orderDate,DESC" | "orderNumber,ASC" | "orderNumber,DESC" | "running,ASC" | "running,DESC") : Promise<PagedItems<TurboOrderBaseProjection>> {
        return this.readProjections<TurboOrderBaseProjection>("baseProjection", page, size, sort);
    }
    
    public async readProjectionsTurboOrderForPresentation(page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "orderDate,ASC" | "orderDate,DESC" | "orderNumber,ASC" | "orderNumber,DESC" | "running,ASC" | "running,DESC") : Promise<PagedItems<TurboOrderForPresentation>> {
        return this.readProjections<TurboOrderForPresentation>("turboOrderForPresentation", page, size, sort);
    }

    public async readProjections<T extends TurboOrderBase & { _links: ApiNavigationLinks }>(projectionName?: string, page?: number, size?: number, sort?: string) : Promise<PagedItems<T>> {
        return await this._requestAdapter.getPage<T>("/rest/turboOrder", "turboOrder", projectionName, page, size, sort);
    }

    public async readOne(id: number): Promise<TurboOrder | undefined> {
        return this.readProjection<TurboOrder>(id);
    }

    public async readProjectionTurboOrderBaseProjection(id: number): Promise<TurboOrderBaseProjection | undefined> {
        return this.readProjection<TurboOrderBaseProjection>(id, "baseProjection")
    }
    
    public async readProjectionTurboOrderForPresentation(id: number): Promise<TurboOrderForPresentation | undefined> {
        return this.readProjection<TurboOrderForPresentation>(id, "turboOrderForPresentation")
    }

    public async readProjection<T extends TurboOrder>(id: number, projection?: string): Promise<T | undefined> {
        const hasProjection = (!!projection);
        let fullUrl = `/rest/turboOrder/${id}`;
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;

        const response =  await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) return undefined
        if(!response.ok){ throw response; }

        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }

    public async readOneEAGER(id: number): Promise<TurboOrderEAGER | undefined> {
        const obj = await this.readOne(id)
        if(obj == undefined) return undefined

        // TODO use Promise#all
        const contract = await this.readContract(obj)
        
        const turbo = await this.readTurbo(obj)

        return {
            contract,
            turbo,
            ...obj,
            id
        }
    }

    public async update<T extends TurboOrder>(obj: T): Promise<TurboOrder> {
        const turboOrder: TurboOrder = this.toTurboOrder(obj);
        return await this._requestAdapter.updateObject(turboOrder)
    }

    public async delete(obj: TurboOrderDto) {
        return await this._requestAdapter.deleteObject(obj)
    }

    public toTurboOrder<T extends TurboOrder>(obj: T): TurboOrder {
        const result: TurboOrder =  {
            ...this.toTurboOrderBase(obj),
            id: obj.id,
            _links: obj._links,
        }
        return result;
    }

    public toTurboOrderDto(obj: TurboOrder): TurboOrderDto {
        return obj
    }

    public toTurboOrderBase<T extends TurboOrderBase>(obj: T): TurboOrderBase {
        const result: TurboOrderBase =  {
            id: obj.id,
            deliveryDate: obj.deliveryDate,
            expiresDate: obj.expiresDate,
            orderDate: obj.orderDate,
            orderNumber: obj.orderNumber,
            running: obj.running,
            subscription: obj.subscription,
            status: obj.status,
        };
        return result;
    }

    public async readContract(obj: TurboOrderDto): Promise<Contract | undefined> {
        return this.readContractProjection<Contract>(obj);
    }
    
    public async readContractProjectionContractBaseProjection(obj: TurboOrderDto): Promise<ContractBaseProjection | undefined> {
        return this.readContractProjection<ContractBaseProjection>(obj, "baseProjection")
    }
    
    public async readContractProjectionContractForPresentation(obj: TurboOrderDto): Promise<ContractForPresentation | undefined> {
        return this.readContractProjection<ContractForPresentation>(obj, "contractForPresentation")
    }
    
    public async readContractProjection<T extends ApiBase & { _links: ApiNavigationLinks }>(obj: TurboOrderDto, projection?: string): Promise<T | undefined> {
        const hasProjection = (!!projection);
        let fullUrl = apiHelper.removeParamsFromNavigationHref(obj._links.contract);
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;
    
        const response = await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) { return undefined; }
        if(!response.ok){ throw response; }
        const result = (await response.json()) as T
        return apiHelper.injectIds(result);
    }
    
    public async setContract(returnType: TurboOrder, child: Contract) {
        if(!returnType._links) throw `Parent has no _links: ${returnType.id}`
        if(!child._links) throw `Child has no _links: ${child.id}`
        await this._requestAdapter.adaptAnyToOne(
            apiHelper.removeParamsFromNavigationHref(returnType._links.contract),
            child._links.self.href
        )
    }
    
    public async deleteFromContract(returnType: TurboOrder, childToDelete: Contract) {
        await this._requestAdapter.getRequest().delete(`/rest/turboOrder/${returnType.id}/contract/${childToDelete.id}`)
    }
    
    public async readTurbo(obj: TurboOrderDto): Promise<Turbo | undefined> {
        return this.readTurboProjection<Turbo>(obj);
    }
    
    public async readTurboProjectionTurboBaseProjection(obj: TurboOrderDto): Promise<TurboBaseProjection | undefined> {
        return this.readTurboProjection<TurboBaseProjection>(obj, "baseProjection")
    }
    
    public async readTurboProjectionTurboForPresentation(obj: TurboOrderDto): Promise<TurboForPresentation | undefined> {
        return this.readTurboProjection<TurboForPresentation>(obj, "turboForPresentation")
    }
    
    public async readTurboProjection<T extends ApiBase & { _links: ApiNavigationLinks }>(obj: TurboOrderDto, projection?: string): Promise<T | undefined> {
        const hasProjection = (!!projection);
        let fullUrl = apiHelper.removeParamsFromNavigationHref(obj._links.turbo);
        fullUrl = hasProjection ? `${fullUrl}?projection=${projection}` : fullUrl;
    
        const response = await this._requestAdapter.getRequest().get(fullUrl)
        if(response.status == 404) { return undefined; }
        if(!response.ok){ throw response; }
        const result = (await response.json()) as T
        return apiHelper.injectIds(result);
    }
    
    public async setTurbo(returnType: TurboOrder, child: Turbo) {
        if(!returnType._links) throw `Parent has no _links: ${returnType.id}`
        if(!child._links) throw `Child has no _links: ${child.id}`
        await this._requestAdapter.adaptAnyToOne(
            apiHelper.removeParamsFromNavigationHref(returnType._links.turbo),
            child._links.self.href
        )
    }
    
    public async deleteFromTurbo(returnType: TurboOrder, childToDelete: Turbo) {
        await this._requestAdapter.getRequest().delete(`/rest/turboOrder/${returnType.id}/turbo/${childToDelete.id}`)
    }

    public async searchFindByCustomer<T extends TurboOrder>(customerId: number, projection?: string, page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "orderDate,ASC" | "orderDate,DESC" | "orderNumber,ASC" | "orderNumber,DESC" | "running,ASC" | "running,DESC"): Promise<PagedItems<T>> {
        const request = this._requestAdapter.getRequest()
    
        const url = stringHelper.appendParams(
            "/rest/turboOrder/search/findByCustomer",
            `customerId=${customerId || ""}`,
            projection && `projection=${projection}`,
            sort && `sort=${sort}`,
            "size=20"
        )
    
        
        const response = await request.get(url)
        const obj = ((await response.json()) as ApiHateoasObjectReadMultiple<T[]>)
        const elements = (obj._embedded && obj._embedded.turboOrder || []).map(item => (apiHelper.injectIds(item)))
    
        return {
            items: elements,
            _links: obj._links, page: obj.page
        }
    }
    
    
    
    public async searchFindByCustomerAndStatus<T extends TurboOrder>(customerId: number, orderStatus: OrderStatus, projection?: string, page?: number, size?: number, sort?: "id,ASC" | "id,DESC" | "orderDate,ASC" | "orderDate,DESC" | "orderNumber,ASC" | "orderNumber,DESC" | "running,ASC" | "running,DESC"): Promise<PagedItems<T>> {
        const request = this._requestAdapter.getRequest()
    
        const url = stringHelper.appendParams(
            "/rest/turboOrder/search/findByCustomerAndStatus",
            `customerId=${customerId || ""}`,
            `orderStatus=${orderStatus || ""}`,
            projection && `projection=${projection}`,
            "size=20"
        )
    
        
        const response = await request.get(url)
        const obj = ((await response.json()) as ApiHateoasObjectReadMultiple<T[]>)
        const elements = (obj._embedded && obj._embedded.turboOrder || []).map(item => (apiHelper.injectIds(item)))
    
        return {
            items: elements,
            _links: obj._links, page: obj.page
        }
    }

    public async putByOrderIdCancel<T extends TurboOrder>(orderId: number, projection?: string): Promise<T>  {
        const request = this._requestAdapter.getRequest()
    
        const url = stringHelper.appendParams(
            `/rest/turboOrder/${orderId}/cancel`,
            projection && `projection=${projection}`
        )
    
        
        const response = await request.fetch(
            url,
            {
                method: "PUT"
            })
    
        if(!response.ok) {
            throw response;
        }
        
        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }
    
    
    
    public async patchExtendByOrderId<T extends TurboOrder>(orderId: number, projection?: string): Promise<T>  {
        const request = this._requestAdapter.getRequest()
    
        const url = stringHelper.appendParams(
            `/rest/turboOrder/extend/${orderId}`,
            projection && `projection=${projection}`
        )
    
        
        const response = await request.fetch(
            url,
            {
                method: "PATCH"
            })
    
        if(!response.ok) {
            throw response;
        }
        
        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }
    
    
    
    public async getLatestRunning<T extends TurboOrder>(projection?: string): Promise<T>  {
        const request = this._requestAdapter.getRequest()
    
        const url = stringHelper.appendParams(
            `/rest/turboOrder/latestRunning`,
            projection && `projection=${projection}`
        )
    
        
        const response = await request.fetch(
            url,
            {
                method: "GET"
            })
    
        if(!response.ok) {
            throw response;
        }
        
        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }
    
    
    
    public async getNonRunningActive<T extends TurboOrder>(projection?: string): Promise<T>  {
        const request = this._requestAdapter.getRequest()
    
        const url = stringHelper.appendParams(
            `/rest/turboOrder/nonRunningActive`,
            projection && `projection=${projection}`
        )
    
        
        const response = await request.fetch(
            url,
            {
                method: "GET"
            })
    
        if(!response.ok) {
            throw response;
        }
        
        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }
    
    
    
    public async postPlaceByTurboIdByAsSubscription<T extends TurboOrder>(turboId: number, asSubscription: boolean, projection?: string): Promise<T>  {
        const request = this._requestAdapter.getRequest()
    
        const url = stringHelper.appendParams(
            `/rest/turboOrder/place/${turboId}/${asSubscription}`,
            projection && `projection=${projection}`
        )
    
        
        const response = await request.fetch(
            url,
            {
                method: "POST"
            })
    
        if(!response.ok) {
            throw response;
        }
        
        const obj = (await response.json()) as T
        return apiHelper.injectIds(obj);
    }
}