import TokenAuthenticationHelper from 'src/Authentication/api/TokenAuthenticationHelper';
import { AUTH_SERVICE_BASE_URL, BFF_BASE_URL } from 'src/generatedConfig';
import { ApiClientExtended } from './ApiClientExtended';
import { RequestAdapter } from './RequestAdapter';
import AuthApiClient from './UserApiClient';

export const getApiClient = (): ApiClientExtended => {
    const authClient = getAuthApiClient();
    const baseUrl = BFF_BASE_URL;
    const client = new ApiClientExtended(new RequestAdapter(baseUrl, new TokenAuthenticationHelper(authClient)), baseUrl);
    return client;
}

export const oauthServerUri = AUTH_SERVICE_BASE_URL;
export const oauthServerClientId = "ttapp-bff-web";
export const oauthServerClientSecret = "3569a397-17d1-4829-bc42-cc576b1402d1";

export const getAuthApiClient = (): AuthApiClient => {
    const userClient = new AuthApiClient(oauthServerUri);
    return userClient;
}