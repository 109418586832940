import * as React from "react";
import "./styles/ConfirmedOrderBox.css";

import turboTasteIcon from 'src/icons/TurboTasteSmall';
import ContentBox from 'src/MolekuleComponents/ContentBox';

// import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
// import { Dispatch } from 'redux';
import Header1 from 'src/AtomComponents/Header1';
// import { IAppState } from 'src/redux';


interface IOwnProps extends RouteComponentProps<any> {
}

// interface IDispatchToProps {
//     // placeOrder: () => Promise<any>;
// }

// interface IStateToProps {
//     // selectedTurbo: ITurbo | undefined;
// }

// type IConfirmedOrderBoxProps = IOwnProps & IDispatchToProps & IStateToProps;
type IConfirmedOrderBoxProps = IOwnProps;

const ConfirmedOrderBox = (props: IConfirmedOrderBoxProps) => {
    // const { } = props;

    return (
        <ContentBox
            isSingleCollumnBox={true}
            title={"Turbotaste"}
            titleIcon={turboTasteIcon}
        >
            <div>
                <div className="confirmedOrderContainerContent">
                    <Header1>Vielen Dank für Ihre Bestellung!</Header1>
                    <div className="confirmedOrderContainerText">
                        <p>Wir haben Ihre Bestellung erhalten und benachrichtigen Sie, sobald Ihr Turbo aktiv ist.</p>
                        <p>Dies kann wenige Minuten in Anspruch nehmen.</p>
                        <p>Während der Bestellaktivierung kann keine neue Buchung durchgeführt werden.</p>
                        <p>&nbsp;</p>
                        <p><Link to="/" >Zurück zum Dashboard</Link></p>
                    </div>
                </div>
            </div>
        </ContentBox >
    );
}

// const mapStateToProps = (state: IAppState, ownProps: IOwnProps): IStateToProps => {
//     const result: IStateToProps = {
//         ...ownProps,
//     }
//     return result;
// }

// const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
//     const result: IDispatchToProps = {
//         ...ownProps,
//     }
//     return result;
// }

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmedOrderBox));

export default withRouter(ConfirmedOrderBox);