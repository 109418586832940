import IAppAlertData from '../interfaces/AppAlertData';
import IDialogBoxMessage from 'src/DialogBox/interfaces/IDialogBoxMessage';

export interface IAppState {
    initialized: boolean;
    alerts: IAppAlertData[];
    messages: IDialogBoxMessage[];
}

export const defaultState: IAppState = {
    initialized: false,
    alerts: [],
    messages: [],
}

export default IAppState;