import * as React from "react";
import "./styles/SpeedInfoLine.css";

import delay from 'src/helpers/delay';

interface ISpeedInfoLineProps {
    percentage: number;
    percentageOrange?: number;
}

interface ISpeedInfoLineState {
    applyStyles: boolean;
}

class SpeedInfoLine extends React.Component<ISpeedInfoLineProps, ISpeedInfoLineState>  {
    public state: ISpeedInfoLineState = { applyStyles: false };

    public componentDidMount() {
        this.waitAndSetCss();
    }

    public render() {
        const { applyStyles } = this.state;

        const { percentage, percentageOrange } = this.props;
        const progressStyles: React.CSSProperties | undefined = applyStyles ? {
            opacity: percentage > 0 ? 1 : 0,
            width: `calc(${percentage * 100}% - 4px)`,
        } : undefined;

        const progressStylesOrange: React.CSSProperties | undefined = applyStyles && percentageOrange ? {
            opacity: percentageOrange > 0 ? 1 : 0,
            width: `calc(${percentageOrange * 100}% - 4px)`,
        } : undefined;

        return (
            <div className="speedInfoLineContainer">
                <div className="speedInfoLineBar">
                    <div className="backgroundLine" />
                    <div className="progressLine"
                        style={progressStyles}
                    />
                    {percentageOrange ?
                        <div className="progressLineOrange"
                            style={progressStylesOrange}
                        /> :
                        ""
                    }
                </div>
            </div>
        );
    }

    private async waitAndSetCss() {
        await delay(50);
        this.setState({ applyStyles: true })
    }
}

export default SpeedInfoLine;