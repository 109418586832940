import * as mom from "moment";

const moment = mom.default;

export class DateHelper {
    /**
     * contains the data string for minimal date value (which is 1st Januar 1970)
     */
    public static readonly MinDateDataString = "1970-01-01";

    public static readableDateFormat = "DD.MM.YYYY";
    public static readableTimeFormat = "HH:mm";


    public static dataDateFormat = "YYYY-MM-DD";

    private dateFormat = DateHelper.dataDateFormat;

    constructor(dateFormat?: string) {
        this.dateFormat = dateFormat ? dateFormat : this.dateFormat;
        this.toDateStringFromIsoString = this.toDateStringFromIsoString.bind(this);
    }

    public isLessThanOneWeekAway(date: Date): boolean {
        return (moment(this.getCurrentDate())).add(1, "weeks").diff(moment(date)) > 0;
    }
    public isInPast(date: Date): boolean {
        return moment(date).diff(moment(this.getCurrentDate())) < 0;
    }

    public getDaysLeft(date: Date): number {
        const daysLeft = dateHelper.getDaysBetween(dateHelper.getCurrentDate(), date);
        return daysLeft;
    }

    /**
     * 
     * @param earlier earlier date
     * @param later later date
     */
    public getDaysBetween(earlier: Date, later: Date) {
        const daysLeft = moment(later).diff(moment(earlier), "days");
        return daysLeft;
    }

    public setYear(year: number, referenceDate?: Date): Date | undefined {
        if (!referenceDate) {
            return moment().year(year).toDate();
        }

        return moment(referenceDate).year(year).toDate();
    }
    public setMonth(month: number, referenceDate?: Date): Date | undefined {
        if (!referenceDate) {
            return moment().month(month).toDate();
        }

        return moment(referenceDate).month(month).toDate();
    }

    public getDateStringFromFormattedString(formattedString: string, srcFormat?: string, toFormat?: string) {
        const date = this.getDateFromString(formattedString, srcFormat ? srcFormat : this.dateFormat);
        return this.getDateString(date, toFormat ? toFormat : DateHelper.readableDateFormat);
    }

    public getDateString(date: Date | undefined, format?: string): string | undefined {
        if (!date) {
            return undefined;
        }
        return moment(date).format(format);
    }

    public getDateFromString(formattedString: string | undefined, format?: string): Date | undefined {
        if (!formattedString) {
            return undefined;
        }
        const result = moment(formattedString, format ? format : this.dateFormat, true);
        return result.isValid() ? result.toDate() : undefined;
    }

    public getValueStringFromDate(value: Date | undefined): string | undefined {
        return value ? moment(value).format(this.dateFormat) : undefined;
    }
    public getCurrentDate(): Date {
        const now = moment();
        const result = this.getDateFromString(this.getValueStringFromDate(now.toDate())) as Date;
        return result;
    }
    public getValueStringForToday(): string {
        return moment().format(this.dateFormat);
    }

    public fromIsoDateTimeString(dateString: string) {
        return !dateString ? undefined : new Date(dateString.lastIndexOf("Z") === dateString.length - 1 ? dateString : (dateString + "Z"));
    }

    public toDateStringFromIsoString(formattedString: string, toFormat?: string) {
        const date = this.fromIsoDateTimeString(formattedString);
        return this.getDateString(date, toFormat ? toFormat : DateHelper.readableDateFormat);
    }

    public toTimeStringFromIsoString(formattedString: string, toFormat?: string) {
        const date = this.fromIsoDateTimeString(formattedString);
        return this.getDateString(date, toFormat ? toFormat : DateHelper.readableTimeFormat);
    }

    public toDateTimeStringFromIsoString(formattedString: string, toDateFormat?: string, toTimeFormat?: string) {
        return this.toDateStringFromIsoString(formattedString, toDateFormat ? toDateFormat : DateHelper.readableDateFormat) +
            ", um " +
            this.toTimeStringFromIsoString(formattedString, toTimeFormat ? toTimeFormat : DateHelper.readableTimeFormat);
    }


}

export const dateHelper = new DateHelper();