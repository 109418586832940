import { AnyAction, Dispatch } from "redux";

import { getApiClient } from '../../api';
import { BandwidthOption, TurboOrder, TurboForPresentation } from '../../api/Entities';
import BandwidthHelper from '../../Contract/helpers/BandwidthHelper';
import DurationHelper from '../../Contract/helpers/DurationHelper';
// import DurationHelper from 'src/Contract/helpers/DurationHelper';
import ProductHelper from '../../Contract/helpers/ProductHelper';
import IDurationViewModel from '../../Contract/interfaces/IDurationViewModel';
import { ReduxThunkDispatch } from '../../declarations/ReduxThunkDispatch';
import { ArrayHelper } from '../../helpers/ArrayHelper';
import delay from '../../helpers/delay';
import { IReduxState } from '../../redux';
import types from "./ActionTypes";
import TurboActionCreators from '../../Turbo/redux/ActionCreators';
import AppAlertHelper from '../../App/helper/AppAlertHelper';

class OrderActionCreators {

    constructor() {
        // this.retrievePossibleTurboOptions = this.retrievePossibleTurboOptions.bind(this);
        this.calculateSelectableOfferOptions = this.calculateSelectableOfferOptions.bind(this);
        this.retrieveTurbo = this.retrieveTurbo.bind(this);
        this.setOffersSelectedBandwidthOptionId = this.setOffersSelectedBandwidthOptionId.bind(this);
        this.setOffersSelectedDuration = this.setOffersSelectedDuration.bind(this);
        this.cancelTurboSubscription = this.cancelTurboSubscription.bind(this);
    }
    public setTurbo(turbo: TurboForPresentation | undefined): AnyAction {
        const result: AnyAction = { type: types.ORDER_SET_TURBO, turbo: turbo || undefined }
        return result;
    }

    public retrieveTurbo(turboId: number | undefined): (dispatch: Dispatch) => Promise<any> {
        return async (dispatch: Dispatch) => {
            try {
                dispatch(this.setLoadingTurbo(true));
                await delay(1000);
                if (!turboId) {
                    dispatch(this.setTurbo(undefined))
                } else {
                    const client = getApiClient();
                    const turbo = await client.turboClient.readProjection<TurboForPresentation>(turboId, "turboForPresentation");
                    dispatch(this.setTurbo(turbo));
                }
            } finally {
                dispatch(this.setLoadingTurbo(false));
            }

        }
    }

    public setLoadingTurbo(loading: boolean): AnyAction {
        return { type: types.ORDER_SET_LOADING_TURBO, loading: loading || false };
    }

    public setLoadingOffersTurboOptions(loading: boolean): AnyAction {
        const result: AnyAction = { type: types.SET_OFFERS_LOADING_TURBO_OPTIONS, loading: loading || false }
        return result;
    }

    // public retrievePossibleTurboOptions(): (dispatch: Dispatch, getState: () => IReduxState) => Promise<any> {
    //     return async (dispatch, getState) => {
    //         const dh = new DurationHelper();

    //         const turboPage = await getApiClient().turboClient.readProjections<TurboForPresentation>("turboForPresentation");

    //         const turbos = turboPage.items.filter(t => !!t.bandwidth && !!t.duration);

    //         const allBandwiths = (turbos || []).map(t => t.bandwidth);
    //         const distinctBandwithOptions = new BandwidthHelper().getDistinctBandwidths(allBandwiths);
    //         const distinctDurations = dh.getDistinctDurationViewModels((turbos || []).map(dh.createDurationViewModelFromTurbo));

    //         dispatch(this.setOffersBandwidthOptions(distinctBandwithOptions));
    //         dispatch(this.setOffersDurations(distinctDurations));

    //         await (dispatch as ReduxThunkDispatch<IReduxState>)(this.calculateSelectableOfferOptions());

    //     }
    // }

    public setOffersBandwidthOptions(options: BandwidthOption[]): AnyAction {
        const result: AnyAction = { type: types.SET_OFFERS_BANDWITH_OPTIONS, options: options || undefined }
        return result;
    }

    public setOffersDurations(options: IDurationViewModel[]): AnyAction {
        const result: AnyAction = { type: types.SET_OFFERS_DURATIONS, options: options || undefined }
        return result;
    }

    public calculateSelectableOfferOptions(): (dispatch: Dispatch, getState: () => IReduxState) => Promise<any> {
        return async (dispatch, getState) => {
            const { contract /*, order*/ } = getState();
            // const { offersBandwidthOptions, offersDurations } = order;

            if (
                // offersBandwidthOptions &&
                // offersDurations &&
                contract.contract && contract.contract.product) {
                const { product } = contract.contract;
                const selectableBandwidths = new ProductHelper().getSelectableBandwithOptions(product/*, offersBandwidthOptions*/);
                const selectableDurations = new ProductHelper().getSelectableDurationOptions(product/*, offersDurations*/);
                dispatch(this.setSelectableOffersBandwidthOptions(selectableBandwidths));
                dispatch(this.setSelectableOffersDurations(selectableDurations));
            }
        };
    }


    public setSelectableOffersBandwidthOptions(options: BandwidthOption[]): AnyAction {
        const result: AnyAction = { type: types.SET_SELECTABLE_OFFERS_BANDWITH_OPTIONS, options: options || undefined }
        return result;
    }

    public setSelectableOffersDurations(options: IDurationViewModel[]): AnyAction {
        const result: AnyAction = { type: types.SET_SELECTABLE_OFFERS_DURATIONS, options: options || undefined }
        return result;
    }
    public setPlacingOrder(placing: boolean): AnyAction {
        const result: AnyAction = { type: types.SET_PLACING_ORDER, placing: placing || false }
        return result;
    }

    public setOffersSelectedBandwidthOptionId(option: BandwidthOption | undefined): (dispatch: Dispatch, getState: () => IReduxState) => Promise<any> {
        return async (dispatch, getState) => {
            dispatch({ type: types.SET_OFFERS_SELECTED_BANDWITH_OPTION_ID, id: option || undefined });
            if (option) {
                const productHelper = new ProductHelper();
                const durationsHelper = new DurationHelper();
                const { contract, order } = getState();
                const { contract: c } = contract;
                const { selectedDuration: offerSelectedDuration } = order;
                const durations = offerSelectedDuration && c && c.product ? productHelper.getPossibleTurboDurations(c.product, option) : [];

                if (offerSelectedDuration &&
                    new ArrayHelper().getFirstMatch(durations || [], o => durationsHelper.isEqualDurationModel(o, offerSelectedDuration)) === undefined) {
                    await (dispatch as ReduxThunkDispatch<IReduxState>)(this.setOffersSelectedDuration(undefined))
                }
            }
        }
    }

    public setOffersSelectedDuration(duration: IDurationViewModel | undefined): (dispatch: Dispatch, getState: () => IReduxState) => Promise<any> {
        return async (dispatch, getState) => {
            dispatch({ type: types.SET_OFFERS_SELECTED_DURATION_ID, id: duration || undefined });
            if (duration) {
                const productHelper = new ProductHelper();
                const { contract, order } = getState();
                const { contract: c } = contract;
                const { selectedBandwidthOption } = order;
                const bandwidthOptions = selectedBandwidthOption && c && c.product ? productHelper.getPossibleTurboBandwithOptions(c.product, duration) : [];

                if (selectedBandwidthOption &&
                    new ArrayHelper().getFirstMatch(bandwidthOptions || [], o => new BandwidthHelper().isEqualBandwidth(o, selectedBandwidthOption)) === undefined) {
                    await (dispatch as ReduxThunkDispatch<IReduxState>)(this.setOffersSelectedBandwidthOptionId(undefined))
                }
            }
        }
    }

    public cancelTurboSubscription(activeTurbo: TurboOrder): (dispatch: Dispatch, getState: () => IReduxState) => Promise<any> {
        return async (dispatch, getState) => {
            const client = getApiClient();
            try {
                dispatch(this.setPlacingOrder(true));
                await client.turboOrderClient.putByOrderIdCancel(activeTurbo.id)
                new AppAlertHelper().addInfo("Die Verlängerung Ihres Turbos wurde abbestellt");
            } catch (err) {
                new AppAlertHelper().addError("Das Abbestelen ist fehlgeschlagen.\r\nBitte versuchen Sie es später erneut oder wenden Sie sich an unseren Kundendienst.", "Abbestellen fehlgeschlagen");
            } finally {
                dispatch(this.setPlacingOrder(false));
            }

            const turboActionCreator = new TurboActionCreators();
            await (dispatch as ReduxThunkDispatch<IReduxState>)(turboActionCreator.retrieveActiveTurbos());

            // hier vielleicht lieber die activeTurbos im state filtern? alten turbo rausschmeißen und neuen einfügen.
            // Ja, wäre möglich. Da wir aber auch diesen abprüfen müssten, wäre das kontraproduktiv. (Wir brauchen OrderForPresentation so wie ich das sehe.)
            // Allerdings
            // ich weiß aber leider nicht wie ich den redux state verändere
            /* swift way:
                guard let order = getLatestUnexpiredOrder() else { return false }
                guard let newOrder: TurboOrderForPresentation = try await(turboOrderClinet.customPutByOrderIdCancel(orderId: order.id)) else { return false }
                currentOrders = currentOrders.filter { $0.id != order.id }
                currentOrders.append(newOrder)
                latestOrder = newOrder
            */
        }
    }
}

export default OrderActionCreators;