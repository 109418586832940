import * as React from "react";
import "./styles/FormInputGroup.css";

export interface IFormInputGroupProps {
    showLabel?: boolean;
    label?: React.ReactNode;
    children?: React.ReactNode;
    inputElement?: React.ReactNode;
    labelHtmlFor?: string;
    vertical?: boolean;
    className?: string;
}

const FormInputGroup = (props: IFormInputGroupProps) => {
    const {
        label,
        labelHtmlFor,
        showLabel,
        children,
        inputElement,
        vertical,
        className,
    } = props;
    const groupClassName = "formInputGroup" + (vertical ? " vertical" : "") + (className ? ` ${className}` : "");
    return (

        <div className={groupClassName}>
            {showLabel && label ?
                <div className="inputLabelContainer">
                    <label htmlFor={labelHtmlFor || undefined}>{label}</label>
                </div> :
                ""}
            <div className="inputContainer">
                {children || inputElement || ""}
                {/* <TextInput name="password" id="userpassword" type="password" placeholder={showLabels ? "" : "Passwort"} /> */}
            </div>
        </div>
    )
}

export default FormInputGroup;