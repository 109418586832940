import * as React from "react";
import "./styles/BandwithSpeedInfoCurve.css"

import StringHelper from 'src/helpers/StringHelper';
import SpeedInfoCurve from './SpeedInfoCurve';

interface IBandwithSpeedInfoCurveProps {
    icon: ImageModules,
    bandwithTypeLabel: string,
    maxBandwith: number,
    currentBandwith: number,
    turboBandwith?: number,
    showTurboBandwithInLabel?: boolean;
    showSpeed?: boolean,
}

const BandwithSpeedInfoCurve = (props: IBandwithSpeedInfoCurveProps) => {
    const { bandwithTypeLabel, showTurboBandwithInLabel, icon, currentBandwith, turboBandwith, maxBandwith, showSpeed } = props;

    const numericCurrentBandwith = (currentBandwith);
    const numericMaxBandwith = (maxBandwith);
    const numericTurboBandwith = turboBandwith ? (turboBandwith) : undefined;

    return (
        <div className="bandwidthSpeedInfoCurve">
            <div className="infoLabelContainer">
                <div className="infoIcon"
                    style={{ backgroundImage: new StringHelper().createCssUrlString(icon) }}
                />
                <div className="infoLabel">{bandwithTypeLabel} {showTurboBandwithInLabel ? numericTurboBandwith : numericCurrentBandwith} Mbit/s</div>
            </div >
            <SpeedInfoCurve
                label={!showSpeed ?
                    undefined :
                    <div className="speedInfoInnerLabel" >
                        <div className={numericTurboBandwith ? "orange" : "blue"}>{`${numericTurboBandwith ? numericTurboBandwith : numericCurrentBandwith} Mbit/s`}</div>
                        {numericTurboBandwith ?
                            <div className="currentWithTurbo blue">
                                {`${numericCurrentBandwith} Mbit/s`}
                            </div> 
                            :
                            ""}
                    </div>
                }
                percentageBlue={numericCurrentBandwith / numericMaxBandwith}
                percentageOrange={numericTurboBandwith ? numericTurboBandwith / numericMaxBandwith : undefined} />
        </div>);
}


export default BandwithSpeedInfoCurve;