import * as React from "react";
import "./styles/ThreeValueSelect.css"

import StringHelper from 'src/helpers/StringHelper';

interface IThreeValueSelectProperties {
    buttonProps: IThreeValueSelectButtonProps[];
    className?: string;
}

class ThreeValueSelect extends React.Component<IThreeValueSelectProperties> {
    public render() {

        const { buttonProps, className } = this.props;
        const buttons = buttonProps.map((prps, i) =>
            <ThreeValueSelectButton key={i} {...prps} />
        );

        const wrapperClassName = "threeValueSelectWrapper" + (className ? ` ${className}` : "");
        return (
            <div className={wrapperClassName}>
                <div className={"threeValueSelectContainer"}>
                    {buttons}
                </div>
            </div>
        );
    }
}

export interface IThreeValueSelectButtonProps {
    content: React.ReactNode;

    selected?: boolean;
    unSelectable?: boolean;
    onSelect?: () => Promise<void>;
    disabled?: boolean;
    asGrey: boolean;
}
const ThreeValueSelectButton = (props: IThreeValueSelectButtonProps) => {
    const { content, unSelectable, onSelect, selected, asGrey, disabled } = props;
    const stringHelper = new StringHelper();

    const className = stringHelper.joinManyClassNames("threeValueSelectButton", (selected ? " selected" : undefined), (onSelect && !unSelectable ? " canSelect" : undefined), asGrey ? "grey" : "", disabled ? "disabled" : "");
    const clickhandler = !onSelect || disabled ?
        undefined :
        async (e: React.MouseEvent<HTMLDivElement>) => {
            if (unSelectable) { return; }
            if (e) { e.preventDefault(); }
            await onSelect();
        };

    return (
        <div
            className={className}
            onClick={clickhandler}>
            <div className="tvsButtonContent">
                {content}
            </div>
        </div>
    );
}

export default ThreeValueSelect;