import AppAlertHelper from 'src/App/helper/AppAlertHelper';
import AlertTypes from 'src/App/interfaces/AlertTypes';
import AuhtenticationActionCreators from 'src/Authentication/redux/ActionCreators';
import store from 'src/redux';
import { FetchRequest } from './FetchAdapter';
import ITokenAuthenticationEventsHelper from './ITokenAuthenticationEventsHelper';
import ITokenAuthenticationHelper from './ITokenAuthenticationHelper';

export class FetchRequestWrapper implements FetchRequest {
    private baseUrl = "";
    private authHelper: ITokenAuthenticationHelper & ITokenAuthenticationEventsHelper | undefined;

    private fetchFunction: (url: string | Request , options: RequestInit | undefined) => Promise<Response>;
    constructor(baseUrl?: string, authHelper?: ITokenAuthenticationHelper & ITokenAuthenticationEventsHelper) {
        this.authHelper = authHelper;
        this.baseUrl = baseUrl || this.baseUrl;
        this.fetchFunction = window.fetch.bind(window);
        this.fetch = this.fetch.bind(this);
    }

    public async fetch(url: string | Request | undefined, options?: RequestInit, dontApplyBaseUrl?: boolean): Promise<Response> {
        if (url === undefined) { throw new Error("No Url has been passed"); }

        const request = dontApplyBaseUrl ?
            url :
            (typeof url === "string" ? this.createUrl(url) : { ...url, url: this.createUrl(url.url) });
        const opts = await this.addHeaders(options);

        try {
            const result = await this.fetchFunction(request, opts);
            if (result.status === 401 && this.authHelper && this.authHelper.getAccessToken()) {
                let optsWithRefreshedToken: RequestInit | undefined;
                try {
                    optsWithRefreshedToken = await this.addHeaders(options, true);
                } catch (err) {
                    this.authHelper.authenticationFailed();
                    // tslint:disable-next-line:no-console
                    console.error("Failed to refresh AccessToken", err);
                    return result;
                }

                const result2 = await this.fetchFunction(request, optsWithRefreshedToken);
                return result2;
            }
            return result
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.log(err);
            throw err;
        }

    }

    public get(url: string, options?: RequestInit): Promise<Response> {
        return this.fetch((url), { method: "get", ...(options || {}) });
    }
    public delete(url: string, options?: RequestInit): Promise<Response> {
        return this.fetch((url), { method: "delete", ...(options || {}) });
    }
    public head(url: string, options?: RequestInit): Promise<Response> {
        return this.fetch((url), { method: "head", ...(options || {}) });
    }
    public options(url: string, options?: RequestInit): Promise<Response> {
        return this.fetch((url), { method: "options", ...(options || {}) });
    }
    public post(url: string, options?: RequestInit): Promise<Response> {
        return this.fetch((url), { method: "post", ...(options || {}) });
    }
    public put(url: string, options?: RequestInit): Promise<Response> {
        return this.fetch((url), { method: "put", ...(options || {}) });
    }
    public patch(url: string, options?: RequestInit): Promise<Response> {
        return this.fetch((url), { method: "patch", ...(options || {}) });
    }

    private createUrl(url: string) {
        if (!this.baseUrl || !url || url.toLowerCase().indexOf("://") >= 0) {
            return url;
        }
        let urlWithoutLeadingSlash = url;
        while (urlWithoutLeadingSlash.indexOf("/") === 0) {
            urlWithoutLeadingSlash = urlWithoutLeadingSlash.slice(1);
        }
        const baseUrlWithEndingSlash = (this.baseUrl.lastIndexOf("/") === this.baseUrl.length - 1) ?
            this.baseUrl :
            this.baseUrl + "/";
        return `${baseUrlWithEndingSlash}${urlWithoutLeadingSlash}`;

    }

    private async addHeaders(options?: RequestInit, performRefresh?: boolean): Promise<RequestInit | undefined> {
        if (this.authHelper) {
            const { headers } = options || { headers: {} };
            if (headers &&
                (headers as string[][]).concat &&
                (headers as string[][]).slice &&
                (headers as string[][]).length) {
                // headers is probably array
                const nextHeaders = (headers as string[][]).slice();

                // TODO: #FIXME
                // tslint:disable-next-line:no-console
                console.error("Will not apply auth headers to string array");
                return { ...(options || {}), headers: nextHeaders };
            } else {
                const nextHeaders: Record<string, string> = { ...(headers || {}) } as Record<string, string>;
                if (!(nextHeaders).Authorization) {

                    let accessToken: string | undefined = this.authHelper.getAccessToken()
                    if (performRefresh) {
                        try {
                            accessToken = await this.authHelper.refreshAccessToken();
                        } catch (err) {

                            const { authData } = store.getState().authentication;
                            if (!!authData) {
                                store.dispatch(new AuhtenticationActionCreators().setAuthData(undefined));

                                new AppAlertHelper().addAppAlert(
                                    {
                                        type: AlertTypes.ERROR,
                                        title: "Sie wurden automatisch abgemeldet",
                                        message: "Bitte melden Sie sich erneut an."
                                    }
                                );
                            }
                        }
                    }

                    if (accessToken) { nextHeaders.Authorization = `Bearer ${accessToken}`; }
                }
                return { ...(options || {}), headers: nextHeaders };
            }
        }
        return options;
    }
}

export default FetchRequestWrapper;