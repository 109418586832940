import * as React from "react";

import "./styles/TextInput.css";

interface ITextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    fullwidth?: boolean;
}

export const TextInput = (props: ITextInputProps) => {
    const { fullwidth, ...inputAttributes } = props;
    const { className, disabled } = inputAttributes;

    const txtBoxClassName = "ttTextInput" + (fullwidth ? " fullWidth" : "") + (className ? (` ${className}`) : "") + (disabled ? " disabled" : "");
    return (
        <input {...inputAttributes} className={txtBoxClassName} />);
}

export default TextInput;