import * as React from "react";
import "./styles/ContentBox.css"

import StringHelper from 'src/helpers/StringHelper';
import ContentBoxTitle from './ContentBoxTitle';

interface IContentBoxProperties {
    titleIcon?: ImageModules;
    title?: React.ReactNode;

    children?: React.ReactNode;
    isSingleCollumnBox?: boolean;

    minWidth?: number;
    className?: string
    
    style?: React.CSSProperties;
}

const ContentBox = (props: IContentBoxProperties) => {
    const { minWidth, style,  children, title, titleIcon, className: cn, isSingleCollumnBox } = props;
    const stringHelper = new StringHelper();

    const wrapperClass = stringHelper.joinManyClassNames("contentBoxWrapper", isSingleCollumnBox ? " colSpan2" : undefined, cn);


    return (
        <div
            className={wrapperClass}
            style={{ flex: minWidth !== undefined ? `1 1 ${minWidth}px` : undefined, ...(style ? style: {}) }}
        >
            <div className="contentBoxContainer">
                <ContentBoxTitle icon={titleIcon}>
                    {title}
                </ContentBoxTitle>
                <div className="contentBoxContentContainer">
                    {children}
                </div>
            </div>
        </div>
    );
}
export default ContentBox;