import * as React from "react";
import './styles/LoginForm.css';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Button from 'src/AtomComponents/Button';
import TextInput from 'src/AtomComponents/TextInput';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import StringHelper from 'src/helpers/StringHelper';
import FormInputGroup from 'src/MolekuleComponents/FormInputGroup';
import { IReduxState } from 'src/redux';
import IUser from './interfaces/IUser';
import AuhtenticationActionCreators from './redux/ActionCreators';

import { LoadingContainer } from 'src/MolekuleComponents/LoadingContainer';


// tslint:disable-next-line:no-empty-interface
interface IOffersOwnProps {
    showLabels?: boolean;
    className?: string;
    verticalForm?: boolean;
    // no own props yet 
}

interface IDispatchToProps {
    login: (username: string, password: string) => Promise<any>;
}

interface IStateToProps {
    user: IUser | undefined;
}

type ILoginFormProps = IOffersOwnProps & IDispatchToProps & IStateToProps;

interface ILoginFormState { username: string; password: string; loggingIn: boolean; emailInputId: string; passwordInputId: string }

class LoginForm extends React.Component<ILoginFormProps, ILoginFormState> {

    public state: ILoginFormState = {
        username: "",
        password: "",
        loggingIn: false,
        emailInputId: "email_" + new StringHelper().generateGuid(),
        passwordInputId: "password_" + new StringHelper().generateGuid(),
    };

    private componentIsMounted?: boolean;

    constructor(props: ILoginFormProps) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.performLogin = this.performLogin.bind(this);
        this.passwordKeyDown = this.passwordKeyDown.bind(this);
    }

    public componentWillUnmount() {
        this.componentIsMounted = false;
    }

    public componentDidMount() {
        this.componentIsMounted = true;
    }

    public render() {
        const { username, password, emailInputId, passwordInputId, loggingIn } = this.state;
        const { showLabels, className, verticalForm } = this.props;
        const formClassName = "loginForm " + (className ? className : "");

        const setUsername = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ username: e.target.value || "" }) }
        const setPassword = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ password: e.target.value || "" }) }
        return (
            <div className={formClassName}>
                <form onSubmit={this.onSubmit} method="POST">
                    <FormInputGroup vertical={verticalForm} labelHtmlFor={emailInputId} label="Benutzername:" showLabel={showLabels} >
                        <TextInput
                            disabled={loggingIn}
                            name="email"
                            autoComplete="email"
                            id={emailInputId}
                            type="text"
                            placeholder={showLabels ? "" : "Benutzername"}
                            fullwidth={true}
                            value={username}
                            onChange={setUsername || ""}
                        />
                    </FormInputGroup>
                    <FormInputGroup vertical={verticalForm} labelHtmlFor={passwordInputId} label="Passwort:" showLabel={showLabels} >
                        <TextInput
                            disabled={loggingIn}
                            name="password"
                            id={passwordInputId}
                            type="password"
                            placeholder={showLabels ? "" : "Passwort"}
                            fullwidth={true}
                            value={password || ""}
                            onChange={setPassword}
                            onKeyDown={this.passwordKeyDown}
                        />
                    </FormInputGroup>
                    <Button disabled={!username || !password || loggingIn} className="loginButton" orange={true} onClick={this.performLogin}>Login</Button>
                    <LoadingContainer
                        hidden={!loggingIn}
                        addWhiteBackgroundColor={true}
                        className={"globalLoadingIndicator"} />
                </form>
            </div>
        );
    }

    private passwordKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        const { username, password } = this.state;

        if (e && e.key === "Enter" && (!!username && !!password)) {
            this.performLogin();
        }
    }

    private onSubmit(e?: React.FormEvent<HTMLFormElement>) {
        if (e) { e.preventDefault(); }
    }

    private async performLogin(e?: React.MouseEvent<HTMLElement>) {
        if (e) { e.preventDefault(); }
        const { login } = this.props;
        const { username, password, loggingIn } = this.state;
        if (!loggingIn) {
            try {
                this.setState({ loggingIn: true });
                await login(username, password);
            } finally {
                if (this.componentIsMounted) {
                    // tslint:disable-next-line:no-console
                    this.setState({ loggingIn: false });
                }
            }
        }
    }
}

const mapStateToProps = (state: IReduxState, ownProps: IOffersOwnProps): IStateToProps => {
    const { authentication } = state;
    const result: IStateToProps = {
        ...ownProps,
        user: authentication.customer,
    }
    return result;
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOffersOwnProps): IDispatchToProps => {
    const actionCreators = new AuhtenticationActionCreators();
    const result: IDispatchToProps = {
        ...ownProps,
        login: (username: string, password: string) => (dispatch as ReduxThunkDispatch<IReduxState>)(actionCreators.login(username, password)),
    }
    return result;
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

