import IAuthData from '../interfaces/IAuthData';
import { Customer } from './../../api/Entities';

export interface IAuthenticationState {
    customer: Customer | undefined;
    authData: IAuthData | undefined,
}

export const defaultState: IAuthenticationState = {
    customer: undefined,
    authData: undefined,
}

export default IAuthenticationState;