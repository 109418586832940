import * as React from "react";
import "./styles/DivButtonWithArrow.css";

import StringHelper from 'src/helpers/StringHelper';
import linkBlueIcon from 'src/icons/LinkBlue';
import linkWhiteIcon from 'src/icons/LinkWhite';


interface IDivButtonWithArrowProps extends React.HTMLAttributes<HTMLDivElement> {
    white?: boolean;
    disabled?: boolean;
}

const DivButtonWithArrow = (props: IDivButtonWithArrowProps) => {
    const { className: cn, children, white, disabled, ...rest } = props;

    const stringHelper = new StringHelper();
    const className = stringHelper.joinManyClassNames(
        "divWithArrow",
        white && "white",
        disabled && "disabled",
        cn);
    const backgroundImage = stringHelper.createCssUrlString(white ? linkWhiteIcon : linkBlueIcon);

    return (
        <div {...{ ...rest, className }} >
            <div className="divWithArrowChildren" >{children}</div>
            <div className="divWithArrowIcon"
                style={{ backgroundImage }}
            />
        </div>
    )
}

export default DivButtonWithArrow;