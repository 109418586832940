import * as React from "react";
import './styles/ConfirmedOrderPageContent.css';

import { RouteComponentProps, withRouter } from 'react-router';
// import { Dispatch } from 'redux';
import ConfirmedOrderBox from './ConfirmedOrderBox';


interface IOwnProps extends RouteComponentProps<{}> {

}

// interface IDispatchToProps {
// }

// interface IStateToProps {
//     // selectedTurbo: Turbo | undefined;
// }

// type IConfirmedOrderPageContentProperties = IStateToProps & IDispatchToProps & IOwnProps;
type IConfirmedOrderPageContentProperties = IOwnProps;

class ConfirmedOrderPageContent extends React.Component<IConfirmedOrderPageContentProperties> {
    private componentIsMounted: boolean | undefined;

    constructor(props: IConfirmedOrderPageContentProperties) {
        super(props);
        this.onReadTerms = this.onReadTerms.bind(this);
        this.onAcceptPrivacy = this.onAcceptPrivacy.bind(this);
    }

    public componentWillMount() {
        // TODO: fetch orders, user etc. to view selected order!
        this.componentIsMounted = true;
        setTimeout(() => {
            this.componentIsMounted = true;
            if (this.componentIsMounted) {
                // this.props.history.push("/");
            }
        }, 5000);
    }

    public componentWillUnmount() {
        this.componentIsMounted = false;
    }

    public render() {
        return (
            <div className={"confirmedOrderPage"} >
                <div className="defaultPageContent confirmedOrderPageContent">
                    <ConfirmedOrderBox />
                </div>
            </div>
        );
    }

    private onReadTerms(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ termsRead: e.target.checked });
    }

    private onAcceptPrivacy(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ privacyAccepted: e.target.checked });
    }

}



// const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
//     const result: IDispatchToProps = {
//         ...ownProps,
//     }
//     return result;
// }
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmedOrderPageContent));
export default withRouter(ConfirmedOrderPageContent);