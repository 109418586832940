import * as React from "react";
import "./styles/AlertBar.css"

import AlertTypes from 'src/App/interfaces/AlertTypes';
import IAppAlertData from 'src/App/interfaces/AppAlertData';
import StringHelper from 'src/helpers/StringHelper';
import offlineIcon from 'src/icons/offline';


interface IAlertBarProps {

    alertData?: IAppAlertData;
    expanded?: boolean;
    onClick?: () => any;
}

const AlertBar = (props: IAlertBarProps) => {
    const { alertData, expanded, onClick } = props;
    const stringHelper = new StringHelper();

    const mappedValue = getMappedValue((alertData && alertData.type) || AlertTypes.NONE);
    const typeClass = mappedValue.className;
    const iconUrl = (alertData && alertData.icon) || mappedValue.defaultIcon;
    const title = (alertData && alertData.title) || mappedValue.defaultTitle;

    const className = stringHelper.joinManyClassNames(
        "alertBar",
        typeClass,
        expanded ? "expanded" : "collapsed");

    const contentContainerClassName = stringHelper.joinManyClassNames(
        "alertBarContainer",
        typeClass,
        expanded ? "expanded" : "collapsed");


    const titleClass = stringHelper.joinManyClassNames("title", typeClass);
    return (
        <div className={className}>
            <div className={contentContainerClassName}
                onClick={onClick}>
                <div className="alertBarContent">
                    {!alertData || alertData.noIcon ?
                        <div className="alertIcon noIcon" /> :
                        <div className="alertIcon"
                            style={{ backgroundImage: stringHelper.createCssUrlString(iconUrl) }}
                        />
                    }
                    <div className="alertTitleAndMessage">
                        <div className={titleClass}>
                            {title}
                        </div>
                        <div className="alertMessage">
                            {(alertData && alertData.message) || ""}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

interface IAlertTypeMapValue { defaultIcon: ImageModules, defaultTitle: string, className: string }

const alertTypeMap: { [key in AlertTypes]: IAlertTypeMapValue } = {
    [AlertTypes.NONE]: { defaultIcon: offlineIcon, defaultTitle: "", className: "none" },
    [AlertTypes.INFO]: { defaultIcon: offlineIcon, defaultTitle: "Information", className: "info" },
    [AlertTypes.WARNING]: { defaultIcon: offlineIcon, defaultTitle: "Warnung", className: "warning" },
    [AlertTypes.ERROR]: { defaultIcon: offlineIcon, defaultTitle: "Fehler", className: "error" },
};

const getMappedValue = <T extends string | ImageModules>(type: AlertTypes) => {
    const t = type || AlertTypes.ERROR;
    return alertTypeMap[t];
}

// const getMappedValueProperty = <T extends string | ImageModules>(type: AlertTypes, key: keyof IAlertTypeMapValue) => {
//     return getMappedValue(type)[key];
// }


export default AlertBar;