import * as React from "react";
import './styles/SettingsPageContent.css';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import { LoadingContainer } from 'src/MolekuleComponents/LoadingContainer';
import { IReduxState } from 'src/redux';
import NoNotificationSettingsBox from './NoNotificationSettingsBox';
import NotificationSettingsBox from './NotificationSettingsBox';
import SettingsActionCreators from './redux/ActionCreators';

// tslint:disable-next-line:no-empty-interface
interface IOwnProps extends RouteComponentProps<any> {
    // no own props yet 
}

// tslint:disable-next-line:no-empty-interface
interface IDispatchToProps {
    fetchNotificationSettings: () => Promise<any>
}


interface IStateToProps {
    loadingSettings: boolean;
}

type IOverviewPageContentProps = IOwnProps & IDispatchToProps & IStateToProps;

class SettingsPageContent extends React.Component<IOverviewPageContentProps>{

    public componentWillMount() {
        const { fetchNotificationSettings } = this.props;
        fetchNotificationSettings();
    }

    public render() {
        const { loadingSettings } = this.props;
        return (
            <div className={"settingsPageContent"}>
                <LoadingContainer hidden={!loadingSettings} />

                <NoNotificationSettingsBox />
                <NotificationSettingsBox />


            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { settings } = state;
    const { loadingSettings } = settings;
    const result: IStateToProps = {
        ...ownProps,
        loadingSettings: loadingSettings || false,
    }
    return result;
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
    const actionCreators = new SettingsActionCreators();
    const result: IDispatchToProps = {
        ...ownProps,
        fetchNotificationSettings: () => (dispatch as ReduxThunkDispatch<IReduxState>)(actionCreators.fetchNotificationSettings()),
    }
    return result;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsPageContent));