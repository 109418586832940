import AuthenticationFeautureApiClient from 'src/Authentication/api/ApiClient';
import IAuthApiClient from './IUserApiClient';

export class AuthApiClient implements IAuthApiClient {

    public readonly authServiceBaseUrl: string;
    // public readonly appServiceBaseUrl: string;

    public readonly auth: AuthenticationFeautureApiClient;

    // constructor(authServiceBaseUrl: string, appServiceBaseUrl: string) {
    constructor(authServiceBaseUrl: string) {
        this.authServiceBaseUrl = authServiceBaseUrl;
        // this.appServiceBaseUrl = appServiceBaseUrl;
        this.auth = new AuthenticationFeautureApiClient(this);
    }
}

export default AuthApiClient;