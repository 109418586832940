import * as React from "react";
import "./styles/OderBox.css";

import downloadIcon from 'src/icons/Download';
import turboTasteIcon from 'src/icons/TurboTasteSmall';
import uploadIcon from 'src/icons/Upload';
import ContentBox from 'src/MolekuleComponents/ContentBox';

import { RouteComponentProps, withRouter } from 'react-router';
import { getApiClient } from 'src/api';
import { ContractForPresentation, Customer } from 'src/api/Entities';
// import Button from 'src/AtomComponents/Button';
import ContentBoxSplitter from 'src/AtomComponents/ContentBoxSplitter';
import Header1 from 'src/AtomComponents/Header1';
import { TurboNameContainer } from 'src/Contract/components/TurboNameContainer';
import ProductHelper from 'src/Contract/helpers/ProductHelper';
import { DateHelper } from 'src/helpers/DateHelper';
import userIcon from 'src/icons/User';
import BandwithSpeedInfoCurve from 'src/MolekuleComponents/BandwithSpeedInfoCurve';
import CheckboxWithLabel from 'src/MolekuleComponents/CheckboxWithLabel';
import ContentBoxSaveButtons from 'src/MolekuleComponents/ContentBoxSaveButtons';
import TurboForPresentation from 'src/Turbo/interfaces/TurboForPresentation';

interface IOderBoxProps extends RouteComponentProps<any> {
    termsRead: boolean;
    privacyAccepted: boolean;

    onAcceptPrivacy: (e: React.ChangeEvent<HTMLInputElement>) => any;
    onReadTerms: (e: React.ChangeEvent<HTMLInputElement>) => any;
    placeOrder: () => Promise<any>;
    
    turbo: TurboForPresentation;
    contract: ContractForPresentation;
    customer: Customer;
}


const OderBox = (props: IOderBoxProps) => {
    const { turbo, customer, contract, privacyAccepted, termsRead, onReadTerms, onAcceptPrivacy, placeOrder } = props;

    const productHelper = new ProductHelper();

    const turboBandwidthOption = turbo.bandwidth || getApiClient().bandwidthOptionClient.build();
    const maxBandwidthOption = productHelper.getMaxBandwidth(contract.product)
    const productBandwithOption = contract.product.bandwidth;

    return (
        <ContentBox
            isSingleCollumnBox={true}
            title={"Turbotaste"}
            titleIcon={turboTasteIcon}
        >
            <div>
                <div className="selectedOrderContainer">
                    <Header1>
                        Details der Bestellung:&nbsp;
                        <span className="blue">{contract.product.name}</span>&nbsp;
                        <TurboNameContainer turbo={turbo} isActive={true} />
                    </Header1>
                    <div className="turboOrderSpeedInfoContainer">
                        <div className="downlaodInfo">
                            <BandwithSpeedInfoCurve
                                bandwithTypeLabel={"maximale Download-Geschwindigkeit"}
                                currentBandwith={productBandwithOption.download}
                                maxBandwith={maxBandwidthOption.download}
                                turboBandwith={turboBandwidthOption.download}
                                showTurboBandwithInLabel={true}
                                icon={downloadIcon}
                            />

                        </div>
                        <div className="uploadInfo">
                            <BandwithSpeedInfoCurve
                                bandwithTypeLabel={"maximale Upload-Geschwindigkeit"}
                                currentBandwith={productBandwithOption.upload}
                                maxBandwith={maxBandwidthOption.upload}
                                turboBandwith={turboBandwidthOption.upload}
                                showTurboBandwithInLabel={true}
                                icon={uploadIcon}
                            />
                        </div>
                    </div>
                    <ContentBoxSplitter />
                    <div className="turboOfferContractInfoContainer">
                        <div className="contractData">
                            <Header1>Ihre Vertragsdaten</Header1>
                            <div className="innerData">
                                <div className="customerInfo">
                                    <div className="customerIcon"
                                        style={{ backgroundImage: `url(${userIcon})` }}
                                    />
                                    <div className="customerData">
                                        <div className="customerName">{customer.fullName}</div>
                                        <div className="customerNumber">Knd-Nr: {customer.customerNumber}</div>
                                    </div>

                                </div>
                                <div className="customerAdress">
                                    <div>
                                        {customer.street}
                                    </div>
                                    <div>
                                        {customer.city}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="durationData">
                            <Header1>Laufzeit</Header1>
                            <div>
                                <span className="orange strong">Turbo:</span> {turbo.duration || ""}
                            </div>
                            <div>
                                <span className="blue strong">Reguläre Vertragslaufzeit:</span> {new DateHelper().toDateStringFromIsoString(contract.expires)}
                            </div>
                        </div>
                    </div>
                    <ContentBoxSplitter />
                    <div className="legalCheckboxesContainer">
                        <CheckboxWithLabel className="readTermsAndConditions" onChange={onReadTerms} checked={termsRead} >
                            Ich habe die <a href="/#">AGBs</a> gelesen.
                        </CheckboxWithLabel>
                        <CheckboxWithLabel className="confirmPrivacy" onChange={onAcceptPrivacy} checked={privacyAccepted} >
                            Ich bin damit einverstanden, dass meine Daten gespeichert werden und ich zukünftig über Telekommunikationsmedien (z. B. Telefon, E-Mail) durch die Dokom21 zu interessanten Themen (z. B. Veranstaltungen, Produkten und Dienstleistungen) kontaktiert werde.
                        </CheckboxWithLabel>
                    </div>

                    <ContentBoxSaveButtons
                        className="orderButtonsContainer"
                        saveButtonDisabled={!turbo || !termsRead || !privacyAccepted}
                        onSaveClick={placeOrder}
                        customSaveButtonLabel="Kostenpflichtig bestellen"
                        customCancelButtonLabel={<div>einmaliger Preis <span className="strong">{turbo.price}</span></div>}
                        cancelButtonDisabled={true}
                    />
                </div>
            </div>
        </ContentBox >
    );
}

export default withRouter(OderBox);
