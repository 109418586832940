import { AnyAction } from 'redux';

import types from "./ActionTypes";
import IReduxState, { defaultState } from "./State";

export const ContractReducer = (state: IReduxState = defaultState, action: AnyAction): IReduxState => {
    switch (action.type) {
        case types.SET_CONTRACT:
            return { ...state, contract: (action as any).contract || undefined };
        case types.SET_LOADING_CONTRACT:
            return { ...state, loadingContract: (action as any).loading || false };

        default:
            return state;
    }
}

export default ContractReducer;