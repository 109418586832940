import { AnyAction } from 'redux';

import types from "./ActionTypes";
import IReduxState, { defaultState } from "./State";

export const AuthenticationReducer = (state: IReduxState = defaultState, action: AnyAction): IReduxState => {
    switch (action.type) {
        case types.SET_LOGGED_IN_USER:
            return { ...state, customer: (action as any).user || undefined };
        case types.SET_AUTH_DATA:
            return { ...state, authData: (action as any).data || undefined };
        default:
            return state;
    }
}

export default AuthenticationReducer;