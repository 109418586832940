import * as React from "react";

import StringHelper from 'src/helpers/StringHelper';
import "./styles/Button.css";

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLElement> {
    orange?: boolean;
    whiteBorder?: boolean;
    useFullHeight?: boolean;
}

export const Button = (props: IButtonProps) => {
    const { orange, useFullHeight, whiteBorder, onClick: oc, ...buttonHtmlAttributes } = props;
    const { className, disabled } = buttonHtmlAttributes;

    const buttonClassName = new StringHelper().joinManyClassNames(
        "ttButton",
        whiteBorder ? "whiteBorder" : undefined,
        orange ? "orange" : undefined,
        useFullHeight ? "maxHeight" : undefined,
        className ? className : undefined,
        disabled ? "disabled" : undefined);
    const onClick: (e: React.MouseEvent<HTMLElement>) => any = (e: React.MouseEvent<HTMLElement>) => { if (oc && !disabled) { oc(e); } };
    return (
        <div
            {...buttonHtmlAttributes} onClick={onClick} className={buttonClassName} />);
}

export default Button;