import * as React from "react";
import "./styles/SpeedInfoCurve.css";

interface ISpeedInfoLineProps {
    percentageBlue: number;
    percentageOrange?: number;
    label?: React.ReactNode;
}

interface ISpeedInfoLineState {
    applyStyles: boolean;
    animationStarted: number | undefined;
    currentSpeed: string | undefined;
}

class SpeedInfoCurve extends React.Component<ISpeedInfoLineProps, ISpeedInfoLineState>  {
    public state: ISpeedInfoLineState = {
        applyStyles: false,
        animationStarted: undefined,
        currentSpeed: undefined,
    };

    private backgroundCanvas: HTMLCanvasElement | undefined;
    private blueCanvas: HTMLCanvasElement | undefined;
    private orangeCanvas: HTMLCanvasElement | undefined;

    private componentMounted: boolean | undefined;

    constructor(props: ISpeedInfoLineProps) {
        super(props);
        this.animate = this.animate.bind(this);
    }

    public componentDidMount() {
        this.componentMounted = true;
        this.waitAndSetCss();
    }
    public componentWillUnmount() {
        this.componentMounted = false;
    }

    public render() {
        const { currentSpeed, } = this.state;
        const { label, percentageOrange } = this.props;
        return (
            <div className="speedInfoCurveContainer">
                <div className="speedInfoCurveArc">
                    <canvas className="speedInfoCurveCanvas background" ref={o => this.backgroundCanvas = !!o ? o : this.backgroundCanvas} />
                    <canvas className="speedInfoCurveCanvas blue" ref={o => this.blueCanvas = !!o ? o : this.blueCanvas} />
                    <canvas className="speedInfoCurveCanvas orange" ref={o => this.orangeCanvas = !!o ? o : this.orangeCanvas} />
                    <div className={"speedInfoCurveLabelContainer"}>
                        {label ?
                            label :
                            currentSpeed !== undefined ?
                                <div className={(percentageOrange === undefined ? "blue " : "") /*+ (label ? "customLabel " : "") */ + "speedInfoCurveLabel"}>
                                    {`${currentSpeed}x`}
                                </div> : ""
                        }
                    </div>
                </div>
            </div>
        );
    }

    private async waitAndSetCss() {
        this.setState({ applyStyles: true, animationStarted: performance.now() })

        window.requestAnimationFrame(this.animate)
    }

    private animate(time: number) {
        if (!this.componentMounted) { return; }
        const { animationStarted } = this.state;
        const { percentageOrange, percentageBlue } = this.props;

        let progress = (time - (animationStarted || 0)) / 1250;
        progress = progress > 1 ? 1 : (progress < 0 ? 0 : progress);

        // ease in / out -> https://math.stackexchange.com/questions/121720/ease-in-out-function/121755#121755


        const easeFactor = 2;
        progress = progress === 1 ? 1 :
            Math.pow(progress, easeFactor) / (Math.pow(progress, easeFactor) + Math.pow(1 - progress, easeFactor))

        const maxPercentage = percentageOrange && percentageOrange > percentageBlue ?
            percentageOrange :
            percentageBlue;

        const currentPercentage = maxPercentage * progress;

        this.drawArcToCanvas(this.backgroundCanvas, "#efefef", 1);

        if (percentageBlue) {
            this.drawArcToCanvas(this.blueCanvas, "#269ed2", currentPercentage < percentageBlue ? currentPercentage : percentageBlue);
        }
        if (percentageOrange && currentPercentage > percentageBlue) {
            const orange = percentageOrange * progress;
            this.drawArcToCanvas(this.orangeCanvas, "#f38a32 ", orange, percentageBlue);
        }

        // const currentSpeed = currentPercentage < percentageBlue ?
        //     (Math.round(currentPercentage * 100 / percentageBlue) / 100) + "" :
        //     (Math.round(currentPercentage * 10 / percentageBlue) / 10) + "";

        const currentSpeed = (Math.round(currentPercentage * 10 / percentageBlue) / 10) + "";
        this.setState({ currentSpeed })
        if (progress < 1 && this.componentMounted) {
            window.requestAnimationFrame(this.animate)
        }
    }

    private drawArcToCanvas(canvas: HTMLCanvasElement | undefined,
        color: string,
        percentage: number,
        startAt: number = 0,
        lineWidth: number = 12
    ) {
        const ctx: CanvasRenderingContext2D | null | undefined = canvas && canvas.getContext("2d");
        if (ctx && !!canvas) {
            const dpr = window.devicePixelRatio || 1;
            const bounding = canvas.getBoundingClientRect();
            canvas.height = bounding.height * dpr;
            canvas.width = bounding.width * dpr;
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = "high";
            ctx.scale(dpr, dpr)

            ctx.clearRect(0, 0, bounding.width, bounding.height);
            if (startAt < 0 || percentage - startAt <= 0) {
                return;
            }

            const semiWidth = Math.floor(bounding.width / 2);
            const semiHeight = Math.floor(bounding.width / 2);
            let radius = (semiWidth > semiHeight ? semiHeight : semiWidth) - lineWidth;
            radius = radius < 0 ? 0 : radius;

            const normalStart = (160 / 360);
            const normalEnd = .5 - normalStart;
            const fillableAngle = 1 - (normalStart - normalEnd);

            const startEmptyAngle = (normalStart + (startAt && startAt > 0 ? startAt * fillableAngle : 0));

            const fillAngleTotal = 2 * Math.PI * fillableAngle;

            const length = (percentage - (startAt && startAt > 0 ? startAt : 0)) * fillAngleTotal;

            ctx.save()
            ctx.strokeStyle = color;
            ctx.fillStyle = color;

            ctx.translate(semiWidth, semiHeight);
            ctx.rotate(startEmptyAngle * 2 * Math.PI)

            if (!startAt) {
                ctx.save();
                ctx.translate(radius, 0);
                ctx.beginPath();
                ctx.arc(0, 0, Math.floor(lineWidth / 2), 0, 2 * Math.PI);
                ctx.fill();
                ctx.restore();
            }

            ctx.save();
            ctx.rotate(length)
            ctx.translate(radius, 0);
            ctx.beginPath();
            ctx.arc(0, 0, Math.floor(lineWidth / 2), 0, 2 * Math.PI);
            ctx.fill();
            ctx.restore();

            ctx.translate(-1 * semiWidth, -1 * semiHeight);

            ctx.beginPath();
            ctx.lineWidth = lineWidth;
            ctx.arc(
                semiWidth,
                semiHeight,
                radius,
                0,
                length);

            ctx.stroke();

        }
    }

}

export default SpeedInfoCurve;