import { NotificationsSettings } from 'src/api/Entities';

export interface ISettingsState {
    loadingSettings: boolean;
    savingSettings: boolean;
    notificationSettings: NotificationsSettings | undefined;
    noNotificationSettingsFlag: boolean;
}

export const defaultState: ISettingsState = {
    loadingSettings: false,
    savingSettings: false,
    notificationSettings: undefined,
    noNotificationSettingsFlag: false,
}

export default ISettingsState;