import * as React from "react";
import { useState } from "react";
import "./styles/AvailableTurbosBox.css";

import turboTasteImage from 'src/icons/TurboTaste';
import turboTasteIcon from 'src/icons/TurboTasteSmall';
import ContentBox from 'src/MolekuleComponents/ContentBox';
import ThreeValueSelect, { IThreeValueSelectButtonProps } from 'src/MolekuleComponents/ThreeValueSelect';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { BandwidthOption, ContractForPresentation, } from 'src/api/Entities';
import BandwidthHelper from 'src/Contract/helpers/BandwidthHelper';
import DurationHelper from 'src/Contract/helpers/DurationHelper';
import IDurationViewModel from 'src/Contract/interfaces/IDurationViewModel';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import { ArrayHelper } from 'src/helpers/ArrayHelper';
import { LoadingContainer } from 'src/MolekuleComponents/LoadingContainer';
import OrderActionCreators from 'src/Order/redux/ActionCreators';
import { IReduxState } from 'src/redux';
import ProductHelper from '../Contract/helpers/ProductHelper';
import { getApiClient } from 'src/api';
import IAppAlertData from 'src/App/interfaces/AppAlertData';
import AppActionCreators from 'src/App/redux/ActionCreators';
import AlertTypes from 'src/App/interfaces/AlertTypes';
import Button from 'src/AtomComponents/Button';
import ContractActionCreators from 'src/Contract/redux/ActionCreators';
import DialogBoxHelper from "src/App/helper/DialogBoxHelper";

// tslint:disable-next-line:no-empty-interface
interface IOwnProps extends RouteComponentProps<any> {
    // no own props yet 
}

interface IDispatchToProps {
    setSelectedBandwidthOption: (option: BandwidthOption | undefined) => any;
    setSelectedDurationOption: (duration: IDurationViewModel | undefined) => any;
    // retrievePossibleTurboOptions: () => Promise<any>;
    addAlert: (data: IAppAlertData) => any;
    setPlacingOrder: (placing: boolean) => any;
    setContract: (contract: ContractForPresentation | undefined) => any;

}

interface IStateToProps {
    loadinContract: boolean;
    contract: ContractForPresentation | undefined;

    loadingOffersTurboOptions: boolean;

    selectedBandwidthOption: BandwidthOption | undefined;
    // offersBandwidthOptions: BandwidthOption[]

    selectedDuration: IDurationViewModel | undefined;
    // offersDurations: IDurationViewModel[];

    selectableOffersBandwidthOptions: BandwidthOption[];
    selectableOffersDurations: IDurationViewModel[];
}

type IAvailableTurbosBoxProps = IOwnProps & IDispatchToProps & IStateToProps;

class AvailableTurbosBox extends React.Component<IAvailableTurbosBoxProps>{
    // public componentWillMount() {
    //     const { retrievePossibleTurboOptions } = this.props;
    //     retrievePossibleTurboOptions();
    // }

    public render() {

        const { loadingOffersTurboOptions, contract, ...availableTurbosContentBoxProps } = this.props;
        // const { offersBandwidthOptions, offersDurations } = availableTurbosContentBoxProps;
        return (
            <ContentBox
                title={"Turbotaste"}
                titleIcon={turboTasteIcon}
            >
                <LoadingContainer addGreyBackgroundColor={false} hidden={!loadingOffersTurboOptions} />
                {loadingOffersTurboOptions ? "" :
                    // (offersBandwidthOptions && offersDurations ?
                    <AvailableTurbosContentBox {...{ ...availableTurbosContentBoxProps, contract }} />
                    // :
                    // <div>Es wurde kein Produkt gefunden.</div>)
                }

            </ContentBox>
        );
    }

}

interface IAvailableTurbosContentBoxProps extends RouteComponentProps<any> {
    contract: ContractForPresentation | undefined;

    selectedBandwidthOption: BandwidthOption | undefined;
    // offersBandwidthOptions: BandwidthOption[]

    selectedDuration: IDurationViewModel | undefined;
    // offersDurations: IDurationViewModel[];

    selectableOffersBandwidthOptions: BandwidthOption[];
    selectableOffersDurations: IDurationViewModel[];

    setSelectedBandwidthOption: (option: BandwidthOption | undefined) => any
    setSelectedDurationOption: (duration: IDurationViewModel | undefined) => any

    addAlert: (data: IAppAlertData) => any;
    setPlacingOrder: (placing: boolean) => any;
    setContract: (contract: ContractForPresentation | undefined) => any;
}

const AvailableTurbosContentBox = withRouter((props: IAvailableTurbosContentBoxProps) => {
    const { contract, /* offersBandwidthOptions, offersDurations,*/   selectableOffersBandwidthOptions, selectableOffersDurations, selectedDuration, selectedBandwidthOption, setSelectedBandwidthOption: setSelectedTurboOption, setSelectedDurationOption: setSelectedLifeOption } = props;
    // TODO: Do we want to add Turbos not available for a specific product?
    const arrayHelper = new ArrayHelper();
    const bandwidthHelper = new BandwidthHelper();
    const durationHelper = new DurationHelper();
    const productHelper = new ProductHelper();

    const [subscription, setSubscription] = useState(false);

    const offer = contract && contract.product ? productHelper.getOfferForBandiwdthAndDuration(contract.product, selectedBandwidthOption, selectedDuration) : undefined;
    const durationsForSelectedBandwidthOptions = contract && contract.product ?
        productHelper.getPossibleTurboDurations(contract.product, selectedBandwidthOption) :
        [];
    const bandwithOptionsForSelectedDurations = contract && contract.product ?
        productHelper.getPossibleTurboBandwithOptions(contract.product, selectedDuration) :
        [];

    const turboButtonProps: IThreeValueSelectButtonProps[] =
        selectableOffersBandwidthOptions.map(option => {
            const disabled = contract && contract.product ?
                (contract.product.possibleTurbos || []).filter(t =>
                    t.enabled &&
                    bandwidthHelper.isEqualBandwidth(t.bandwidth, option)
                    //  &&
                    // (
                    //     !selectedDuration ||
                    //     durationHelper.isEqualDuration(t.duration, selectedDuration.name)
                    // )
                ).length === 0 :
                true;

            const result: IThreeValueSelectButtonProps = {
                content:
                    <div className="turboSelectButtonContent">
                        <div>{option.download + ""}/{option.upload + ""}</div>
                        <div className="buttonMbitsLabel">Mbit/s</div>
                    </div>,
                unSelectable: arrayHelper.getFirstMatch(selectableOffersBandwidthOptions, b => bandwidthHelper.isEqualBandwidth(option, b)) === undefined,
                onSelect: async () => {
                    setSelectedTurboOption(option);
                },
                selected: selectedBandwidthOption && bandwidthHelper.isEqualBandwidth(option, selectedBandwidthOption),
                asGrey: disabled || arrayHelper.getFirstMatch(bandwithOptionsForSelectedDurations, bw => bandwidthHelper.isEqualBandwidth(bw, option)) === undefined,
                disabled: disabled || false,
            }
            return result;
        });

    const durationOptionProps: IThreeValueSelectButtonProps[] =
        durationHelper.getSortedDurationsBySorting(selectableOffersDurations || []).map(option => {
            const disabled = contract && contract.product ?
                (contract.product.possibleTurbos || []).filter(t =>
                    t.enabled &&
                    durationHelper.isEqualDuration(t.duration, option.name)
                    // &&
                    // (!selectedBandwidthOption ||
                    //     bandwidthHelper.isEqualBandwidth(t.bandwidth, selectedBandwidthOption))
                ).length === 0 :
                true;

            const result: IThreeValueSelectButtonProps = {
                content:
                    <div className="lifeOptionSelectButtonContent">
                        <div>{option.name || ""}</div>
                    </div>,
                unSelectable: arrayHelper.getFirstMatch(selectableOffersDurations, d => durationHelper.isEqualDurationModel(option, d)) === undefined,
                onSelect: async () => {
                    setSelectedLifeOption(option);
                },
                selected: selectedDuration && durationHelper.isEqualDurationModel(option, selectedDuration),
                asGrey: disabled || arrayHelper.getFirstMatch(durationsForSelectedBandwidthOptions, d => durationHelper.isEqualDurationModel(d, option)) === undefined,
                // disabled: !option.available,
                disabled: disabled || false,
            }
            return result;
        });

    const hasAnyPossibleTurbo = contract && contract.product && contract.product.possibleTurbos.filter(pt => pt.enabled).length > 0;

    const repeatOptionProps: IThreeValueSelectButtonProps[] =
        [
            {
                content:
                    <div className="lifeOptionSelectButtonContent">
                        <div>Einmalig</div>
                    </div>,
                unSelectable: false,
                // tslint:disable-next-line: no-empty
                onSelect: async () => { setSubscription(false) },
                selected: !subscription && hasAnyPossibleTurbo,
                asGrey: false,
                disabled: !hasAnyPossibleTurbo,
            },
            {
                content:
                    <div className="lifeOptionSelectButtonContent">
                        <div>Fortlaufend</div>
                    </div>,
                unSelectable: false,
                // tslint:disable-next-line: no-empty
                onSelect: async () => { setSubscription(true) },
                selected: subscription && hasAnyPossibleTurbo,
                asGrey: false,
                disabled: !hasAnyPossibleTurbo,
            },
        ];


    const placeOrder = async () => {
        const { history, setPlacingOrder, addAlert, setContract, contract: c } = props;
        if (offer) {
            try {
                setPlacingOrder(true);

                const msg = {
                    cancelButtonText: "Abbrechen",
                    okButtonText: "Jetzt Bestellen",
                    onCancelButtonClick: () => {
                        setPlacingOrder(false);
                        new DialogBoxHelper().discardAppMessage(msg);
                    },
                    onOkButtonClick: async () => {
                        try {
                            await getApiClient().turboOrderClient.postPlaceByTurboIdByAsSubscription(offer.id, subscription);
                            setSelectedTurboOption(undefined);
                            setSelectedLifeOption(undefined);
                            if (c) {
                                setContract({ ...c, product: { ...c.product, possibleTurbos: c.product.possibleTurbos.map(t => ({ ...t, enabled: false })) } })
                            }
                            history.push(`/product/turbo/order/confirmed`)
                        } catch (err) {
                            if (err.message && err.title) {
                                addAlert({
                                    type: AlertTypes.ERROR,
                                    title: err.title,
                                    message: err.message + (err.code && ` (${err.code})`),
                                })
                            }
                            else {
                                addAlert({ message: "Fehler beim Absenden des Auftrags", type: AlertTypes.ERROR });
                            }

                        } finally {

                            setPlacingOrder(false);
                            new DialogBoxHelper().discardAppMessage(msg);
                        }
                    },
                    text: new DialogBoxHelper().getTurboOfferDialogText(offer, subscription),
                    title: "Bestellung abschließen",
                    containerClass: "orderTurboDialogContainer",
                };
                new DialogBoxHelper().addAppMessage(msg);
            } catch (err) {
                if (err.message && err.title) {
                    addAlert({
                        type: AlertTypes.ERROR,
                        title: err.title,
                        message: err.message + (err.code && ` (${err.code})`),
                    })
                }
                else {
                    addAlert({ message: "Fehler beim Absenden des Auftrags", type: AlertTypes.ERROR });
                }
            } finally {
                // setPlacingOrder(false);
            }
        }
    }

    return (
        <div className="availableTurbosContentBox">
            <div
                className="offersBigIcon"
                style={{
                    backgroundImage: `url(${turboTasteImage})`
                }}
            />
            <div className="selectOfferContainer">
                <div className="offerButtonsTurboOffersContainer">
                    <ThreeValueSelect
                        className="offerButtonsTurboOffers"
                        buttonProps={turboButtonProps}
                    />
                </div>
                <div className="offerButtonsLifeOffersContainer">
                    <ThreeValueSelect
                        className="offerButtonsLifeOffers"
                        buttonProps={durationOptionProps}
                    />
                </div>
                <div className="offerButtonsLifeOffersContainer">
                    <ThreeValueSelect
                        className="offerButtonsLifeOffers"
                        buttonProps={repeatOptionProps}
                    />
                </div>
                <div className="offerPriceInfo">
                    {offer ?
                        <span dangerouslySetInnerHTML={{ __html: (offer.pricelabel || "").replace(/<script>/g, v => "&lt;script&gt;") }} /> :
                        <span>Bitte wählen Sie eine Geschwindigkeit und eine Dauer</span>
                    }
                </div>
                <div className="orderButtonContainer">
                    <Button
                        onClick={placeOrder}
                        disabled={!offer}
                        className={"orderButton"}
                        orange={true}
                    >
                        Jetzt bestellen
                        </Button>
                    {/* <LinkButton
                        disabled={!offer}
                        to={offer ? `/product/turbo/order/${offer.id}` : "#"}
                        className={"orderButton"}
                        orange={true}>Zur Bestellung
                </LinkButton> */}
                </div>
            </div>
        </div>
    );
})


const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { contract, order } = state;
    const result: IStateToProps = {
        ...ownProps,
        loadinContract: contract.loadingContract || false,
        contract: contract.contract,

        loadingOffersTurboOptions: order.loadingOffersTurboOptions,

        selectedBandwidthOption: order.selectedBandwidthOption,
        // offersBandwidthOptions: order.offersBandwidthOptions,

        selectedDuration: order.selectedDuration,
        // offersDurations: order.offersDurations,

        selectableOffersBandwidthOptions: order.selectableOffersBandwidthOptions,
        selectableOffersDurations: order.selectableOffersDurations,
    }
    return result;
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
    const actionCreators = new OrderActionCreators();
    const appActionCreators = new AppActionCreators();
    const contractActionCreators = new ContractActionCreators();
    const result: IDispatchToProps = {
        ...ownProps,
        // retrievePossibleTurboOptions: () => (dispatch as ReduxThunkDispatch<IReduxState>)(actionCreators.retrievePossibleTurboOptions()),
        setSelectedBandwidthOption: bandwidth => (dispatch as ReduxThunkDispatch<IReduxState>)(actionCreators.setOffersSelectedBandwidthOptionId(bandwidth)),
        setSelectedDurationOption: id => (dispatch as ReduxThunkDispatch<IReduxState>)(actionCreators.setOffersSelectedDuration(id)),

        setPlacingOrder: placing => dispatch(actionCreators.setPlacingOrder(placing)),
        addAlert: (data: IAppAlertData) => (dispatch as ReduxThunkDispatch<IReduxState>)(appActionCreators.addAppAlert(data)),
        setContract: (contract: ContractForPresentation | undefined) => dispatch(contractActionCreators.setContract(contract)),

    }
    return result;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AvailableTurbosBox));