export interface IAuthRequestError extends Error {
    body: {
        error: string;
        error_description: "Bad credentials" | string;
    }
}

export class AuthRequestErrorHelper {
    constructor() {

        this.toAuthRequestError = this.toAuthRequestError.bind(this);
        this.isIAuthRequestError = this.isIAuthRequestError.bind(this);
    }

    public isIAuthRequestError(error: Error): boolean {
        const aError = error as IAuthRequestError;
        return (
            !!aError &&
            !!aError.body &&
            !!aError.body.error &&
            !!aError.body.error_description);

    }

    public toAuthRequestError(error: Error): IAuthRequestError {
        if (!this.isIAuthRequestError(error)) { throw new Error("No auth request error has been passed"); }
        return error as IAuthRequestError;
    }
    public isBadCredentialsError(error: IAuthRequestError) {
        return error && error.body && error.body.error_description === "Bad credentials";
    }

}

export default IAuthRequestError;