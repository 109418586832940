// import { BaseClient, RequestAdapter } from 'src/api/BaseClient';
import { BaseClient, RequestAdapter } from './../../api/BaseClient';

import * as ClientOAuth2 from "client-oauth2";
import { oauthServerClientId, oauthServerClientSecret, oauthServerUri } from 'src/api';
import IAuthApiClient from 'src/api/IUserApiClient';
import IAuthData from '../interfaces/IAuthData';
// import IUser from '../interfaces/IUser';

export class AuthenticationFeautureApiClient extends BaseClient<IAuthApiClient>{

    constructor(client: IAuthApiClient) {

        const requestAdapter = new RequestAdapter(client.authServiceBaseUrl);
        super(client, requestAdapter);
    }

    public async login(email: string, password: string): Promise<IAuthData> {
        // const request = this._requestAdapter.getRequest();
        const oAuthClient = new ClientOAuth2.default(
            {
                clientId: oauthServerClientId,
                clientSecret: oauthServerClientSecret,
                accessTokenUri: oauthServerUri + "/oauth/token",
                // authorizationUri: 'https://github.com/login/oauth/authorize',
                // redirectUri: 'http://example.com/auth/github/callback',
                // SCopes not needed yet
                // scopes: ['notifications', 'gist']
            }
        );
        const response = await oAuthClient.owner.getToken(email, password, {
            accessTokenUri: oauthServerUri + "/oauth/token",
        });

        // TODO: #FIXME ?
        return (response.data as unknown) as IAuthData;
    }

    public async refreshToken(authentication: IAuthData): Promise<any> {
        const oAuthClient = new ClientOAuth2.default(
            {
                clientId: oauthServerClientId,
                clientSecret: oauthServerClientSecret,
                accessTokenUri: oauthServerUri + "/oauth/token",
                // authorizationUri: 'https://github.com/login/oauth/authorize',
                // redirectUri: 'http://example.com/auth/github/callback',
                // SCopes not needed yet
                // scopes: ['notifications', 'gist']
            }
        );
        const token = oAuthClient.createToken((authentication as unknown) as ClientOAuth2.Data);

        const refreshedToken = await token.refresh();
        return (refreshedToken.data as unknown) as IAuthData;
    }

}

export default AuthenticationFeautureApiClient;