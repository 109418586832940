import * as React from "react";
import "./styles/NotificationSettingsBox.css";

import settingsIcon from 'src/icons/Settings';
import ContentBox from 'src/MolekuleComponents/ContentBox';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { NotificationsSettings } from 'src/api/Entities';
// import ContentBoxSplitter from 'src/AtomComponents/ContentBoxSplitter';
import Header1 from 'src/AtomComponents/Header1';
// import RangeInput from 'src/AtomComponents/RangeInput';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import { KeysOfPropsWithType } from 'src/declarations/Types';
// import StringHelper from 'src/helpers/StringHelper';
import CheckboxWithLabel from 'src/MolekuleComponents/CheckboxWithLabel';
import ContentBoxSaveButtons from 'src/MolekuleComponents/ContentBoxSaveButtons';
import { IReduxState } from 'src/redux';
// import NotificationSettingsHelper from './helpers/NotificationSettingsHelper';
import SettingsActionCreators from './redux/ActionCreators';

interface IOwnProps extends RouteComponentProps<any> {
}


interface IStateToProps {
    savingSettings: boolean;
    loadingSettings: boolean;
    notificationSettings: NotificationsSettings | undefined;
}

interface IDispatchToProps {
    saveSettings: (settings: NotificationsSettings) => Promise<any>
}

type INotificationSettingsBoxProps = IOwnProps & IStateToProps & IDispatchToProps;

interface INotificationSettingsBoxState {
    originalNotificationSettings: NotificationsSettings | undefined;
    currentSettings: NotificationsSettings | undefined;
}

class NotificationSettingsBox extends React.Component<INotificationSettingsBoxProps, INotificationSettingsBoxState> {

    constructor(props: INotificationSettingsBoxProps) {
        super(props)
        const { notificationSettings } = props;
        this.state = {
            originalNotificationSettings: notificationSettings,
            currentSettings: notificationSettings
        }

        this.saveSettings = this.saveSettings.bind(this);
        this.cancelSettings = this.cancelSettings.bind(this);
    }

    public componentWillReceiveProps(next: INotificationSettingsBoxProps) {
        const { notificationSettings } = next;
        if (this.state.originalNotificationSettings !== notificationSettings) {
            this.setState({
                originalNotificationSettings: notificationSettings,
                currentSettings: notificationSettings
            });
        }
    }

    public render() {
        const { loadingSettings } = this.props;
        const { currentSettings, originalNotificationSettings } = this.state;
        // const stringHelper = new StringHelper();

        const toggleNotify = (key: KeysOfPropsWithType<NotificationsSettings, boolean>) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const { currentSettings: cs } = this.state;
            const next: NotificationsSettings | undefined = cs ? { ...cs } : undefined;
            if (next && key) {
                next[key] = e.target.checked;
            }

            this.setState({ currentSettings: next });
        }

        if (!originalNotificationSettings || loadingSettings) { return <div />; }

        // const changeNotificationLevel = (key: KeysOfPropsWithType<NotificationSettings, NotificationLevel>) => (e: React.ChangeEvent<HTMLInputElement>) => {
        //     const { currentSettings: cs } = this.state;
        //     const next = { ...cs };

        //     const value = e.target.value;
        //     const valueAsNumber = value ? +value : 0;

        //     const nextLevel = new NotificationSettingsHelper().rangeValueToLevel(valueAsNumber);

        //     next[key] = nextLevel;

        //     this.setState({ currentSettings: next });
        // }

        // const mailNotificationLevelRangeValue = new NotificationSettingsHelper().levelToRangeValue(currentSettings.mailNotificationLevel);
        // const pushServiceNotificationLevelRangeValue = new NotificationSettingsHelper().levelToRangeValue(currentSettings.pushServiceNotificationLevel);

        return (
            <ContentBox
                isSingleCollumnBox={true}
                title={"Einstellungen"}
                titleIcon={settingsIcon}
            >
                <div className="settingsBoxContentContainer">
                    <div className="settingsProductUpdateAndNotificationsContainer">
                        <Header1>Produktinformationen und Benachrichtigungen</Header1>
                        <div className="notifyByMailContainer">
                            <CheckboxWithLabel onChange={toggleNotify("sendMail")} checked={currentSettings && currentSettings.sendMail} >
                                Benachrichtigungen als E-Mail
                            </CheckboxWithLabel>
                            {/* <div className={stringHelper.joinManyClassNames("notificationLevelRangeContainer", currentSettings.notifyByMail ? "visible" : "hidden")} >
                                <RangeInput
                                    min={0}
                                    max={2}
                                    value={mailNotificationLevelRangeValue}
                                    onChange={changeNotificationLevel("mailNotificationLevel")} />
                                <div>
                                    <NotificationLevelLabel level={currentSettings.mailNotificationLevel} />
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="notifyByPushServiceContainer">
                            <CheckboxWithLabel onChange={toggleNotify("notifyByPushService")} checked={currentSettings.notifyByPushService} >
                                Benachrichtigungen als Push Nachricht
                            </CheckboxWithLabel>

                            <div className={stringHelper.joinManyClassNames("notificationLevelRangeContainer", currentSettings.notifyByPushService ? "visible" : "hidden")}>
                                <RangeInput
                                    min={0}
                                    max={2}
                                    value={pushServiceNotificationLevelRangeValue}
                                    onChange={changeNotificationLevel("pushServiceNotificationLevel")} />
                                <div>
                                    <NotificationLevelLabel level={currentSettings.pushServiceNotificationLevel} />
                                </div>
                            </div>

                        </div> */}
                        <ContentBoxSaveButtons
                            onSaveClick={this.saveSettings}
                            onCancelClick={this.cancelSettings}
                            saveButtonDisabled={currentSettings === originalNotificationSettings}
                            cancelButtonDisabled={currentSettings === originalNotificationSettings}
                        />
                    </div>
                </div>
            </ContentBox >
        );
    }

    private async saveSettings(e: React.MouseEvent<HTMLElement>) {
        if (e) { e.preventDefault(); }
        const { saveSettings } = this.props;
        const { currentSettings } = this.state;

        if (currentSettings) {
            await saveSettings(currentSettings);
        }
    }

    private cancelSettings(e: React.MouseEvent<HTMLElement>) {
        if (e) { e.preventDefault(); }
        const { originalNotificationSettings } = this.state;
        this.setState({ currentSettings: originalNotificationSettings });
    }
}

// const NotificationLevelLabel = (props: { level: NotificationLevel }) => {
//     const { level } = props;

//     const label = (() => {
//         switch (level) {
//             case NotificationLevel.basicNotification:
//                 return <span>Sie erhalten Benachrichtigungen zu <span className="strong">Bestellungen</span> und <span className="strong">Rechungen</span>.</span>
//             case NotificationLevel.productAdvertisements:
//                 return <span>Sie erhalten Benachrichtigungen zu <span className="strong">Bestellungen</span>, <span className="strong">Rechungen</span> und <span className="strong">Angeboten</span> zu ihren Verträgen.</span>
//             case NotificationLevel.fullAdvertisements:
//                 return <span>Sie erhalten Benachrichtigungen zu <span className="strong">Bestellungen</span>, <span className="strong">Rechungen</span> sowie spannenden <span className="strong">Angeboten</span> und <span className="strong">Aktionen</span> der DOKOM21</span>
//             default:
//                 return "Unbekannter Wert für Benachrichtigungen: " + (level || "")
//         }
//     })();

//     return (<div>
//         {label}
//     </div>)

// }

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { settings } = state;
    const { savingSettings, loadingSettings, notificationSettings } = settings;
    const result: IStateToProps = {
        ...ownProps,
        loadingSettings: loadingSettings || false,
        savingSettings: savingSettings || false,
        notificationSettings: notificationSettings || undefined,
    }
    return result;
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
    const actionCreators = new SettingsActionCreators();
    const result: IDispatchToProps = {
        ...ownProps,
        saveSettings: (settings: NotificationsSettings) => (dispatch as ReduxThunkDispatch<IReduxState>)(actionCreators.saveNotificationSettings(settings)),
    }
    return result;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsBox));