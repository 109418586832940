import * as React from "react";

import StringHelper from 'src/helpers/StringHelper';
import SpeedInfoLine from 'src/MolekuleComponents/SpeedInfoLine';

interface IBandwithSpeedInfoLineProps {
    bandwithTypeLabel: string,
    maxBandwith: number,
    currentBandwith: number,
    appliedTurboBandwith?: number
    showTurboBandwithInLabel?: boolean;
    labelClass?: string;
    bandwidthClass?: string;
    additionalLabel?: React.ReactNode;
}

const BandwithSpeedInfoLine = (props: IBandwithSpeedInfoLineProps) => {
    const { bandwithTypeLabel, showTurboBandwithInLabel, currentBandwith, appliedTurboBandwith, maxBandwith,
        additionalLabel,
        labelClass: lc,
        bandwidthClass: bc } = props;
    const stringHelper = new StringHelper();

    const numericCurrentBandwith = (currentBandwith);
    const numericMaxBandwith = (maxBandwith);
    const numericTurboBandwith = appliedTurboBandwith ? (appliedTurboBandwith) : undefined;

    const labelClassName = stringHelper.joinManyClassNames("label", lc);
    const bandwidthClassName = stringHelper.joinManyClassNames("bandwidthLabel", bc);

    return (
        <div>
            <div className={labelClassName}>
                {bandwithTypeLabel}&nbsp;
                <span className={bandwidthClassName}>{showTurboBandwithInLabel ? numericTurboBandwith : numericCurrentBandwith}&nbsp;Mbit/s</span>
                {additionalLabel||""}
            </div>
            <SpeedInfoLine percentage={numericCurrentBandwith / numericMaxBandwith}
                percentageOrange={numericTurboBandwith ? numericTurboBandwith / numericMaxBandwith : undefined} />
        </div>);
}


export default BandwithSpeedInfoLine;