import * as React from "react";
import { useEffect, } from "react";
import '../styles/CurrentBandwithPage.css';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { TurboOrder, OrderStatus, } from 'src/api/Entities';
import { IReduxState } from 'src/redux';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import Button from 'src/AtomComponents/Button';
import AlertTypes from 'src/App/interfaces/AlertTypes';
import IAppAlertData from 'src/App/interfaces/AppAlertData';
import { getApiClient } from 'src/api';
import { Dispatch } from 'redux';
import AppActionCreators from 'src/App/redux/ActionCreators';
import OrderActionCreators from 'src/Order/redux/ActionCreators';
import DialogBoxHelper from 'src/App/helper/DialogBoxHelper';

// tslint:disable-next-line:no-empty-interface
interface IOwnProps extends RouteComponentProps<any> {
    // no own props yet 
}

interface IDispatchToProps {
    addAlert: (data: IAppAlertData) => any;
    setPlacingOrder: (placing: boolean) => any;
    cancelTurboSubscription: (activeTurbo: TurboOrder) => Promise<any>;
}

interface IStateToProps {
    activeTurbo: TurboOrder | undefined;
    placingOrder: boolean;
}
type ExtendOrderButtonProps = IOwnProps & IDispatchToProps & IStateToProps;

const ExtendOrderButton = (props: ExtendOrderButtonProps) => {
    const { activeTurbo: at, } = props;
    const successMessage = {
        title: "Turbo verlängert",
        text:
            "Vielen Dank, dass Sie Ihren Turbo für einen weiteren " + (at && at.turbo && at.turbo.duration.length > 1 && at.turbo.duration.substring(1).trim()) + " verlängert haben.",
        //        "Die Verlängerung Ihres Turbos wurde erfolgreich veranlasst. Sie werden in Kürze über die neue Laufzeit informiert.",
        okButtonText: "Schließen",
        onOkButtonClick: async () => {
            new DialogBoxHelper().discardAppMessage(successMessage);
        },
        cancelButtonText: undefined,
        onCancelButtonClick: undefined
    };

    useEffect(() => {

        return () => {
            new DialogBoxHelper().discardAppMessage(successMessage)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { activeTurbo: turbo, placingOrder } = props;
    const extendOrder = async () => {
        const { activeTurbo, setPlacingOrder, addAlert } = props;
        if (activeTurbo && !placingOrder) {
            try {


                setPlacingOrder(true);
                const msg = {
                    cancelButtonText: "Abbrechen",
                    okButtonText: "Jetzt Bestellen",
                    onCancelButtonClick: () => {
                        setPlacingOrder(false);
                        new DialogBoxHelper().discardAppMessage(msg);
                    },
                    onOkButtonClick: async () => {
                        try {
                            await getApiClient().turboOrderClient.patchExtendByOrderId(activeTurbo.id);
                            new DialogBoxHelper().discardAppMessage(msg)
                            new DialogBoxHelper().addAppMessage(successMessage)
                        } catch (err) {
                            if (err.message && err.title) {
                                addAlert({
                                    type: AlertTypes.ERROR,
                                    title: err.title,
                                    message: err.message + (err.code && ` (${err.code})`),
                                })
                            }
                            else {
                                addAlert({ message: "Fehler beim Absenden des Auftrags", type: AlertTypes.ERROR });
                            }

                        } finally {
                            setPlacingOrder(false);
                            new DialogBoxHelper().discardAppMessage(msg);
                        }
                    },
                    text: new DialogBoxHelper().getTurboOrderDialogText(activeTurbo),
                    title: "Bestellung abschließen",
                    containerClass: "orderTurboDialogContainer",
                };

                new DialogBoxHelper().addAppMessage(msg);

            } catch (err) {
                if (err.message && err.title) {
                    addAlert({
                        type: AlertTypes.ERROR,
                        title: err.title,
                        message: err.message + (err.code && ` (${err.code})`),
                    })
                }
                else {
                    addAlert({ message: "Fehler beim Absenden des Auftrags", type: AlertTypes.ERROR });
                }
            } finally {
                // setPlacingOrder(false);
            }
        }
    }
    const cancelSubscription = () => {
        const { activeTurbo, cancelTurboSubscription } = props;
        if (activeTurbo && !placingOrder) {
            cancelTurboSubscription(activeTurbo);
        }
    }
    const canExtend = turbo!.status !== OrderStatus.Canceled && !turbo!.subscription;
    const canCancel = turbo!.status !== OrderStatus.Canceled && !!turbo!.subscription;

    return <React.Fragment>
        {canExtend ?
            <div className="detailsPageButtonContainer">
                <Button
                    disabled={placingOrder}
                    onClick={extendOrder}
                    orange={false}
                    className="detailsButton"
                >
                    Turbo verlängern
            </Button>
            </div> : ""}
        {canCancel ?
            <div className="detailsPageButtonContainer">
                <Button
                    disabled={placingOrder}
                    onClick={cancelSubscription}
                    orange={false}
                    className="detailsButton"
                >
                    Ausschalten
            </Button>
            </div> : ""}
    </React.Fragment>

}


const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { order, turbo } = state;
    const result: IStateToProps = {
        ...ownProps,
        activeTurbo: turbo.activeTurbo,
        placingOrder: order.placingOrder,
    }
    return result;
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
    const actionCreators = new OrderActionCreators();
    const appActionCreators = new AppActionCreators();
    const result: IDispatchToProps = {
        ...ownProps,
        setPlacingOrder: placing => dispatch(actionCreators.setPlacingOrder(placing)),
        addAlert: (data: IAppAlertData) => (dispatch as ReduxThunkDispatch<IReduxState>)(appActionCreators.addAppAlert(data)),
        cancelTurboSubscription: (data: TurboOrder) => (dispatch as ReduxThunkDispatch<IReduxState>)(actionCreators.cancelTurboSubscription(data)),

    }
    return result;
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExtendOrderButton));
