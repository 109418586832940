import StringHelper from 'src/helpers/StringHelper';
import {  TurboOrderClient } from './ApiClient';
// import apiHelper from './ApiHelper';
import { PagedItems } from './ApiTypes';
import { Customer, OrderStatus, TurboOrder } from './Entities';
// import { RequestAdapter } from './RequestAdapter';

export class TurboOrderApiClientExtended extends TurboOrderClient {

    // constructor(apiClient: ApiClient, requestAdapter?: RequestAdapter) {
    //     super(apiClient, requestAdapter);
    // }

    public async getOrdersByCustomer<T extends TurboOrder>(customer: Customer, projection?: string, page?: number, sort?: string, size?: number): Promise<PagedItems<T>> {
        // const stringHelper = new StringHelper();
        // const url = stringHelper.appendParams("/rest/turboOrder/search/findByCustomer",
        //     `customerId=${customer.id}`
        // );
        // return await this._requestAdapter.getPage<T>(url, "turboOrder", projection, page, size, sort || "orderDate,desc");
        return this.searchFindByCustomer<T>(customer.id, projection, page, size, sort as any || "orderDate,DESC");
    }

    public async getOrdersByCustomerAndStatus<T extends TurboOrder>(customer: Customer, orderStatus: OrderStatus, projection?: string, page?: number, sort?: string, size?: number): Promise<PagedItems<T>> {
        const stringHelper = new StringHelper();
        const url = stringHelper.appendParams("/rest/turboOrder/search/findByCustomerAndStatus",
            `customerId=${customer.id}`,
            `orderStatus=${orderStatus}`
        );
        return await this._requestAdapter.getPage<T>(url, "turboOrder", projection, page, size, sort || "orderDate,desc");
    }

    public async getOrdersByCustomersAndStatuses<T extends TurboOrder>(customer: Customer[], orderStatus: OrderStatus[], projection?: string, page?: number, sort?: string, size?: number): Promise<PagedItems<T>> {
        const customerParam = customer.map(c => `customerId=${c.id}`).join("&");
        const orderParam = orderStatus.map(s => `orderStatus=${s}`).join("&");

        const stringHelper = new StringHelper();
        const url = stringHelper.appendParams("/rest/turboOrder/search/findByCustomersAndStatuses",
            customerParam,
            orderParam
        );

        return await this._requestAdapter.getPage<T>(url, "turboOrder", projection, page, size, sort || "orderDate,desc");
    }

}

export default TurboOrderApiClientExtended;