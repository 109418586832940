import * as React from "react";
import "./styles/LoadingContainer.css";

import StringHelper from 'src/helpers/StringHelper';
import loadingIndicatorIcon from 'src/icons/LoadingIndicator';

interface ILoadingContainerProps {
    hidden?: boolean;
    addGreyBackgroundColor?: boolean;
    addWhiteBackgroundColor?: boolean;
    className?: string
}

export const LoadingContainer = (props: ILoadingContainerProps) => {
    const { hidden, addGreyBackgroundColor, addWhiteBackgroundColor, className: cn } = props;

    const icon = loadingIndicatorIcon;
    const stringHelper = new StringHelper();
    const iconCssUrl = stringHelper.createCssUrlString(icon);
    const className = stringHelper.joinManyClassNames(
        "loadingContainer",
        addGreyBackgroundColor ? "withGreyBackgroundColor" : undefined,
        addWhiteBackgroundColor ? "withWhiteBackgroundColor" : undefined,
        cn ? cn : undefined,
        hidden ? "hidden" : "visible");

    return (
        <div className={className} >
            <div className={"innerContainer"}
                style={{ backgroundImage: iconCssUrl }}
            />
        </div>
    );

}