import { BandwidthOption, ProductForPresentation, TurboForPresentation } from 'src/api/Entities';
// import { ArrayHelper } from 'src/helpers/ArrayHelper';
import IDurationViewModel from '../interfaces/IDurationViewModel';
import BandwidthHelper from './BandwidthHelper';
import DurationHelper from './DurationHelper';

export class ProductHelper {

    private bandwithHelper = new BandwidthHelper();
    private durationHelper = new DurationHelper();

    constructor() {
        this.getOfferForBandiwdthAndDuration = this.getOfferForBandiwdthAndDuration.bind(this);
        this.getMaxBandwidth = this.getMaxBandwidth.bind(this);
        this.getPossibleTurboDurations = this.getPossibleTurboDurations.bind(this);
        this.getSelectableBandwithOptions = this.getSelectableBandwithOptions.bind(this);
        this.getSelectableDurationOptions = this.getSelectableDurationOptions.bind(this);
    }

    public getOfferForBandiwdthAndDuration(product: ProductForPresentation, bandwidth: BandwidthOption | undefined, duration: IDurationViewModel | undefined): TurboForPresentation | undefined {
        const bandwithHelper = this.bandwithHelper;
        const durationHelper = this.durationHelper;
        if (!bandwidth || !duration || !product || !product.possibleTurbos) {
            return undefined;
        }
        const matchingTurbos = product.possibleTurbos.filter(t =>
            t.bandwidth &&
            t.duration &&
            bandwithHelper.isEqualBandwidth(t.bandwidth, bandwidth) &&
            durationHelper.isEqualDurationModel(durationHelper.createDurationViewModelFromTurbo(t), duration));

        return matchingTurbos.length === 1 ? matchingTurbos[0] : undefined;
    }

    public getMaxBandwidth(product: ProductForPresentation): { upload: number, download: number } {
        const { upload, download } = product.bandwidth;
        let maxUpload = (upload);
        let maxDownload = (download);

        for (const turbo of product.possibleTurbos || []) {
            const tbw = turbo.bandwidth;
            if (tbw) {
                const maxTurboUpload = (tbw.upload);
                const maxTurboDownload = (tbw.download);
                maxDownload = maxDownload < maxTurboDownload ? maxTurboDownload : maxDownload;
                maxUpload = maxUpload < maxTurboUpload ? maxTurboUpload : maxUpload;
            }
        }
        return {
            upload: (maxUpload),
            download: (maxDownload)
        };
    }

    public getPossibleTurboBandwithOptions(product: ProductForPresentation,
        selectedDuration?: IDurationViewModel
    ): BandwidthOption[] {

        const durationHelper = new DurationHelper();

        const allBandwiths = (product.possibleTurbos || []).filter(t =>
            !!t.bandwidth &&
            t.enabled &&
            (!selectedDuration || durationHelper.isEqualDurationModel(durationHelper.createDurationViewModelFromTurbo(t), selectedDuration))
        ).map(t => t.bandwidth);

        const distinctBandwithOptions = new BandwidthHelper().getDistinctBandwidths(allBandwiths); // new ArrayHelper().getDistinctByExpression(allBandwiths, (b1, b2) => b1.id === b2.id)
        return distinctBandwithOptions;
    }

    public getPossibleTurboDurations(product: ProductForPresentation, selectedBandwidthOption?: BandwidthOption): IDurationViewModel[] {
        const bandwithHelper = this.bandwithHelper;
        const durationHelper = new DurationHelper();
        // const allDurations = (product.possibleTurbos || []).filter(t =>
        //     !!t.duration &&
        //     (!selectedBandwidthOption || (t.bandwidth && bandwithHelper.isEqualBandwidth(t.bandwidth, selectedBandwidthOption)))
        // ).map(t => t.duration);
        const turbosForBandwidth = (product.possibleTurbos || []).filter(
            t =>
                t.enabled &&
                (!selectedBandwidthOption ||
                    (t.bandwidth && bandwithHelper.isEqualBandwidth(t.bandwidth, selectedBandwidthOption)))
        )
        const durations = turbosForBandwidth.map(durationHelper.createDurationViewModelFromTurbo);

        const distinctDurations = durationHelper.getDistinctDurationViewModels(durations); // new ArrayHelper().getDistinctByExpression(allDurations, (b1, b2) => b1 === b2)
        return distinctDurations;
    }

    public getSelectableBandwithOptions(product: ProductForPresentation/*, allTurboOfferBandwidths: BandwidthOption[]*/): BandwidthOption[] {
        const productBandwidths = (product.possibleTurbos || []).filter(t => !!t.bandwidth).map(t => t.bandwidth);
        return new BandwidthHelper().getDistinctBandwidths(productBandwidths);
        // const arrayHelper = new ArrayHelper();
        // const bandwithHelper = this.bandwithHelper;

        // const result: BandwidthOption[] = allTurboOfferBandwidths.reduce(
        //     (p: BandwidthOption[], c: BandwidthOption) => {
        //         if (arrayHelper.getFirstMatch(productBandwidths || [],
        //             obj => bandwithHelper.isEqualBandwidth(c, obj)
        //         ) !== undefined) {
        //             return [...p, c];
        //         }
        //         return p;
        //     }, []);

        // return result;
    }

    public getSelectableDurationOptions(product: ProductForPresentation/*, allTurboOfferDurations: IDurationViewModel[]*/): IDurationViewModel[] {
        const durationHelper = new DurationHelper();
        const productDurations = (product.possibleTurbos || []).map(durationHelper.createDurationViewModelFromTurbo);
        return durationHelper.getDistinctDurationViewModels(productDurations);
        // const arrayHelper = new ArrayHelper();

        // const productDurations = (product.possibleTurbos || []).map(durationHelper.createDurationViewModelFromTurbo);

        // const result: IDurationViewModel[] = allTurboOfferDurations.reduce(
        //     (p: IDurationViewModel[], c: IDurationViewModel) => {
        //         if (arrayHelper.getFirstMatch(productDurations || [],
        //             obj => durationHelper.isEqualDurationModel(c, obj)
        //         ) !== undefined) {
        //             return [...p, c];
        //         }
        //         return p;
        //     }, []);

        // return result;
    }
}

export default ProductHelper;