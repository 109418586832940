import * as React from "react";
import './styles/LoginPageContent.css';

import LoginForm from './LoginForm';

const LoginPageContent = () => {

    return (
        <div className={"loginPageContent"}>
            <LoginForm showLabels={true} className="hiddenXS loginOnBlue" verticalForm={true} />
            <LoginForm showLabels={false} className="visibleOnlyXS loginOnBlue" />
        </div>
    );
}

export default LoginPageContent;