import { BandwidthOption, TurboOrder } from 'src/api/Entities';
import { ArrayHelper } from 'src/helpers/ArrayHelper';

export class BandwidthHelper {

    constructor() {
        this.getDistinctBandwidths = this.getDistinctBandwidths.bind(this);
        this.getDistinctBandwidths = this.getDistinctBandwidths.bind(this);
    }

    public optionFromOder(order: TurboOrder | undefined): BandwidthOption | undefined {
        return order && order.turbo ? order.turbo.bandwidth : undefined;
    }

    public getDistinctBandwidths(bandwidths: BandwidthOption[], ordered: boolean = true) {
        const result =
            new ArrayHelper().getDistinctByExpression(
                bandwidths,
                this.isEqualBandwidth
            );

        return !ordered ? result : result.sort((b1, b2) =>
            (b1.download > b2.download ?
                1 :
                (b1.download < b2.download ?
                    -1 :
                    (b1.download === b2.download ?
                        (b1.upload > b2.upload ?
                            1 :
                            (b1.upload < b2.upload ?
                                -1 :
                                0)) :
                        0)
                )
            )
        );
    }

    public isEqualBandwidth(b1: BandwidthOption, b2: BandwidthOption) {
        return (
            b1.id === b2.id ||
            (b1.download === b2.download && b1.upload === b2.upload)
        );
    }

}

export default BandwidthHelper;