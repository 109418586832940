const OrderActionTypes = Object.freeze({
    ORDER_SET_LOADING_TURBO: "ORDER_SET_LOADING_TURBO",
    ORDER_SET_TURBO: "ORDER_SET_TURBO",

    SET_OFFERS_BANDWITH_OPTIONS: "SET_OFFERS_BANDWITH_OPTIONS",
    SET_OFFERS_DURATIONS: "SET_OFFERS_DURATIONS",

    SET_SELECTABLE_OFFERS_BANDWITH_OPTIONS: "SET_SELECTABLE_OFFERS_BANDWITH_OPTIONS",
    SET_SELECTABLE_OFFERS_DURATIONS: "SET_SELECTABLE_OFFERS_DURATIONS",

    SET_OFFERS_LOADING_TURBO_OPTIONS: "SET_OFFERS_LOADING_TURBO_OPTIONS",

    SET_OFFERS_SELECTED_BANDWITH_OPTION_ID: "SET_OFFERS_SELECTED_BANDWITH_OPTION_ID",
    SET_OFFERS_SELECTED_DURATION_ID: "SET_OFFERS_SELECTED_DURATION_ID",
    
    SET_PLACING_ORDER: "SET_PLACING_ORDER",
})

export default OrderActionTypes;