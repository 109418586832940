import * as React from "react";

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ContractForPresentation, TurboOrder, OrderStatus, } from 'src/api/Entities';
import { IReduxState } from 'src/redux';
import { Dispatch } from 'redux';
import Button from 'src/AtomComponents/Button';
import { DateHelper } from 'src/helpers/DateHelper';
import LinkButton from 'src/AtomComponents/LinkButton';
import ExtendOrderButton from './ExtendOrderButton';

interface IOwnProps extends RouteComponentProps<any> {

}

// tslint:disable-next-line:no-empty-interface
interface IDispatchToProps {
}

interface IStateToProps {

    loadingContract: boolean;

    activeTurbo: TurboOrder | undefined;
    contract: ContractForPresentation | undefined;
}

type DetailsActiveTurboInfoBoxProps = IStateToProps & IOwnProps;
const DetailsActiveTurboInfoBox = (props: DetailsActiveTurboInfoBoxProps) => {
    const { contract, activeTurbo } = props;
    const dateHelper = new DateHelper();
    const hasActiveTurbo = !!activeTurbo;
    const hasAvailableOptions = contract && contract.product && contract.product.possibleTurbos.filter(pt => pt.enabled).length > 0;

    return hasActiveTurbo ?
        <div style={{ width: "100%" }}>
            {hasAvailableOptions ?
                <div className="detailsPageButtonContainer">
                    <LinkButton
                        to="/product/turbo"
                        orange={false}
                        className="detailsButton"
                    >
                        Turbo erhöhen
                    </LinkButton>
                </div> :
                ""
            }
            <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", marginTop: "4px" }}>
                <div className="detailsInfoCell">gebuchte Laufzeit:</div>
                <div className="detailsInfoCell value" style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                        {activeTurbo!.turbo!.duration}
                    </div>
                    {
                        activeTurbo!.status === OrderStatus.Active &&
                            activeTurbo!.turbo!.hours === -1 ?
                            <div style={{ marginTop: "4px" }}>
                                <Button style={{ maxWidth: "128px", height: "24px", padding: "4px 8px", margin: "8px 0px" }}>Nicht verlängern</Button>
                            </div> :
                            ""
                    }
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "4px" }}>
                <div className="detailsInfoCell">Turbo endet am:</div>
                <div className="detailsInfoCell value">
                    <div>{activeTurbo!.expiresDate ? dateHelper.toDateTimeStringFromIsoString(activeTurbo!.expiresDate!) + "" : "-"}</div>
                </div>
            </div>
            {activeTurbo!.subscription ?
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "4px" }}>
                    <div className="detailsInfoCell" />
                    <div className="detailsInfoCell value">
                        {activeTurbo!.status === OrderStatus.Active ?
                            "Wird anschließend automatisch verlängert" :
                            activeTurbo!.status === OrderStatus.Canceled ?
                                "Die automatische Verlängerung wurde beendet." :
                                ""}
                    </div>
                </div>
                : <React.Fragment />}
            <ExtendOrderButton />
        </div>
        :
        <React.Fragment />;

}
const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { contract, turbo } = state;

    const result: IStateToProps = {
        ...ownProps,
        activeTurbo: turbo.activeTurbo,

        loadingContract: contract.loadingContract || false,
        contract: contract.contract,
    }
    return result;
}
const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
    const result: IDispatchToProps = {
        ...ownProps,

    }
    return result;
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsActiveTurboInfoBox));