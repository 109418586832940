export class ArrayHelper {

    constructor() {
        this.getDistinctByExpression = this.getDistinctByExpression.bind(this);
        this.getMergedDistinctByExpression = this.getMergedDistinctByExpression.bind(this);
    }

    public getFirstMatch<T>(arr: T[], matchingCriteria: (obj: T) => boolean): T | undefined {
        if (!arr) { return undefined }

        // tslint:disable-next-line:forin
        for (const index in arr) {
            const current = arr[index];
            if (matchingCriteria(current)) {
                return current;
            }
        }
        return undefined;
    }

    public async getFirstMatchAsync<T>(arr: T[], matchingCriteria: (obj: T) => Promise<boolean>): Promise<T | undefined> {
        if (!arr) { return undefined }

        // tslint:disable-next-line:forin
        for (const index in arr) {
            const current = arr[index];
            if (await matchingCriteria(current)) {
                return current;
            }
        }
        return undefined;
    }

    public hasDifferentItem<T1, T2>(arr1: T1[], arr2: T2[], equalityComparer: (i1: T1, i2: T2) => boolean): boolean {
        const exclusiveItemInArr1 =
            this.getFirstMatch(arr1,
                i1 => arr2.filter(
                    i2 => equalityComparer(i1, i2)).length === 0);

        if (exclusiveItemInArr1 !== undefined) { return true; }
        const exclusiveItemInArr2 =
            this.getFirstMatch(arr2,
                i2 =>
                    arr1.filter(i1 => equalityComparer(i1, i2)).length === 0);

        if (exclusiveItemInArr2 !== undefined) { return true; }
        return false;
    }


    public getDistinctByExpression<T>(array: T[], comparer: (obj1: T, obj2: T) => boolean): T[] {
        const result: T[] =
            array.reduce(
                (p: T[], currentItem: T) => {
                    const alreadyThere = p.filter(pi => comparer(currentItem, pi)).length > 0;
                    return !alreadyThere ? [...p, currentItem] : p;
                },
                []
            );

        return result;
    }

    public getMergedDistinctByExpression<T>(arrays: T[][], comparer: (obj1: T, obj2: T) => boolean): T[] {
        const result: T[] =
            arrays.reduce(
                (p: T[], currentItem: T[]) => {
                    return this.getDistinctByExpression([...p, ...currentItem], comparer)
                },
                []
            );

        return result;
    }
}