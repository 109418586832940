import * as React from 'react';
import "./styles/App.css";

import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { Dispatch } from 'redux';
import IAuthData from '../Authentication/interfaces/IAuthData';
import IUser from '../Authentication/interfaces/IUser';
import LoginPageContent from '../Authentication/LoginPageContent';
import OverviewPageContent from '../Dashboard/OverviewPageContent';
import { ReduxThunkDispatch } from '../declarations/ReduxThunkDispatch';
import { LoadingContainer } from '../MolekuleComponents/LoadingContainer';
import ConfirmedOrderPageContent from '../Order/ConfirmedOrderPageContent';
import OrderPageContent from '../Order/OrderPageContent';
import { IReduxState } from '../redux';
import SettingsPageContent from '../Settings/SettingsPageContent';
import TurboHistoryPageContent from '../Turbo/TurboOrderHistoryPageContent';
import VersionPage from '../Version/VersionPage';
import AppAlertContainer from './components/AppAlertContainer';
import ContentContainer from './components/ContentContainer';
import Navigation from './components/Navigation';
import AppActionCreators from './redux/ActionCreators';
import IDialogBoxMessage from '../DialogBox/interfaces/IDialogBoxMessage';
import DialogBox from '../DialogBox/DialogBox';
import CurrentBandwithPage from '../Contract/DetailsPage';


// tslint:disable-next-line:no-empty-interface
interface IOffersOwnProps extends RouteComponentProps<any> {
}

interface IDispatchToProps {
  initializeApplication: () => Promise<any>;
}

interface IStateToProps {
  user: IUser | undefined;
  authData: IAuthData | undefined;
  initialized: boolean;
  messages: IDialogBoxMessage[];
}
type AppProps = IOffersOwnProps & IDispatchToProps & IStateToProps;

interface IAppState {
  showAlert?: boolean;
}

class App extends React.Component<AppProps, IAppState> {
  public state: IAppState = { showAlert: false };

  public componentWillMount() {
    const { initializeApplication } = this.props;
    initializeApplication();

  }

  public render() {
    const { user, authData, initialized, messages } = this.props;
    return (
      <div className="ttAppContainer">
        <AppAlertContainer />
        <div className="ttAppLayout">
          <Navigation visible={!!user && !!authData && initialized} />
          {messages.length > 0 ? <DialogBox message={messages[0]} /> : null}
          {!initialized ?
            <ContentContainer>
              <LoadingContainer
                addWhiteBackgroundColor={true}
                className={"globalLoadingIndicator"} />
            </ContentContainer> :
            (user && authData ?
              <ContentContainer>
                <Switch>
                  <Route path="/" exact={true} component={OverviewPageContent} />
                  <Route path="/product/turbo/order/history" component={TurboHistoryPageContent} />
                  <Route path="/product/turbo/order/confirmed" component={ConfirmedOrderPageContent} />
                  <Route path="/product/turbo/order/:turboId" component={OrderPageContent} />
                  <Route path="/product/turbo" component={OverviewPageContent} />
                  <Route path="/product/currentBandwith" component={CurrentBandwithPage} />
                  <Route path="/settings" component={SettingsPageContent} />
                  <Route path="/version" component={VersionPage} />
                </Switch>
              </ContentContainer> :
              <ContentContainer>
                <LoginPageContent />
              </ContentContainer>)
          }
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state: IReduxState, ownProps: IOffersOwnProps): IStateToProps => {
  const { app, authentication } = state;
  const result: IStateToProps = {
    ...ownProps,
    initialized: app.initialized,
    user: authentication.customer,
    authData: authentication.authData,
    messages: app.messages
  }
  return result;
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOffersOwnProps): IDispatchToProps => {
  const appActionCreators = new AppActionCreators();
  const result: IDispatchToProps = {
    ...ownProps,
    initializeApplication: () => (dispatch as ReduxThunkDispatch<IReduxState>)(appActionCreators.initializeApplication()),
  }
  return result;
}

// Since: https://reacttraining.com/react-router/web/guides/redux-integration
// TODO: #FIXME
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
