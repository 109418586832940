import * as React from "react";
import "./styles/CurrentRateDetailsBox.css"

import tarifDetailsIcon from 'src/icons/TarifDetails';
import ContentBox from 'src/MolekuleComponents/ContentBox';

import { connect } from 'react-redux';
import { PagedItems } from 'src/api/ApiTypes';
import { ContractForPresentation, TurboOrder, OrderStatus, } from 'src/api/Entities';
import Header1 from 'src/AtomComponents/Header1';
import { DateHelper } from 'src/helpers/DateHelper';
import downloadIcon from 'src/icons/Download';
import uploadIcon from 'src/icons/Upload';
import Badge from 'src/MolekuleComponents/Badge';
import BadgeColor from 'src/MolekuleComponents/interfaces/BadgeColor';
import { LoadingContainer } from 'src/MolekuleComponents/LoadingContainer';
import { IReduxState } from 'src/redux';
import BandwithSpeedInfoLine from '../MolekuleComponents/BandwithSpeedInfoLine';
import { TurboNameContainer } from './components/TurboNameContainer';
import BandwidthHelper from './helpers/BandwidthHelper';
import ProductHelper from './helpers/ProductHelper';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import { Dispatch } from 'redux';
import OrderActionCreators from '../Order/redux/ActionCreators';
import LinkWithArrow from 'src/MolekuleComponents/LinkWithArrow';

// tslint:disable-next-line:no-empty-interface
interface IOwnProps {
    key?: any;
    // no own props yet 
}

interface IDispatchToProps {
    cancelTurboSubscription: (activeTurbo: TurboOrder) => any;
}

interface IStateToProps {
    loadinContract: boolean;
    contract: ContractForPresentation | undefined;
    activeTurbo: TurboOrder | undefined;
    pendingTurbos: PagedItems<TurboOrder> | undefined;
}

type ICurrentProductDetailsBoxProps = IOwnProps & IDispatchToProps & IStateToProps;

const CurrentProductDetailsBox = (props: ICurrentProductDetailsBoxProps) => {
    const { contract, activeTurbo, pendingTurbos, loadinContract, cancelTurboSubscription } = props;
    return (
        <ContentBox
            title={"Aktuelle Geschwindigkeit"}
            titleIcon={tarifDetailsIcon}
            minWidth={272}
        >
            <LoadingContainer addGreyBackgroundColor={false} hidden={!loadinContract} />
            {loadinContract ? "" :
                (contract ?
                    <ProductInfoContainer contract={contract} activeTurbo={activeTurbo} pendingTurbos={pendingTurbos} cancelTurboSubscription={cancelTurboSubscription} /> :
                    <div>Es wurde kein Produkt für Sie gefunden</div>)
            }
        </ContentBox>
    );
}

interface IProductInfoContainerProps {
    contract: ContractForPresentation;
    activeTurbo: TurboOrder | undefined;
    pendingTurbos: PagedItems<TurboOrder> | undefined;
    cancelTurboSubscription: (activeTurbo: TurboOrder) => any;
}

const ProductInfoContainer = (props: IProductInfoContainerProps) => {
    const { contract, activeTurbo, pendingTurbos } = props;
    const { product } = contract;
    const productHelper = new ProductHelper()
    const bandwidthHelper = new BandwidthHelper()

    const maxBandwith = productHelper.getMaxBandwidth(product);

    // const expiresDateString = new DateHelper().toDateStringFromIsoString(contract.expires);

    const pendingTurbo = (pendingTurbos && pendingTurbos.items.length > 0) ? pendingTurbos.items[0] : undefined;

    const hasActiveTurbo = !!activeTurbo;
    const hasPendingTurbo = !!pendingTurbo;
    const visibleTurbo = (activeTurbo && activeTurbo.turbo) || (pendingTurbo && pendingTurbo.turbo);

    let activeTurboExpiresString: string | undefined;

    if (activeTurbo && activeTurbo.expiresDate && (!activeTurbo.subscription || activeTurbo.status === OrderStatus.Canceled)) {
        activeTurboExpiresString = activeTurbo ? (new DateHelper().toDateTimeStringFromIsoString(activeTurbo.expiresDate) + "") : undefined;
    }

    const activeTurboBandwith =
        bandwidthHelper.optionFromOder(activeTurbo) ||
        ({ upload: undefined, download: undefined });

    return (
        <div className="currentRateBoxContent">
            <div className="productDataContainer">
                <Header1 blue={true} className="productName">
                    <div className="productNameLabel">
                        {product.name}
                    </div>
                    {(hasActiveTurbo || hasPendingTurbo) &&
                        <div className="productNameAdditionalInfo">
                            &nbsp;
                        {visibleTurbo &&
                                <TurboNameContainer turbo={visibleTurbo} isActive={hasActiveTurbo} />
                            }
                            &nbsp;
                        <div>
                                <Badge badgeColor={hasActiveTurbo ? BadgeColor.ORANGE : BadgeColor.BLUE}>
                                    {hasActiveTurbo ? "TURBO" : "BESTELLT"}
                                </Badge>
                            </div>
                        </div>
                    }
                </Header1>
                <div className="productValidUntil">
                    <div>
                        {/* VertrashowDetailsContainergslaufzeit bis&nbsp;<span className="blue">{expiresDateString}</span> */}
                    </div>
                    {activeTurboExpiresString ?
                        <div>Turbo läuft ab am <span className="orange strong">{activeTurboExpiresString}</span></div> :
                        ""}
                </div>
                <div className="bandwithInfoContainer">
                    <div className="downloadInfo">
                        <div className="downloadInfoIcon"
                            style={{ backgroundImage: `url(${downloadIcon})` }}
                        />
                        <div className="downloadInfoContent">
                            <BandwithSpeedInfoLine
                                bandwithTypeLabel="Max Download:"
                                currentBandwith={product.bandwidth.download}
                                maxBandwith={maxBandwith.download}
                                appliedTurboBandwith={activeTurboBandwith.download}
                                showTurboBandwithInLabel={!!activeTurboBandwith.download}
                                bandwidthClass={activeTurboBandwith.download ? "orange strong" : undefined}
                                additionalLabel={
                                    !!activeTurboBandwith.download ?
                                        <React.Fragment>
                                            <span>&nbsp;(Standard:</span>
                                            <span className="blue strong">&nbsp;{product.bandwidth.download} Mbit/s</span>
                                            <span>)</span>
                                        </React.Fragment>
                                        :
                                        ""
                                }
                            />
                        </div>

                    </div>
                    <div className="uploadInfo">
                        <div className="uploadInfoIcon"
                            style={{ backgroundImage: `url(${uploadIcon})` }}
                        />
                        <div className="uploadInfoContent">
                            <BandwithSpeedInfoLine
                                bandwithTypeLabel="Max Upload:"
                                currentBandwith={product.bandwidth.upload}
                                maxBandwith={maxBandwith.upload}
                                appliedTurboBandwith={activeTurboBandwith.upload}
                                showTurboBandwithInLabel={!!activeTurboBandwith.upload}
                                bandwidthClass={activeTurboBandwith.upload ? "orange strong" : undefined}
                                additionalLabel={
                                    !!activeTurboBandwith.upload ?
                                        <React.Fragment>
                                            <span>&nbsp;(Standard:</span>
                                            <span className="blue strong">&nbsp;{product.bandwidth.upload} Mbit/s</span>
                                            <span>)</span>
                                        </React.Fragment>
                                        :
                                        ""
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="showDetailsContainer">
                <div>
                    <LinkWithArrow to="/product/currentBandwith" className="strong blue">Mehr Details und Optionen</LinkWithArrow>
                </div>
            </div>
        </div >)
}


const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { contract, turbo } = state;
    const result: IStateToProps = {
        ...ownProps,
        contract: contract.contract,
        activeTurbo: turbo.activeTurbo,
        pendingTurbos: turbo.pendingTurbos,
        loadinContract: contract.loadingContract || false,

    }
    return result;
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
    const orderActionCreators = new OrderActionCreators();
    const result: IDispatchToProps = {
        ...ownProps,
        cancelTurboSubscription: (activeTurbo: TurboOrder) => (dispatch as ReduxThunkDispatch<IReduxState>)(orderActionCreators.cancelTurboSubscription(activeTurbo)),
    }
    return result;
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentProductDetailsBox);