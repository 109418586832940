import * as React from "react";
import "./styles/ContentBoxTitle.css"

interface IContentBoxTitleProperties {
    icon?: ImageModules;
    children?: React.ReactNode;
}

class ContentBoxTitle extends React.Component<IContentBoxTitleProperties> {
    public render() {
        const { children, icon } = this.props;
        return (
            <div className={"contentBoxTitleWrapper"}>
            
                <div className="contentBoxTitleContainer">
                    {icon ?
                        <div
                            className="icon"
                            style={{ backgroundImage: `url(${icon})` }}
                        /> :
                        ""}
                    <div className="label">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}
export default ContentBoxTitle;