import { ApiClient } from './ApiClient';
import { RequestAdapter } from './RequestAdapter';
import TurboOrderApiClientExtended from './TurboOrderApiClientExtended';

export class ApiClientExtended extends ApiClient {

    public readonly turboOrderClient: TurboOrderApiClientExtended;

    constructor(requestAdapter?: RequestAdapter, baseUrl?: string) {
        super(requestAdapter, baseUrl);
        const adapter = requestAdapter || new RequestAdapter(baseUrl || "");
        this.turboOrderClient = new TurboOrderApiClientExtended(this, adapter);
    }
}
