import * as React from "react";

import { Turbo } from 'src/api/Entities';
import StringHelper from 'src/helpers/StringHelper';

export const TurboNameContainer = (props: { turbo: Turbo, isActive: boolean }) => {
    const { turbo, isActive } = props;
    const bandwidthData = turbo.bandwidth;
    const bwLabel =
        bandwidthData ?
            (bandwidthData.download + "/" + bandwidthData.upload + " Mbit/s") :
            "Unbekannte Bandbreite";
    const className = new StringHelper().joinManyClassNames(
        "strong",
        !isActive && "blue",
        isActive && "orange"
    );

    return (
        <span
            className={className}>
            {bwLabel}
        </span>);

}