import * as React from "react";
import { Link, NavLinkProps, Route, RouteComponentProps } from "react-router-dom";
import StringHelper from 'src/helpers/StringHelper';
import "./styles/BurgerMenueLink.css";

interface IBurgerMenueLinkProperties extends NavLinkProps {
    children: React.ReactNode;
    icon?: ImageModules;
    onLinkClicked?: () => any;

    isHrefAnchor?: boolean;
    openAsBlank?: boolean;
}

class BurgerMenueLink extends React.Component<IBurgerMenueLinkProperties> {
    constructor(props: IBurgerMenueLinkProperties) {
        super(props);
        this.renderRouteChildren = this.renderRouteChildren.bind(this);
    }

    // see also: https://github.com/ReactTraining/react-router/blob/5bde02b80b68155ba84afeb4f424cb2e7bacde43/packages/react-router-dom/modules/NavLink.js
    public render() {
        const { to, ...restProps } = this.props;

        const path = typeof to === "object" ? to.pathname : to;
        // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
        const escapedPath = path && (path as string).replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1");

        return (
            <Route
                path={escapedPath}
                exact={restProps.exact}
                strict={restProps.strict}
                location={restProps.location}
                children={this.renderRouteChildren}
            />
        );
    }

    private renderRouteChildren({ location, match }: RouteComponentProps<any>) {
        const {
            children,
            icon,
            "aria-current": ariaCurrent,
            style: styleProp,
            activeStyle,
            to,
            className: cp,
            activeClassName: activeClassNameProps,
            exact,
            onLinkClicked,

            isHrefAnchor,
            openAsBlank,

            ...rest } = this.props;

        const isActive = !!match && (match.isExact || !exact);
        const activeClassName = "activeRoute" + (activeClassNameProps ? ` ${activeClassNameProps}` : "")

        const helper = new StringHelper();
        const className = helper.joinClassNames("burgerMenueLink",
            isActive
                ? helper.joinClassNames(cp, activeClassName)
                : cp);
        const style = isActive ? { ...styleProp, ...activeStyle } : styleProp;

        const content = <div className="menueLinkContent" >
            <div className="menueIcon" style={{ backgroundImage: icon ? `url(${icon})` : undefined }} />
            <div className="menueLabel">{children}</div>
        </div>;

        if (isHrefAnchor) {
            return (
                <a
                    aria-current={(isActive && ariaCurrent) || false}
                    className={className}
                    style={style}
                    target={openAsBlank ? "_blank" : undefined}
                    href={(typeof to === "object" ? to.pathname : to) as string | undefined} >
                    {content}
                </a>
            )
        }

        return (
            <Link
                aria-current={(isActive && ariaCurrent) || false}
                className={className}
                style={style}
                to={to}
                onClick={onLinkClicked}
                {...rest}
            >
                {content}
            </Link>
        );
    }
}

export default BurgerMenueLink;