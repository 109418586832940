import { AnyAction, Dispatch } from "redux";
import { getApiClient } from 'src/api';
import { PagedItems } from 'src/api/ApiTypes';
import { OrderStatus, TurboOrderForPresentation } from 'src/api/Entities';
import { IReduxState } from 'src/redux';
import types from "./ActionTypes";
import AppAlertHelper from 'src/App/helper/AppAlertHelper';

class TurboActionCreators {

    constructor() {
        this.retrievePendingeTurbos = this.retrievePendingeTurbos.bind(this);
        this.retrieveActiveTurbos = this.retrieveActiveTurbos.bind(this);
        this.retrieveTurboHistory = this.retrieveTurboHistory.bind(this);
    }

    public setActiveTurbos(turbo: TurboOrderForPresentation | undefined): AnyAction {
        const result: AnyAction = { type: types.SET_ACTIVE_TURBO, turbo: turbo || undefined }
        return result;
    }
    public setActiveNotRunningTurbos(turbos: TurboOrderForPresentation[] | undefined): AnyAction {
        const result: AnyAction = { type: types.SET_ACTIVE_NOT_RUNNING_TURBOS, turbos: turbos || undefined }
        return result;
    }
    public setPendingTurbos(turbos: PagedItems<TurboOrderForPresentation> | undefined): AnyAction {
        const result: AnyAction = { type: types.SET_PENDING_TURBOS, turbos: turbos || undefined }
        return result;
    }
    public setTurboOrdersHistory(turbos: PagedItems<TurboOrderForPresentation> | undefined): AnyAction {
        const result: AnyAction = { type: types.SET_TURBO_HISTORY, turbos: turbos || undefined }
        return result;
    }
    public setLoadingTurboHistory(loading: boolean): AnyAction {
        const result: AnyAction = { type: types.SET_LOADING_TURBO_HISTORY, loading: loading || false }
        return result;
    }

    public retrieveTurboHistory(size: number | undefined): (dispatch: Dispatch, getState: () => IReduxState) => Promise<any> {
        return async (dispatch, getState) => {
            dispatch(this.setLoadingTurboHistory(true));
            dispatch(this.setTurboOrdersHistory(undefined));

            try {
                const { authentication } = getState();
                const customer = authentication.customer;

                // tslint:disable-next-line: no-console
                // console.log("HERE")
                const client = getApiClient();
                const turbos = customer ?
                    await client.turboOrderClient.getOrdersByCustomer<TurboOrderForPresentation>(customer, "turboOrderForPresentation", undefined, "orderDate,Desc&sort=deliveryDate,Desc&sort=expiresDate,Desc", size) :
                    undefined;

                dispatch(this.setTurboOrdersHistory(turbos));
            } finally {
                dispatch(this.setLoadingTurboHistory(false));
            }

        }
    }

    public retrieveNextTurboHistoryPage(): (dispatch: Dispatch, getState: () => IReduxState) => Promise<any> {
        return async (dispatch, getState) => {
            dispatch(this.setLoadingTurboHistory(true));

            try {
                const { authentication, turbo } = getState();
                const customer = authentication.customer;
                const currentHistory = turbo.turboHistory;

                const client = getApiClient();
                const turbos = customer && currentHistory && currentHistory.page.number + 1 < currentHistory.page.totalPages ?
                    await client.turboOrderClient.getOrdersByCustomer<TurboOrderForPresentation>(customer, "turboOrderForPresentation", currentHistory.page.number + 1, "orderDate,Desc&sort=deliveryDate,Desc&sort=expiresDate,Desc", currentHistory.page.size) :
                    undefined;

                const { turbo: tState2 } = getState();

                if (currentHistory === tState2.turboHistory && currentHistory && turbos && turbos.items.length > 0) {
                    const nextHistory: PagedItems<TurboOrderForPresentation> = ({ ...turbos, items: [...currentHistory.items, ...turbos.items] });
                    dispatch(this.setTurboOrdersHistory(nextHistory));
                }
            } finally {
                dispatch(this.setLoadingTurboHistory(false));
            }

        }
    }

    public retrieveActiveTurbos(): (dispatch: Dispatch, getState: () => IReduxState) => Promise<any> {
        return async (dispatch, getState) => {
            dispatch(this.setActiveTurbos(undefined));

            const { authentication } = getState();
            const customer = authentication.customer;

            const client = getApiClient();
            let turbo: TurboOrderForPresentation | undefined;
            try {
                turbo = customer ?
                    await client.turboOrderClient.getLatestRunning<TurboOrderForPresentation>("turboOrderForPresentation") :
                    undefined;
            } catch (err) {
                if (err.status !== 404) {
                    new AppAlertHelper().addError("Beim Abruf des Turbos ist etwas schief gelaufen. Bitte aktualisieren Sie diese Seite. Falls das Problem bestehen bleibt, wenden Sie sich bitte an den Kundenservice.")
                    throw err;
                }
            }
            dispatch(this.setActiveTurbos(turbo));
        }
    }

    public retrieveActiveNotRunningTurbos(): (dispatch: Dispatch, getState: () => IReduxState) => Promise<any> {
        return async (dispatch, getState) => {
            dispatch(this.setActiveNotRunningTurbos([]));

            const { authentication } = getState();
            const customer = authentication.customer;

            const client = getApiClient();
            let turbos: TurboOrderForPresentation[] | undefined;
            try {
                turbos = customer ?
                    [(await client.turboOrderClient.getNonRunningActive<TurboOrderForPresentation>("turboOrderForPresentation"))] :
                    [];
            } catch (err) {
                if (err.status !== 404) {
                    new AppAlertHelper().addError("Beim Abruf des Turbos ist etwas schief gelaufen. Bitte aktualisieren Sie diese Seite. Falls das Problem bestehen bleibt, wenden Sie sich bitte an den Kundenservice.")
                    throw err;
                }
            }
            dispatch(this.setActiveNotRunningTurbos(turbos || []));
        }
    }

    public retrievePendingeTurbos(): (dispatch: Dispatch, getState: () => IReduxState) => Promise<any> {
        return async (dispatch, getState) => {
            dispatch(this.setPendingTurbos(undefined));

            const { authentication } = getState();
            const customer = authentication.customer;

            const client = getApiClient();
            const turbos = customer ?
                await client.turboOrderClient.getOrdersByCustomerAndStatus<TurboOrderForPresentation>(customer, OrderStatus.Pending, "turboOrderForPresentation") :
                undefined;

            dispatch(this.setPendingTurbos(turbos));
        }
    }




}

export default TurboActionCreators;