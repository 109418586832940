const AppActionTypes = Object.freeze({
    SET_APP_INITIALIZED: "SET_APP_INITIALIZED",

    ADD_APP_ALERT: "ADD_APP_ALERT",
    DISCARD_APP_ALERT: "DISCARD_APP_ALERT",
    CLEAR_APP_ALERTS: "CLEAR_APP_ALERTS",

    ADD_APP_MESSAGE: "ADD_APP_MESSAGE",
    DISCARD_APP_MESSAGE: "DISCARD_APP_MESSAGE",
    CLEAR_APP_MESSAGES: "CLEAR_APP_MESSAGES",
})

export default AppActionTypes;