import { AnyAction } from 'redux';

import types from "./ActionTypes";
import ISettingsState, { defaultState } from "./State";

export const SettingsReducer = (state: ISettingsState = defaultState, action: AnyAction): ISettingsState => {
    switch (action.type) {
        case types.SETTINGS_SET_LOADING_NOTIFICATION_SETTINGS:
            return { ...state, loadingSettings: (action as any).loading || false };
        case types.SETTINGS_SET_SAVING_NOTIFICATION_SETTINGS:
            return { ...state, savingSettings: (action as any).saving || false };
        case types.SETTINGS_SET_NOTIFICATION_SETTINGS:
            return { ...state, notificationSettings: (action as any).notificationSettings || undefined };
        case types.SETTINGS_SET_NO_NOTIFICATION_SETTINGS_FLAG:
            return { ...state, noNotificationSettingsFlag: (action as any).flag || undefined };
        default:
            return state;
    }
}

export default SettingsReducer;