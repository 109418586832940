import * as React from "react";
import './styles/OverviewPageContent.css';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import ContractActionCreators from 'src/Contract/redux/ActionCreators';
import { ReduxThunkDispatch } from 'src/declarations/ReduxThunkDispatch';
import { IReduxState } from 'src/redux';
import TurboActionCreators from 'src/Turbo/redux/ActionCreators';
import CurrentProductDetailsBox from '../Contract/CurrentProductDetailsBox';
import AvailableTurbosBox from '../Turbo/AvailableTurbosBox';
import OverviewTurboHistoryBox from '../Turbo/OverviewTurboHistoryBox';
import { Product } from 'src/api/Entities';
import DialogBoxHelper from '../App/helper/DialogBoxHelper';
import { LoadingContainer } from 'src/MolekuleComponents/LoadingContainer';
import DivButtonWithArrow from 'src/MolekuleComponents/DivButtonWithArrow';

// tslint:disable-next-line:no-empty-interface
interface IOwnProps extends RouteComponentProps<any> {
    // no own props yet 
}

interface IDispatchToProps {
    loadContract: () => Promise<any>;
    retrieveTurboHistory: (size: number | undefined) => Promise<any>;
    activateProduct: () => Promise<any>;
}

interface IStateToProps {
    loadingContract: boolean;
    product: Product | undefined;
    placingOrder: boolean | undefined;
}

type IOverviewPageContentProps = IOwnProps & IDispatchToProps & IStateToProps;

class OverviewPageContent extends React.Component<IOverviewPageContentProps>{

    private activationMessage = {
        title: "Aktivierung",
        text: `Vielen Dank, dass Sie sich für DOKOM21 EasyExtras entschieden haben.
Bitte klicken Sie hier, um Ihren Zugang freizuschalten.`,
        okButtonText: "Jetzt aktivieren",
        onOkButtonClick: async () => {
            await this.props.activateProduct();
            new DialogBoxHelper().discardAppMessage(this.activationMessage);
        },
        cancelButtonText: undefined,
        onCancelButtonClick: undefined
    }

    public componentWillMount() {
        const { loadContract, retrieveTurboHistory } = this.props;
        loadContract();
        retrieveTurboHistory(3);
    }

    public componentWillReceiveProps(nextProps: IOverviewPageContentProps) {
        if (nextProps.product && this.props.product !== nextProps.product && nextProps.product.activated === false) {
            new DialogBoxHelper().addAppMessage(this.activationMessage)
        }
    }

    public componentWillUnmount() {
        new DialogBoxHelper().discardAppMessage(this.activationMessage)
    }

    public render() {
        const { placingOrder } = this.props;
        return (
            <div className={"overviewPageContent"} >
                {placingOrder ?
                    <LoadingContainer
                        addWhiteBackgroundColor={true}
                        className={"globalLoadingIndicator"} /> : <React.Fragment>
                        <div className="offerSection">
                            <AvailableTurbosBox />
                            <div className="pricingConditionsAndBusinessTermsContainer">
                                * Alle Preise inkl. MwSt., Online-Rechnung und zzgl. Lieferkosten,&nbsp;<a href="https://www.dokom21.de/lieferungzahlung" target="_blank" rel="noopener noreferrer"><DivButtonWithArrow white={true} >Lieferung/Zahlung</DivButtonWithArrow></a>
                            </div>
                        </div>
                        <div className="currentRateAndHistoryContainer">
                            <CurrentProductDetailsBox />
                            <OverviewTurboHistoryBox />
                        </div></React.Fragment>}

            </div>


        );
    }
}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IStateToProps => {
    const { contract, order } = state;
    const result: IStateToProps = {
        ...ownProps,
        loadingContract: contract.loadingContract || false,
        product: contract.contract && contract.contract.product,
        placingOrder: order.placingOrder,
    }
    return result;
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IOwnProps): IDispatchToProps => {
    const actionCreators = new ContractActionCreators();
    const turboActionCreators = new TurboActionCreators();
    const result: IDispatchToProps = {
        ...ownProps,
        loadContract: () => (dispatch as ReduxThunkDispatch<IReduxState>)(actionCreators.retrieveContract()),
        retrieveTurboHistory: (size: number | undefined) => (dispatch as ReduxThunkDispatch<IReduxState>)(turboActionCreators.retrieveTurboHistory(size)),
        activateProduct: () => (dispatch as ReduxThunkDispatch<IReduxState>)(actionCreators.activateProduct()),
    }
    return result;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OverviewPageContent));