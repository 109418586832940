import * as React from "react";
import "./styles/ContentBoxSplitter.css";

import StringHelper from 'src/helpers/StringHelper';

const ContentBoxSplitter = (props: { className?: string }) => {
    const { className: cn } = props;
    const helper = new StringHelper();
    const className = helper.joinManyClassNames("contentBoxSplitter", cn);
    return (<div className={className} />)
}

export default ContentBoxSplitter;