import * as React from 'react'
import "./styles/ContentContainer.css"

import logoIcon from 'src/icons/Logo';
import waveIcon from 'src/icons/Wave';

// tslint:disable-next-line:no-empty-interface
export interface IContentContainerProperties {
    children: React.ReactNode | React.ReactNode[];
}

const ContentContainer = (props: IContentContainerProperties) => {

    const { children } = props;

    return (
        <div className="ttAppContent">
            <div className="ttAppContentSpacer" />
            <div className="ttAppContentChildren">
                {children}
                <div className="contentChildrenBackground" />
            </div>
            <div className="ttAppContentBackgroundContainer">
                <div className="ttAppLogoIcon">
                    <img src={logoIcon} width="280" height="71" alt="logo" />
                </div>
                <div
                    className="ttAppContentBackgroundImage"
                    style={{ backgroundImage: `url(${waveIcon})` }} />
                <div
                    className="ttAppContentBackgroundBlue" />
            </div>
        </div>
    )
}

export default ContentContainer