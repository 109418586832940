import { AnyAction } from 'redux';

import IAppAlertData from '../interfaces/AppAlertData';
import types from "./ActionTypes";
import IAppState, { defaultState } from "./State";
import IDialogBoxMessage from 'src/DialogBox/interfaces/IDialogBoxMessage';

export const AppReducer = (state: IAppState = defaultState, action: AnyAction): IAppState => {
    switch (action.type) {
        case types.SET_APP_INITIALIZED:
            return { ...state, initialized: (action as any).initialized || false };

        case types.ADD_APP_ALERT: {
            const alertData: IAppAlertData = (action as any).data;
            return { ...state, alerts: alertData ? [...state.alerts, alertData] : state.alerts };
        }
        case types.DISCARD_APP_ALERT: {
            const alertData: IAppAlertData = (action as any).data;
            const alerts = [...state.alerts].filter(a => a !== alertData);
            return { ...state, alerts };
        }
        case types.CLEAR_APP_ALERTS: 
            return { ...state, alerts: [] };
        case types.ADD_APP_MESSAGE: {
            const message: IDialogBoxMessage | undefined = (action as any).data;
            return { ...state, messages: message ? [...state.messages, message] : state.messages }
        }
        case types.DISCARD_APP_MESSAGE: {
            const message: IDialogBoxMessage | undefined = (action as any).data;
            const messages = [...state.messages].filter(m => m !== message)
            return { ...state, messages }
        }
        case types.CLEAR_APP_MESSAGES:
            return { ...state, messages: [] }
        default:
            return state;
    }
}

export default AppReducer;